import { Capacitor } from "@capacitor/core";
import config from '../config.json';

export default function getRedirectUri() {
    const [localRedirectSignIn, webRedirectSignIn, nativeRedirectSignIn] = config.auth_redirect_uri.value.split(",");

    if (Capacitor.isNativePlatform()) {
        return nativeRedirectSignIn;
    }

    const isLocalhost = Boolean(window.location.hostname === "localhost" || window.location.hostname === "[::1]" || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));

    return isLocalhost ? localRedirectSignIn : webRedirectSignIn;
}