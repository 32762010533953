import Input from "@mui/joy/Input";
import { RefObject } from "react";

interface TextInputProps {
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>;
    placeholder?: string,
    submitCallback?: () => void,
    inputRef?: RefObject<HTMLInputElement>,
}

export default function TextInput(props: TextInputProps): JSX.Element {
    return (
        <Input
            ref={props.inputRef}
            size='lg'
            value={props.value}
            onKeyDown={event => event.key === 'Enter' && props.submitCallback?.()}
            onChange={event => props.setValue(event.target.value)}
            placeholder={props.placeholder}
            sx={{
                'input:focus': { boxShadow: 'none' }
            }}
        />
    );
}
