import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";
import { FinancialElement } from "../../model/FinancialElement";
import { OperationDetail } from "../../model/OperationDetail";

interface IncompleteStepListItemProps {
    icon: ReactNode
    item: FinancialElement | OperationDetail
    onClick: () => void
}

export default function IncompleteStepListItem(props: IncompleteStepListItemProps): JSX.Element {
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '18px',
        }}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                width: '100%',
                justifyContent: 'start',
                alignItems: 'center',
            }}>
                <Box sx={theme => ({
                    width: '44px',
                    height: '44px',
                    borderRadius: '22px',
                    backgroundColor: theme.vars.palette.background.level1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                })}>
                    <Box sx={{ width: '24px', strokeWidth: '2px' }}>
                        {props.icon}
                    </Box>
                </Box>
                <Typography level="title-sm" fontWeight='lg' sx={theme => ({
                    color: theme.vars.palette.text.tertiary
                })}>
                    {props.item.title}
                </Typography>
            </Box>
            <Button variant='plain' color='primary' onClick={props.onClick}>
                Agregar
            </Button>
        </Box>
    );
}
