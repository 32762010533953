export default function SomosfinLogo(): JSX.Element {
    return (
        <svg viewBox="0 0 1200 230" xmlns="http://www.w3.org/2000/svg">
            <path d="M15,167.7h37.8 C52,182.1,63.2,191,82.3,191c15.3,0,26-5.8,26-14.4c0-24.5-91.5-5.2-91.5-60C16.8,86.9,43.9,69,80.6,69c39.5,0,69,20.5,63.2,54.6 h-38.1c3.5-14.1-7.5-23.7-25.1-23.7c-14.7,0-23.4,6.6-23.4,14.7c0,25.4,91.5,4.6,91.5,59.7c0,30-27.4,47.6-66.4,47.6 C40.4,221.9,13.9,201.1,15,167.7" fill="currentFill" />
            <path d="M268.5,145.4 c0-26-14.1-42.7-35.2-42.7c-21.1,0-35.2,16.7-35.2,42.7c0,25.7,14.1,42.7,35.2,42.7C254.4,188.2,268.5,171.1,268.5,145.4 M157.4,145.4c0-45.9,30.3-76.5,75.9-76.5c45.6,0,75.9,30.6,75.9,76.5c0,45.9-30.3,76.5-75.9,76.5 C187.7,221.9,157.4,191.3,157.4,145.4" fill="currentFill" />
            <path d="M543.9,128.1v89.5 h-39.3v-87.7c0-16.2-6.9-27.1-20.2-27.1c-17.6,0-30,17-30,43.3v71.6h-39.3v-87.7c0-17-7.2-28.3-20.8-28.3 c-17.3,0-30.6,17.3-30.6,45v71h-39V73.3h39V99c7.5-17.9,24.5-30,45.9-30c20.8,0,36.7,12.1,42.4,30.3c7.8-18.5,24.2-30.3,46.2-30.3 C528.3,69,543.9,90.9,543.9,128.1" fill="currentFill" />
            <path d="M668.7,145.4 c0-26-14.1-42.7-35.2-42.7c-21.1,0-35.2,16.7-35.2,42.7c0,25.7,14.1,42.7,35.2,42.7C654.6,188.2,668.7,171.1,668.7,145.4 M557.6,145.4c0-45.9,30.3-76.5,75.9-76.5c45.6,0,75.9,30.6,75.9,76.5c0,45.9-30.3,76.5-75.9,76.5 C587.9,221.9,557.6,191.3,557.6,145.4" fill="currentFill" />
            <path d="M718.4,167.7h37.8 c-0.9,14.4,10.4,23.4,29.4,23.4c15.3,0,26-5.8,26-14.4c0-24.5-91.5-5.2-91.5-60c0-29.7,27.1-47.6,63.8-47.6 c39.5,0,69,20.5,63.2,54.6h-38.1c3.5-14.1-7.5-23.7-25.1-23.7c-14.7,0-23.4,6.6-23.4,14.7c0,25.4,91.5,4.6,91.5,59.7 c0,30-27.4,47.6-66.4,47.6C743.8,221.9,717.3,201.1,718.4,167.7" fill="currentFill" />
            <path d="M942.5,59.4 c3.2-10.4,0.6-16.4-6.4-16.4c-6.9,0-10.1,6.1-10.1,16.4v13.9h50.2v33.5H926v110.8h-39.3V106.8h-28.9V73.3h28.9V59.4 c0-30.3,18.2-48.2,49.9-48.2c33.5,0,46.5,19.6,39.8,48.2H942.5z" fill="currentFill" />
            <path d="M989.3,73.3h39.3v144.3 h-39.3V73.3z M985.8,31.4c0-13.3,10.1-23.4,23.4-23.4c13.3,0,23.4,10.1,23.4,23.4c0,13.3-10.1,23.4-23.4,23.4 C995.9,54.8,985.8,44.7,985.8,31.4" fill="currentFill" />
            <path d="M1185,135.4v82.2h-39.2 v-82c0-13.6,0-34-22.5-34c-18.8,0-32.3,18.2-32.3,45v71h-39V73.3h39V99c8.4-18.8,25.4-30,47.3-30C1185,69,1185,115.4,1185,135.4" fill="currentFill" />
        </svg>
    );
}
