import { Dispatch, SetStateAction, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

interface CustomDatePickerProps {
    value: Dayjs | null;
    setValue: Dispatch<SetStateAction<Dayjs | null>>;
    min: Dayjs;
    max: Dayjs;
    shouldDisable?: (date: Dayjs) => boolean;
}

export default function CustomDatePicker(props: CustomDatePickerProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <DatePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={props.setValue}
            closeOnSelect
            format='DD / MM / YYYY'
            shouldDisableDate={props.shouldDisable}
            minDate={props.min}
            maxDate={props.max}
            value={props.value}
            slotProps={{
                layout: {
                    sx: {
                        '& .MuiPickersCalendarHeader-labelContainer': {
                            pointerEvents: 'none',
                        },
                        '& .MuiPickersCalendarHeader-switchViewButton': {
                            display: 'none'
                        },
                        '& .MuiDatePickerToolbar-root': {
                            display: 'none'
                        },
                        '& .MuiButtonBase-root:not([disabled])': {
                            fontWeight: 'bold',
                            opacity: '1'
                        },
                        '& .MuiButtonBase-root:not([disabled]):not(.Mui-selected)': {
                            color: 'black',
                        },
                    }
                },
                actionBar: {
                    actions: [],
                },
                openPickerButton: {
                    sx: {
                        padding: 0,
                        margin: 0,
                    }
                },
                inputAdornment: {
                    sx: {
                        fontSize: '18px',
                        cursor: 'pointer',
                        margin: 0,
                        pr: '12px',
                        py: '12px',
                        border: '1px solid #CDD7E1',
                        maxHeight: '100%',
                        borderLeft: 'none',
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                    }
                },
                textField: {
                    InputProps: {
                        readOnly: true,
                    },
                    onClick: e => {
                        setOpen(true);
                        (e.target as HTMLInputElement).blur();
                    },
                    onKeyDown: e => (e.target as HTMLInputElement).blur(),
                    onSelectCapture: e => (e.target as HTMLInputElement).blur(),
                    onSelect: e => (e.target as HTMLInputElement).blur(),
                    sx: {
                        boxShadow: '0px 1px 2px 0px #15151514',
                        borderRadius: '6px',
                        backgroundColor: '#FBFCFE',
                        'input': {
                            cursor: 'pointer',
                            pl: 0,
                            py: '5px',
                            fontSize: '16px',
                            width: '154px',
                            lineHeight: '32px',
                            height: '32px',
                            border: '1px solid #CDD7E1',
                            borderRadius: '6px',
                            textAlign: 'center',
                            '@media (pointer: fine)': {
                                borderRight: 'none',
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0
                            }
                        },
                        'input:focus': {
                            boxShadow: 'none',
                            borderColor: '#CDD7E1',
                        },
                        '& .MuiInputBase-root': {
                            p: 0,
                            borderRadius: '6px',
                            backgroundColor: 'transparent',
                            fontFamily: 'Inter',
                            border: 'none',
                            height: '44px'
                        },
                        '& fieldset': {
                            display: open ? 'block' : 'none',
                            borderRadius: '6px',
                        },
                    },
                },
            }}
        />
    );
}
