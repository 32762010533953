import { registerPlugin } from '@capacitor/core';

export interface AppleLogoutBrowserPlugin {
    open(options: { url: string }): Promise<void>;
    close(): Promise<void>;
}

const AppleLogoutBrowser = registerPlugin<AppleLogoutBrowserPlugin>('AppleLogoutBrowser');

export default AppleLogoutBrowser;
