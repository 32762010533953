export default function PawPrint(): JSX.Element {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9998 6C12.1043 6 12.9998 5.10457 12.9998 4C12.9998 2.89543 12.1043 2 10.9998 2C9.89519 2 8.99976 2.89543 8.99976 4C8.99976 5.10457 9.89519 6 10.9998 6Z" stroke="currentColor" strokeWidth="currentStrokeWidth" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.9998 10C19.1043 10 19.9998 9.10457 19.9998 8C19.9998 6.89543 19.1043 6 17.9998 6C16.8952 6 15.9998 6.89543 15.9998 8C15.9998 9.10457 16.8952 10 17.9998 10Z" stroke="currentColor" strokeWidth="currentStrokeWidth" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.9998 18C21.1043 18 21.9998 17.1046 21.9998 16C21.9998 14.8954 21.1043 14 19.9998 14C18.8952 14 17.9998 14.8954 17.9998 16C17.9998 17.1046 18.8952 18 19.9998 18Z" stroke="currentColor" strokeWidth="currentStrokeWidth" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.99975 10C9.65636 10 10.3065 10.1293 10.9132 10.3806C11.5198 10.6319 12.071 11.0002 12.5353 11.4645C12.9996 11.9288 13.3679 12.48 13.6191 13.0866C13.8704 13.6932 13.9997 14.3434 13.9997 15V18.5C13.9995 19.3365 13.6996 20.1452 13.1546 20.7796C12.6095 21.4141 11.8552 21.8324 11.0283 21.9587C10.2015 22.085 9.3567 21.9111 8.64704 21.4683C7.93738 21.0255 7.40976 20.3432 7.15975 19.545C6.73308 18.1683 5.83308 17.2667 4.45975 16.84C3.66193 16.5901 2.97991 16.0629 2.53711 15.3538C2.0943 14.6446 1.91996 13.8004 2.04564 12.9739C2.17131 12.1473 2.58869 11.3931 3.22226 10.8476C3.85582 10.3021 4.66372 10.0015 5.49975 10H8.99975Z" stroke="currentColor" strokeWidth="currentStrokeWidth" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
