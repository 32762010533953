import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import config from './config.json';
import getRedirectUri from './utils/redirect-uri-utils';
import { Capacitor } from '@capacitor/core';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const isNativePlatform = Capacitor.isNativePlatform();

if (isNativePlatform) {
  const metaTag = document.createElement('meta');
  metaTag.httpEquiv = 'Content-Security-Policy';
  metaTag.content = config.content_security_policy.value;
  document.head.appendChild(metaTag);
}

root.render(
  <Auth0Provider
    domain={config.auth_domain.value}
    clientId={config.auth_client_id.value}
    authorizationParams={{
      redirect_uri: getRedirectUri(),
      audience: config.auth_audience.value,
      prompt: isNativePlatform ? 'login' : 'select_account',
    }}
    useRefreshTokens={true}
    useRefreshTokensFallback={!isNativePlatform}
    cacheLocation='localstorage'
  >
    <App />
  </Auth0Provider>
);
