import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "./pages/LoadingScreen";
import { ContextProvider } from "./utils/context";
import Fallback from "./components/Fallback";
import { ErrorBoundary } from "react-error-boundary";
import Router from "./Router";
import Login from "./pages/Login";
import { useEffect, useState } from "react";
import { Capacitor, PluginListenerHandle } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { useHandleSignOut } from "./hooks/UseHandleSignOut";
import config from './config.json';
import createCache from '@emotion/cache';
import { CacheProvider } from "@emotion/react";
import {
  createTheme as materialCreateTheme,
  ThemeProvider as MaterialThemeProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { extendTheme as joyExtendTheme } from "@mui/joy/styles";
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

const materialTheme = materialCreateTheme({
  palette: {
    primary: {
      main: '#614FDA'
    },
  },
});

const joyTheme = joyExtendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#EDE7FB',
          100: '#D1C4F4',
          200: '#B29DED',
          300: '#9275E7',
          400: '#7756E2',
          500: '#614FDA',
          600: '#4D33D5',
          700: '#392BCD',
          800: '#2125C6',
          900: '#001AB8',

          // Plain
          plainColor: '#5938DC',
          plainHoverBg: '#EDE7FB',
          plainActiveBg: '#D1C4F4',

          // Solid
          solidBg: '#614FDA',

          solidHoverBg: '#4D33D5',

          solidActiveBg: '#392BCD',

          // Outlined
          outlinedBg: 'transparent',
          outlinedBorder: '#9275E7',
          outlinedColor: '#614FDA',

          outlinedHoverBg: '#D1C4F4',
          outlinedHoverBorder: '#9275E7',
          outlinedHoverColor: '#614FDA',

          outlinedActiveBg: '#EDE7FB',
          outlinedActiveBorder: '#9275E7',
          outlinedActiveColor: '#614FDA',
        },
        focusVisible: '#5938DC',
      },
    },
  },
});

const nonceMatch = config.content_security_policy.value.match(/'nonce-([a-zA-Z0-9]+)'/);
const emotionCache = createCache({ key: 'css', nonce: nonceMatch ? nonceMatch[1] : '' });

export default function App(): JSX.Element {
  const { isLoading, user, getAccessTokenSilently } = useAuth0();
  const { handleSignOut } = useHandleSignOut();
  const [isThereAccessToken, setIsThereAccessToken] = useState(false);

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      let showListenerHandle: PluginListenerHandle;
      let hideListenerHandle: PluginListenerHandle;

      const setKeyboardListeners = async () => {
        showListenerHandle = await Keyboard.addListener('keyboardWillShow', ({ keyboardHeight }) => {
          document.documentElement.style.setProperty('--keyboard-height', `${keyboardHeight}px`);
          const activeElement = document.activeElement as HTMLElement;
          if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
            if (activeElement.getBoundingClientRect().bottom > window.innerHeight - keyboardHeight) {
              requestAnimationFrame(() => {
                activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              });
            }
          }
        });

        hideListenerHandle = await Keyboard.addListener('keyboardWillHide', () => {
          document.documentElement.style.removeProperty('--keyboard-height');
        });
      };

      setKeyboardListeners();

      return () => {
        if (showListenerHandle) showListenerHandle.remove();
        if (hideListenerHandle) hideListenerHandle.remove();
      };
    }
  }, []);

  useEffect(() => {
    const testGettingAccessTokenSilently = async () => {
      try {
        await getAccessTokenSilently();
        setIsThereAccessToken(true);
      } catch (error) {
        if (error && typeof error === 'object' && 'error' in error && typeof error.error === 'string' &&
          ['missing_refresh_token', 'invalid_grant', 'login_required'].includes(error.error)) {
          handleSignOut();
        }
      }
    }

    if (Capacitor.isNativePlatform() && !isLoading && user) {
      testGettingAccessTokenSilently();
    }
  }, [isLoading, user, getAccessTokenSilently, handleSignOut]);

  if (isLoading) return <LoadingScreen reference='1' />;

  if (!user) return <Login />;

  if (Capacitor.isNativePlatform() && !isThereAccessToken) return <LoadingScreen reference='2' />;

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <ContextProvider userId={user?.sub ?? ''}>
        <CacheProvider value={emotionCache}>
          <MaterialThemeProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
            <JoyCssVarsProvider theme={joyTheme}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es' localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                <Router />
              </LocalizationProvider>
            </JoyCssVarsProvider>
          </MaterialThemeProvider>
        </CacheProvider>
      </ ContextProvider>
    </ ErrorBoundary>
  );
}
