import { useCurrencyByCode } from "../../hooks/CurrencyFromCode";
import { getDecimal, getInteger } from "../../utils/number-utils";

interface CurrencyAmountProps {
    currency_code?: string,
    amount: string,
    size: number,
    hideSign?: boolean,
}

export default function CurrencyAmount(props: CurrencyAmountProps): JSX.Element {
    const { getSign } = useCurrencyByCode();

    const ratio = 1.5;

    const lg = `${props.size * ratio}px`;
    const md = `${props.size}px`;
    const sm = `${props.size / ratio}px`;
    const sm3 = `${props.size / ratio ** 3}px`;
    const sm4 = `${props.size / ratio ** 4}px`;

    return (
        <div style={{ display: 'flex', minHeight: lg }}>
            {!props.hideSign &&
                <p style={{ fontSize: md, lineHeight: lg, paddingRight: sm3 }}>
                    {props.amount.includes('-') ? '-' : '+'}
                </p>
            }
            <p style={{ fontSize: md, lineHeight: lg, paddingRight: sm3 }}>
                {getSign(props.currency_code)}
            </p>
            <p style={{ fontSize: md, lineHeight: lg, paddingRight: sm4 }}>
                {getInteger(props.amount)}
            </p>
            <p style={{ fontSize: sm, lineHeight: md }}>
                {getDecimal(props.amount)}
            </p>
        </div>
    );
}
