import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import Loading from "../components/Loading";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import { FinancialElement } from "../model/FinancialElement";
import { Operation, defaultOperation } from "../model/Operation";
import { v4 as uuid } from 'uuid';
import { getFormattedLocaleDate, getHourInMilis, ifDateIsValidThen } from "../utils/date-utils";
import Select from "../components/Select";
import { processDecimalInput } from "../utils/process-decimal-input";
import OperationsService from "../utils/OperationsService";
import { useAPI } from "../hooks/UseAPI";

interface TransferenceProps {
    financialElement: FinancialElement
    availableCurrencies: string[]
    close: () => void
}

export default function Transference(props: TransferenceProps): JSX.Element {
    const { client, addFeedback, financialElements } = useContext(Context);
    const { createOperationBatch } = useAPI();
    const [operation, setOperation] = useState<Operation>({ ...defaultOperation });
    const [date, setDate] = useState<Date>();
    const [loading, setLoading] = useState(true);
    const { getSign } = useCurrencyByCode();

    useEffect(() => {
        setLoading(true);
        try {
            const now = new Date();
            setDate(now);
            setOperation({
                ...defaultOperation,
                timestamp: getFormattedLocaleDate(now),
                currency_code: props.availableCurrencies.length > 0 ? props.availableCurrencies[0] : (client.currencies ? client.currencies[0].code : '')
            });
        } finally {
            setLoading(false);
        }
    }, [client, props.availableCurrencies, props.financialElement]);

    if (loading) return <Loading />

    function handleOperationInputChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setOperation(oldOperation => ({
            ...oldOperation,
            [target.name]: target.value
        }));
    }

    function saveCreateOperation(): void {
        setLoading(true);
        const grouping_id = uuid();
        const operationsToCreate = [{
            ...operation,
            timestamp: new Date(operation.timestamp ?? '').getTime().toString(),
            hour: getHourInMilis(date).toString(),
            grouping_id: grouping_id
        }, {
            ...operation,
            financial_element_id: props.financialElement.id,
            grouping_id: grouping_id,
            timestamp: new Date(operation.timestamp ?? '').getTime().toString(),
            hour: (getHourInMilis(date) + 1).toString(),
            amount: `-${operation.amount}`
        }];

        OperationsService.createOperationBatch(client.id ?? '', operationsToCreate, createOperationBatch).then(() => {
            addFeedback({ message: 'Transferencia de saldo creada con éxito!', level: 'success' });
            props.close();
            setLoading(false);
        });
    }

    const currenciesToDisplay = props.availableCurrencies.length > 0 ? props.availableCurrencies : client.currencies?.map(c => c.code) ?? [];

    return (
        <article className="h-fit bg-sf-violet-light">
            <h2>Transferencia de saldo desde <u>{props.financialElement.title}</u></h2>
            <Select placeholder='Cuenta destino *' name='financial_element_id' value={operation.financial_element_id} onChange={handleOperationInputChange} options={financialElements.filter(financialElement => financialElement.status === 'ENABLED').map(financialElement => ({ value: financialElement.id, alias: financialElement.title }))} />
            <div className='flex space-x-3'>
                <div className='w-48'>
                    <Select placeholder='Moneda' name='currency_code' value={operation.currency_code} onChange={handleOperationInputChange} options={currenciesToDisplay.map(currency_code => ({ value: currency_code, alias: getSign(currency_code) }))} />
                </div>
                <input type='text' inputMode='decimal' name='amount' placeholder='Cantidad *' value={operation.amount} onChange={e => processDecimalInput(e) && handleOperationInputChange(e)} className='field' />
            </div>
            <input name='description' type='text' placeholder='Descripción *' value={operation.description} onChange={handleOperationInputChange} className='field' />
            <input name='timestamp' type='date' value={operation.timestamp} onChange={ifDateIsValidThen(handleOperationInputChange)} className='field' />
            <div className='flex w-full justify-between items-center'>
                <button className='button-secondary' onClick={() => props.close()}>Cerrar</button>
                <button className='button-primary my-0' disabled={!operation.timestamp || !operation.financial_element_id || !operation.currency_code || !operation.amount || parseFloat(operation.amount) === 0 || !operation.description} onClick={saveCreateOperation}>Guardar</button>
            </div>
        </article>
    );
}
