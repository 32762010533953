import Loading from "../components/Loading";

interface LoadingScreenProps {
    reference: string
}

export default function LoadingScreen(props: LoadingScreenProps): JSX.Element {
    return (
        <div className='flex flex-col w-screen h-screen p-8'>
            <div className='flex justify-center items-center w-full h-full'>
                <Loading />
            </div>
            <div className='flex justify-end w-full h-fit'>
                <small className="secondary">{props.reference}</small>
            </div>
        </div>
    );
}