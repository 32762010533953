import StepLayout from './StepLayout';
import { useNavigate } from 'react-router-dom';
import PersonalGrowth from '../../components/initial-profile/brand-images/PersonalGrowth';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import { getIcon, savingIconMapping } from '../../utils/icon-mapping-utils';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useRef, useState } from 'react';
import { defaultFinancialElement, FinancialElement } from '../../model/FinancialElement';
import TitleAmountCurrencyForm from './TitleAmountCurrencyForm';
import IncompleteStepListItem from '../../components/initial-profile/IncompleteStepListItem';
import StepListItem from '../../components/initial-profile/StepListItem';
import { useAPI } from '../../hooks/UseAPI';
import { Context } from '../../utils/context';
import { sortElements } from '../../utils/sort-elements';
import { FinancialElementTypes } from '../../consts/FinancialElementTypes';
import { clearString } from '../../utils/string-utils';
import { DEFAULT_CURRENCY_CODE } from '../../consts/currency-codes';

export default function SavingsStep(): JSX.Element {
    const { client, setClient } = useContext(Context);
    const { listFinancialElements, createFinancialElementBatch, updateFinancialElementBatch, updateClient } = useAPI();
    const [savingItems, setSavingItems] = useState<FinancialElement[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [savingItemToEdit, setSavingItemToEdit] = useState<FinancialElement | null>(null);
    const shouldScrollToEnd = useRef(false);
    const endMarkerRef = useRef<HTMLElement>(null);
    const [modifiedSavingItems, setModifiedSavingItems] = useState<(string | undefined)[]>([]);

    useEffect(() => {
        async function setUp() {
            setLoading(true);
            try {
                const results = await listFinancialElements(client.id ?? '');
                const savingItems = results.filter(financialElement => financialElement.type === FinancialElementTypes.ASSET.key);
                const now = new Date().getTime();
                const suggestions = ['Efectivo'].map((title, i) => ({
                    ...defaultFinancialElement,
                    title,
                    amount: '0',
                    type: FinancialElementTypes.ASSET.key,
                    status: 'ENABLED',
                    order_key: (now + i).toString()
                }));
                const currentSavingItems = savingItems.length > 0 ? savingItems : suggestions;
                sortElements(currentSavingItems, [
                    { field: 'order_key', asc: true },
                    { field: 'update_timestamp', asc: false },
                ]);
                setSavingItems(currentSavingItems);
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [client.id, listFinancialElements]);

    useEffect(() => {
        if (savingItemToEdit === null && shouldScrollToEnd.current) {
            shouldScrollToEnd.current = false;
            setTimeout(() => endMarkerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 100);
        }
    }, [savingItemToEdit]);

    const defaultCurrencyCode = client.currencies ? client.currencies[0]?.code ?? DEFAULT_CURRENCY_CODE : DEFAULT_CURRENCY_CODE;

    function isSavingItemComplete(savingItem: FinancialElement) {
        return savingItem.title && savingItem.amount && savingItem.currency_code;
    }

    function submitForm(newFinancialElement: FinancialElement) {
        setSavingItems(oldSavingItems => {
            const newSavingItems = oldSavingItems.filter(item => clearString(item.title) !== clearString(newFinancialElement.title) || item.id !== '');
            const updateIndex = newSavingItems.findIndex(item => item.order_key === newFinancialElement.order_key);
            if (updateIndex === -1) {
                newSavingItems.push(newFinancialElement);
                shouldScrollToEnd.current = true;
            } else {
                newSavingItems[updateIndex] = newFinancialElement;
                setModifiedSavingItems(prev => [...prev, newFinancialElement.id ?? '']);
            }

            return newSavingItems;
        });
        setSavingItemToEdit(null);
        setClient(oldClient => {
            const newCurrencies = [...oldClient.currencies ?? []];
            newCurrencies.unshift({
                code: newFinancialElement.currency_code
            });
            const uniqueCurrencies = newCurrencies.filter((currency, index, self) => index === self.findIndex(c => c.code === currency.code));

            return {
                ...oldClient,
                currencies: uniqueCurrencies
            };
        });
    }

    async function submitStep() {
        setLoading(true);
        try {
            const savingItemsToCreate = savingItems.filter(savingItem => savingItem.id === '' && savingItem.amount !== '0');
            const creatingSavingItemsPromise = savingItemsToCreate.length > 0 ? createFinancialElementBatch(client.id ?? '', savingItemsToCreate) : Promise.resolve();

            const savingItemsToUpdate = savingItems.filter(savingItem => savingItem.id && modifiedSavingItems.includes(savingItem.id));
            const updateSavingItemsPromise = savingItemsToUpdate.length > 0 ? updateFinancialElementBatch(client.id ?? '', savingItemsToUpdate) : Promise.resolve();

            const updateClientPromese = updateClient(client.id ?? '', client);

            await Promise.all([creatingSavingItemsPromise, updateSavingItemsPromise, updateClientPromese]);
        } finally {
            navigate('/6');
            setLoading(false);
        }
    }

    return (<>
        <StepLayout
            endMarkerRef={endMarkerRef}
            currentStep={5}
            totalSteps={6}
            goBack={() => navigate('/4')}
            goToNext={submitStep}
            skip={() => navigate('/6')}
            title='Ahorros'
            description='¡La mejor parte! Agrega tus ahorros para planificar cómo cumplir tus objetivos.'
            isButtonDisabled={savingItems.find(isSavingItemComplete) === undefined}
            loading={loading}
            buttonText='Siguiente'
            image={<PersonalGrowth />}
        >
            <Box sx={{
                gap: '18px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                {savingItems.map((savingItem, index) =>
                    <Box key={index} sx={{ width: '100%' }}>
                        {isSavingItemComplete(savingItem) ?
                            <StepListItem
                                icon={getIcon(savingItem.title, savingIconMapping)}
                                item={savingItem}
                                onClick={() => setSavingItemToEdit(savingItem)}
                            />
                            :
                            <IncompleteStepListItem
                                icon={getIcon(savingItem.title, savingIconMapping)}
                                item={savingItem}
                                onClick={() => setSavingItemToEdit({ ...savingItem, currency_code: defaultCurrencyCode })}
                            />
                        }
                        <Divider />
                    </Box>
                )}
                <Button
                    onClick={() => setSavingItemToEdit({
                        ...defaultFinancialElement,
                        amount: '0',
                        currency_code: defaultCurrencyCode,
                        type: FinancialElementTypes.ASSET.key,
                        status: 'ENABLED',
                        order_key: new Date().getTime().toString(),
                    })}
                    variant='plain'
                    color='neutral'
                    startDecorator={
                        <AddIcon sx={{
                            width: '24px',
                            height: '24px',
                            color: 'currentColor'
                        }} />
                    }
                >
                    Nuevo ahorro
                </Button>
            </Box>
        </StepLayout>
        <TitleAmountCurrencyForm
            goBack={() => setSavingItemToEdit(null)}
            formTitle='Agregar ahorro'
            titlePlaceholder='Nombre del ahorro'
            item={savingItemToEdit}
            submit={(newFinancialElement: FinancialElement) => submitForm({ ...newFinancialElement, amount: parseFloat(newFinancialElement.amount || '0').toString() })}
        />
    </>);
}
