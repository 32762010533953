import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useAPI } from '../hooks/UseAPI';
import { Context } from '../utils/context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { addDaysToTimestamp, addMonthsToTimestamp, ifDateIsValidThen } from '../utils/date-utils';
import { FinancialElementTypes } from '../consts/FinancialElementTypes';
import { Operation } from '../model/Operation';
import { FinancialElement } from '../model/FinancialElement';
import Select from '../components/Select';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { getDecimal, getInteger } from '../utils/number-utils';
import { useVerticalScrollWithShadow } from '../hooks/VerticalScrollWithShadow';
import { sortElements } from '../utils/sort-elements';
import EmptyList from '../components/EmptyList';
import CashflowHeader from '../components/Cashflow/CashflowHeader';
import CashflowItem from '../components/Cashflow/CashflowItem';
import CashflowBalance from '../components/Cashflow/CashflowBalance';
import OperationsService from '../utils/OperationsService';
import CurrentValuesService from '../utils/CurrentValuesService';

function reduceOperations(operations: Operation[]): string {
    return operations.reduce((acc, operation) => acc + parseFloat(operation.amount ?? '0'), 0).toString();
}

interface FinancialElementsValues {
    financialElement: FinancialElement;
    initialValuesByCurrency: Map<string, number>;
    todayValuesByCurrency: Map<string, number>;
    finalValuesByCurrency: Map<string, number>;
}

export default function Cashflow(): JSX.Element {
    const { client, operationDetails, financialElements } = useContext(Context);
    const { listBudgets, listOperations, listValuations, createValuation } = useAPI();
    const [operations, setOperations] = useState<Operation[]>([]);
    const [loading, setLoading] = useState(true);
    const [todayTimestamp, setTodayTimestamp] = useState<string>('');
    const [timestampFrom, setTimestampFrom] = useState<string>('');
    const [timestampTo, setTimestampTo] = useState<string>('');
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [inflowHidden, setInflowHidden] = useState<boolean>(true);
    const [outflowHidden, setOutflowHidden] = useState<boolean>(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [financialElementsValues, setFinancialElementsValues] = useState<FinancialElementsValues[]>([]);
    const { getSign } = useCurrencyByCode();
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const [key, setKey] = useState<string>('');

    const isCash = (financialElement?: FinancialElement) => financialElement?.transactional === true && financialElement?.type === FinancialElementTypes.ASSET.key && financialElement?.status === 'ENABLED';

    useEffect(() => {
        async function setUp() {
            setLoading(true);
            setCurrencyCode(client.currencies ? client.currencies[0]?.code ?? '' : '');
            const result = await listBudgets(client.id ?? '');
            const budgetTimestamp = searchParams.get('budget_timestamp') ?? (new Date()).getTime().toString();
            let currentBudgetIndex = result.findIndex(budget => (budget.timestamp_from ?? '') < budgetTimestamp && budgetTimestamp <= (budget.timestamp_to ?? ''));
            if (currentBudgetIndex < 0) {
                currentBudgetIndex = result.length - 1;
            }

            const today = new Date();
            const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
            setTodayTimestamp(UTCtoday);
            setTimestampTo(UTCtoday);
            setTimestampFrom(result[currentBudgetIndex]?.timestamp_from ?? addMonthsToTimestamp(UTCtoday, -1));
        }

        setUp();
    }, [client.id, client.currencies, searchParams, listBudgets]);

    useEffect(() => {
        async function fetchData() {
            if (!client.id || !todayTimestamp || !timestampFrom || !timestampTo) return;

            try {
                setLoading(true);
                const timestampFromDayBefore = addDaysToTimestamp(timestampFrom, -1);
                const from = addDaysToTimestamp(timestampFromDayBefore, -7);
                const to = addDaysToTimestamp(timestampTo, 7);
                const ops = await OperationsService.listOperationsByRange(client.id, from, to, listOperations);
                setOperations(ops);
                const cashFinancialElements = financialElements.filter(isCash);
                const currentValuesByCurrency = await CurrentValuesService.getValues(client.id, cashFinancialElements, [timestampFromDayBefore, todayTimestamp, timestampTo], listOperations, listValuations, createValuation);
                const financialElementsVals = cashFinancialElements.map(financialElement => {
                    const initialValuesByCurrency = new Map<string, number>();
                    const todayValuesByCurrency = new Map<string, number>();
                    const finalValuesByCurrency = new Map<string, number>();
                    currentValuesByCurrency.filter(currentValue => currentValue.financial_element_id === financialElement.id).forEach(currentValue => {
                        const currentValueTimestamp = currentValue.timestamp ?? '';
                        const currentValueCurrencyCode = currentValue.currency_code ?? '';
                        const currentValueAmount = parseFloat(currentValue.amount ?? '0');
                        if (currentValueTimestamp === timestampFromDayBefore) {
                            initialValuesByCurrency.set(currentValueCurrencyCode, currentValueAmount);
                        }
                        if (currentValueTimestamp === todayTimestamp) {
                            todayValuesByCurrency.set(currentValueCurrencyCode, currentValueAmount);
                        }
                        if (currentValueTimestamp === timestampTo) {
                            finalValuesByCurrency.set(currentValueCurrencyCode, currentValueAmount);
                        }
                    });

                    return { financialElement, initialValuesByCurrency, todayValuesByCurrency, finalValuesByCurrency };
                });
                setFinancialElementsValues(financialElementsVals);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [client.id, financialElements, todayTimestamp, timestampFrom, timestampTo, createValuation, listOperations, listValuations]);

    if (loading) return <Loading />

    function handleDateFromChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();
        setTimestampFrom(newTimestamp);
    }

    function handleDateToChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();
        setTimestampTo(newTimestamp);
    }

    const minDate = !todayTimestamp ? '' : new Date(parseInt(addMonthsToTimestamp(todayTimestamp, -12))).toISOString().substring(0, 10);

    const dateFrom = !timestampFrom ? '' : new Date(parseInt(timestampFrom)).toISOString().substring(0, 10);

    const dateTo = !timestampTo ? '' : new Date(parseInt(timestampTo)).toISOString().substring(0, 10);

    const maxDate = !todayTimestamp ? '' : new Date(parseInt(addMonthsToTimestamp(todayTimestamp, 1))).toISOString().substring(0, 10);

    const getOperationDetail = (operation?: Operation) => operationDetails.find(operationDetail => operationDetail.id === operation?.operation_detail_id);

    const getFinancialElement = (operation?: Operation) => financialElements.find(financialElement => financialElement.id === operation?.financial_element_id);

    const financialElementIsNonTransactionalAndOfType = (finacialElementType: FinancialElementTypes, financialElement?: FinancialElement) =>
        financialElement?.type === finacialElementType.key &&
        !financialElement?.transactional;

    const financialElementIsTransactionalAndOfType = (finacialElementType: FinancialElementTypes, financialElement?: FinancialElement) =>
        financialElement?.type === finacialElementType.key &&
        financialElement?.transactional;

    const filteredOperations = operations?.filter(operation => {
        if (timestampFrom === undefined || timestampTo === undefined || operation.timestamp === undefined) return 0;

        return operation.timestamp >= timestampFrom && operation.timestamp <= timestampTo && isCash(getFinancialElement(operation)) && operation.currency_code === currencyCode;
    });

    const movementsOperations = filteredOperations.filter(operation => operation.operation_detail_id !== '');

    const nonMovementsOperations = filteredOperations.filter(operation => operation.operation_detail_id === '')
        .map(operation => ({
            ...operation,
            financial_element_id: operations.find(o =>
                o.grouping_id === operation.grouping_id &&
                o.financial_element_id !== operation.financial_element_id
            )?.financial_element_id
        }));

    const allOperations = [...movementsOperations, ...nonMovementsOperations];

    const initialValueByCurrency = new Map<string, number>();
    const todayValuesByCurrency = new Map<string, number>();
    const finalValuesByCurrency = new Map<string, number>();
    financialElementsValues.forEach(financialElementsValue => {
        financialElementsValue.initialValuesByCurrency.forEach((currentValue, currency_code) =>
            initialValueByCurrency.set(currency_code, (initialValueByCurrency.get(currency_code) ?? 0) + currentValue));
        financialElementsValue.todayValuesByCurrency.forEach((currentValue, currency_code) =>
            todayValuesByCurrency.set(currency_code, (todayValuesByCurrency.get(currency_code) ?? 0) + currentValue));
        financialElementsValue.finalValuesByCurrency.forEach((currentValue, currency_code) =>
            finalValuesByCurrency.set(currency_code, (finalValuesByCurrency.get(currency_code) ?? 0) + currentValue));
    });

    const initialValue = initialValueByCurrency.get(currencyCode)?.toString() ?? '0';
    const todayValue = todayValuesByCurrency.get(currencyCode)?.toString() ?? '0';
    const finalValue = finalValuesByCurrency.get(currencyCode)?.toString() ?? '0';

    const displayToday = timestampFrom < todayTimestamp && todayTimestamp < timestampTo;

    const totalOperations = reduceOperations(allOperations);

    const pastOperationsFilterPredicate = (operation: Operation) =>
        (operation.timestamp ?? '') <= todayTimestamp;

    const futureOperationsFilterPredicate = (operation: Operation) =>
        (operation.timestamp ?? '') > todayTimestamp;

    const mainOperations = displayToday ? allOperations.filter(pastOperationsFilterPredicate) : allOperations;
    const futureOperations = allOperations.filter(futureOperationsFilterPredicate);
    const totalFutureOperations = reduceOperations(futureOperations);

    const inflowOperationsFilterPredicate = (operation: Operation) =>
        !operation.amount?.includes('-');

    const incomeOperationsFilterPredicate = (operation: Operation) =>
        (operation.operation_detail_id ?? '') !== '';

    const inflowOperations = mainOperations.filter(inflowOperationsFilterPredicate);
    const totalInflow = reduceOperations(inflowOperations);

    const inflowDueToIncomeOperations = inflowOperations.filter(incomeOperationsFilterPredicate);
    const totalInflowDueToIncome = reduceOperations(inflowDueToIncomeOperations);

    const usedInflowOperationsIds = inflowDueToIncomeOperations.map(operation => operation.id);
    const otherInflowOperations = inflowOperations.filter(operation => !usedInflowOperationsIds.includes(operation.id));
    const totalOtherInflow = reduceOperations(otherInflowOperations);

    const outflowOperationsFilterPredicate = (operation: Operation) =>
        !!operation.amount?.includes('-');

    const spendingOperationsFilterPredicate = (operation: Operation) =>
        (operation.operation_detail_id ?? '') !== '' &&
        (getOperationDetail(operation)?.goal_id ?? '') === '';

    const transactionalDebtPaymentOperationsFilterPredicate = (operation: Operation) =>
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsTransactionalAndOfType(FinancialElementTypes.DEBT, getFinancialElement(operation));

    const nonTransactionalDebtPaymentOperationsFilterPredicate = (operation: Operation) =>
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.DEBT, getFinancialElement(operation));

    const outflowOperations = mainOperations.filter(outflowOperationsFilterPredicate);
    const totalOutflow = reduceOperations(outflowOperations);

    const outflowDueToSpendingOperations = outflowOperations.filter(spendingOperationsFilterPredicate);
    const totalOutflowDueToSpending = reduceOperations(outflowDueToSpendingOperations);

    const outflowDueToTransactionalDebtPaymentOperations = outflowOperations.filter(transactionalDebtPaymentOperationsFilterPredicate);
    const totalOutflowDueToTransactionalDebtPayment = reduceOperations(outflowDueToTransactionalDebtPaymentOperations);

    const outflowDueToNonTransactionalDebtPaymentOperations = outflowOperations.filter(nonTransactionalDebtPaymentOperationsFilterPredicate);
    const totalOutflowDueToNonTransactionalDebtPayment = reduceOperations(outflowDueToNonTransactionalDebtPaymentOperations);

    const usedOutflowOperationsIds = [...outflowDueToSpendingOperations, ...outflowDueToTransactionalDebtPaymentOperations, ...outflowDueToNonTransactionalDebtPaymentOperations].map(operation => operation.id);
    const otherOutflowOperations = outflowOperations.filter(operation => !usedOutflowOperationsIds.includes(operation.id));
    const totalOtherOutflow = reduceOperations(otherOutflowOperations);

    const errorsAndOmissionsToday = ((parseFloat(todayValue) - parseFloat(initialValue)) - (parseFloat(totalInflow) + parseFloat(totalOutflow))).toString();
    const errorsAndOmissionsFinal = displayToday ? ((parseFloat(finalValue) - parseFloat(todayValue)) - parseFloat(totalFutureOperations)).toString()
        : ((parseFloat(finalValue) - parseFloat(initialValue)) - parseFloat(totalOperations)).toString();

    const operationsMap = new Map<string, Operation[]>([
        ['Entradas de dinero', inflowOperations],
        ['Ingresos de tu Economía cotidiana', inflowDueToIncomeOperations],
        ['Otras entradas de dinero', otherInflowOperations],
        ['Salidas de dinero', outflowOperations],
        ['Egresos de tu Economía cotidiana', outflowDueToSpendingOperations],
        ['Pagos de tarjetas de crédito', outflowDueToTransactionalDebtPaymentOperations],
        ['Pagos de otras deudas', outflowDueToNonTransactionalDebtPaymentOperations],
        ['Otras salidas de dinero', otherOutflowOperations],
        ['Operaciones comprometidas', futureOperations]
    ]);

    const sortedOperations = sortElements(operationsMap.get(key) ?? allOperations, [
        { field: 'timestamp', asc: false },
        { field: 'hour', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    const operationsTimestampMap = new Map<string, Operation[]>();
    sortedOperations.forEach(operation =>
        operationsTimestampMap.set(operation.timestamp ?? '', [
            ...(operationsTimestampMap.get(operation.timestamp ?? '') ?? []),
            operation
        ])
    );
    const operationsTimestampMapEntries = Array.from(operationsTimestampMap.entries());
    sortElements(operationsTimestampMapEntries, [
        { field: 0, asc: false },
    ]);
    operationsTimestampMapEntries.forEach(entry => {
        sortElements(entry[1], [
            { field: 'hour', asc: false },
            { field: 'update_timestamp', asc: false },
        ]);
    });

    function goToOperation(operation: Operation) {
        if (operation.grouping_id === '') {
            navigate(`/operations/${operation.id}`);
        } else {
            navigate(`/transactions/${operation.grouping_id}`);
        }
    }

    const currency = getSign(currencyCode);

    return (
        <main>
            <article className='h-fit'>
                <h2>Análisis de efectivo</h2>
                <div className="w-full flex border-t border-sf-black space-x-3 pt-2">
                    <div className="w-1/2">
                        <p className="pb-1">Desde</p>
                        <input onKeyDown={e => e.preventDefault()} name='timestamp_from' type='date' min={minDate} max={dateTo} value={dateFrom} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateFromChange)} />
                    </div>
                    <div className="w-1/2">
                        <p className="pb-1">Hasta</p>
                        <input onKeyDown={e => e.preventDefault()} name='timestamp_to' type='date' min={dateFrom} max={maxDate} value={dateTo} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateToChange)} />
                    </div>
                </div>
                <div className='w-full h-fit ring-1 ring-sf-violet-dark rounded-lg'>
                    <Select placeholder='Moneda' name='currency_code' value={currencyCode} onChange={e => setCurrencyCode((e.target as HTMLInputElement).value)} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                </div>
                <article className='bg-sf-violet-light'>
                    <CashflowBalance
                        title={'Saldo inicial'}
                        currency={currency}
                        amount={initialValue}
                    />
                    <div className='space-y-1.5'>
                        <CashflowHeader
                            title={'Entradas de dinero'}
                            setKey={setKey}
                            hidden={inflowHidden}
                            setHidden={setInflowHidden}
                            currency={currency}
                            amount={totalInflow}
                        />
                        {!inflowHidden && <>
                            <CashflowItem title={'Ingresos de tu Economía cotidiana'} setKey={setKey} currency={currency} amount={totalInflowDueToIncome} />
                            <CashflowItem title={'Otras entradas de dinero'} setKey={setKey} currency={currency} amount={totalOtherInflow} />
                        </>}
                    </div>
                    <div className='space-y-1.5 pb-3 border-b border-sf-black'>
                        <CashflowHeader
                            title={'Salidas de dinero'}
                            setKey={setKey}
                            hidden={outflowHidden}
                            setHidden={setOutflowHidden}
                            currency={currency}
                            amount={totalOutflow}
                        />
                        {!outflowHidden && <>
                            <CashflowItem title={'Egresos de tu Economía cotidiana'} setKey={setKey} currency={currency} amount={totalOutflowDueToSpending} />
                            <CashflowItem title={'Pagos de tarjetas de crédito'} setKey={setKey} currency={currency} amount={totalOutflowDueToTransactionalDebtPayment} />
                            <CashflowItem title={'Pagos de otras deudas'} setKey={setKey} currency={currency} amount={totalOutflowDueToNonTransactionalDebtPayment} />
                            <CashflowItem title={'Otras salidas de dinero'} setKey={setKey} currency={currency} amount={totalOtherOutflow} />
                        </>}
                    </div>
                    {displayToday &&
                        <div className='space-y-1.5 pb-3 border-b border-sf-black'>
                            <CashflowBalance
                                title={'Saldo actual'}
                                currency={currency}
                                amount={todayValue}
                                errorsAndOmissions={errorsAndOmissionsToday}
                            />
                            <CashflowItem title={'Operaciones comprometidas'} setKey={setKey} currency={currency} amount={totalFutureOperations} />
                        </div>
                    }
                    <CashflowBalance
                        title={'Saldo final'}
                        currency={currency}
                        amount={finalValue}
                        errorsAndOmissions={errorsAndOmissionsFinal}
                    />
                </article>
            </article>
            <article className='p-0 space-y-0 h-fit'>
                <div className='p-3 flex flex-wrap items-center justify-between w-full'>
                    <h2 className='whitespace-nowrap'>Operaciones en efectivo</h2>
                    <div className="h-fit text-sf-violet-dark ring-1 ring-inset ring-sf-violet-dark p-1 w-fit rounded-full text-sm flex justify-center items-center font-bold whitespace-nowrap">
                        {key ? <>
                            <p className='px-1'>{key}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5 rounded-full bg-sf-violet-dark text-white" viewBox="0 0 24 24" onClick={() => setKey('')}><path fill="currentColor" d="m12 13.4l-2.917 2.925q-.276.275-.704.275t-.704-.275t-.275-.7t.275-.7L10.6 12L7.675 9.108Q7.4 8.832 7.4 8.404t.275-.704t.7-.275t.7.275L12 10.625L14.892 7.7q.276-.275.704-.275t.704.275q.3.3.3.713t-.3.687L13.375 12l2.925 2.917q.275.276.275.704t-.275.704q-.3.3-.712.3t-.688-.3z" /></svg>
                        </> :
                            <p className='px-1'>Todas las operaciones</p>
                        }
                    </div>
                </div>
                <div onScroll={onScrollHandler} ref={ref} style={{ maxHeight: '36rem' }} className={`px-3 rounded-b-2xl space-y-3 overflow-auto ${boxShadow}`}>
                    {operationsTimestampMapEntries.length > 0 ?
                        operationsTimestampMapEntries.map(entry =>
                            <div key={entry[0]} className='py-1'>
                                <div className='flex justify-between items-center'>
                                    <h3 className='text-lg'>{new Date(parseInt(entry[0])).toLocaleDateString('es', { timeZone: 'UTC' })}</h3>
                                </div>
                                <ul className="divide-y divide-sf-black border-t border-sf-black">
                                    {entry[1].map((operation, index) =>
                                        <div key={index} className="flex py-1">
                                            <div className='py-1 flex justify-between items-center w-full cursor-pointer' onClick={() => goToOperation(operation)}>
                                                {operation.operation_detail_id === '' ?
                                                    <p>{operation.description}</p>
                                                    :
                                                    <p className='font-bold'>{operationDetails.find(operationDetail => operationDetail.id === operation.operation_detail_id)?.title}</p>
                                                }
                                                <div className={`flex ${operation.amount?.includes('-') ? 'expenditure' : 'income'}`}>
                                                    <p className="font-mono">{operation.amount?.includes('-') ? '-' : '+'}</p>
                                                    <p className="mx-1">{getSign(operation.currency_code)}</p>
                                                    <p>{getInteger(operation.amount ?? '')}</p>
                                                    <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(operation.amount ?? '')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </ul>
                            </div>
                        )
                        : <EmptyList message="No hay operaciones." />
                    }
                </div>
            </article>
        </main >
    );
}
