import { useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { useAPI } from "../hooks/UseAPI";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import EmptyList from "../components/EmptyList";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import { FinancialElement } from "../model/FinancialElement";
import { sortElements } from "../utils/sort-elements";
import { FinancialElementTypes } from "../consts/FinancialElementTypes";
import Transference from "../components/Transference";
import { getInteger, getDecimal } from '../utils/number-utils';
import CurrentValuesService from "../utils/CurrentValuesService";
import OperationsService from "../utils/OperationsService";

interface AssetItemProps {
    financialElement: FinancialElement
    valuesItems: Map<string, number>
    openTransference: (financialElement: FinancialElement) => void
}

function AssetItem(props: AssetItemProps): JSX.Element {
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();
    const valuesItems = Array.from(props.valuesItems.entries()).map(entry => ({ currencyCode: entry[0], valuation: entry[1].toString() }));

    return (
        <div className="py-2">
            <div className="cursor-pointer" onClick={() => navigate(`/assets/${props.financialElement.id}`)}>
                {props.financialElement.status === 'DISABLED' &&
                    <p className="bg-sf-yellow-dark h-min mb-1.5 font-bold rounded-full flex justify-center items-center whitespace-nowrap">
                        Deshabilitada
                    </p>
                }
                <div className="flex justify-between h-fit w-full">
                    <div className="max-w-[62%]">
                        <p className="truncate font-bold">{props.financialElement.title}</p>
                        <p className="secondary">{props.financialElement.description}</p>
                    </div>
                    {props.financialElement.status === 'ENABLED' && valuesItems.length > 0 &&
                        <div className="w-fit h-fit">
                            {valuesItems.map(item =>
                                <div key={item.currencyCode} className="flex items-center min-h-full self-center justify-start">
                                    <div className={`flex self-center ${item.valuation.includes('-') ? 'expenditure' : 'income'} `}>
                                        <p className="font-mono ml-1">{item.valuation.includes('-') ? '-' : '+'}</p>
                                        <p className="ml-0.5">{getSign(item.currencyCode)}</p>
                                        <p className="ml-0.5">{getInteger(item.valuation)}</p>
                                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(item.valuation)}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
            <div className="flex flex-row-reverse w-full items-center justify-between py-1">
                <button className="button-aux flex space-x-1 items-center" onClick={() => { props.openTransference(props.financialElement); window.scrollTo(0, 0); }}>
                    <p>Transferencia de saldo</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5" viewBox="0 0 24 24"><path fill="currentColor" d="M2.038 12q0 1.815.956 3.286q.956 1.47 2.516 2.162q.196.104.31.287q.115.182.042.384q-.074.208-.285.277q-.212.07-.408-.015q-2.142-.962-3.136-2.772q-.995-1.81-.995-3.609q0-1.792.986-3.6t3.122-2.77q.196-.084.41-.018q.215.067.294.275q.079.196-.033.378q-.111.183-.307.287q-1.56.692-2.516 2.162T2.038 12m12 7q-2.92 0-4.96-2.04T7.038 12q0-2.921 2.04-4.96T14.038 5q1.247 0 2.362.392t2.025 1.135q.171.135.19.333q.02.198-.146.363q-.16.16-.37.156q-.21-.004-.387-.139q-.77-.588-1.686-.914Q15.11 6 14.038 6q-2.5 0-4.25 1.75T8.038 12q0 2.5 1.75 4.25t4.25 1.75q1.072 0 1.988-.326q.916-.326 1.686-.914q.176-.135.387-.139q.21-.004.37.156q.166.165.146.363q-.019.198-.19.333q-.91.742-2.025 1.135q-1.115.392-2.362.392m7.008-6.5h-7.392q-.214 0-.357-.143q-.143-.144-.143-.357t.143-.357q.143-.143.357-.143h7.392L19.3 9.754q-.14-.14-.15-.344q-.01-.204.15-.364t.354-.16q.194 0 .354.16l2.388 2.389q.242.242.242.565q0 .323-.242.565l-2.388 2.389q-.14.14-.345.15q-.203.01-.363-.15t-.16-.354q0-.194.16-.354z" /></svg>
                </button>
                {props.financialElement.transactional &&
                    <small onClick={() => navigate(`/assets/${props.financialElement.id}`)} className="cursor-pointer ring-1 ring-inset ring-sf-violet-dark h-min px-2.5 py-0.5 rounded-full flex justify-center items-center text-sf-violet-dark font-bold whitespace-nowrap">
                        Uso cotidiano
                    </small>
                }
            </div>
        </div>
    );
}

export default function Assets(): JSX.Element {
    const { client, setFinancialElements } = useContext(Context);
    const { listFinancialElements, listOperations, listValuations, createValuation } = useAPI();
    const [loading, setLoading] = useState(true);
    const [assets, setAssets] = useState<AssetItemProps[]>([]);
    const [financialElementToTransfer, setFinancialElementToTransfer] = useState<FinancialElement>();
    const { getSign } = useCurrencyByCode();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            if (!client.id) return;
            try {
                const today = new Date();
                const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
                const financialElements = await listFinancialElements(client.id);
                sortElements(financialElements, [
                    { field: 'status', asc: false },
                    { field: 'transactional', asc: false },
                    { field: 'update_timestamp', asc: false },
                ]);
                setFinancialElements(financialElements);
                const assetFinancialElements = financialElements.filter(financialElement => financialElement.type === FinancialElementTypes.ASSET.key);
                const currentValues = await CurrentValuesService.getValues(client.id ?? '', assetFinancialElements, [UTCtoday], listOperations, listValuations, createValuation);
                const assetItems = assetFinancialElements.map(financialElement => {
                    const valuesItems = new Map<string, number>();
                    currentValues.filter(currentValue => currentValue.financial_element_id === financialElement.id).forEach(currentValue => {
                        valuesItems.set(currentValue.currency_code ?? '', parseFloat(currentValue.amount ?? '0'));
                    });
                    return { financialElement, valuesItems } as AssetItemProps;
                });
                setAssets(assetItems);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return OperationsService.subscribe(fetchData);
    }, [client.id, setFinancialElements, createValuation, listFinancialElements, listOperations, listValuations]);

    if (loading) return <Loading />

    const balanceItems = new Map<string, { transactionalBalance: string, totalBalance: string }>();
    const defaultBalanceItem = { transactionalBalance: '0', totalBalance: '0' };
    assets.filter(asset => asset.financialElement.status === 'ENABLED').forEach(asset => {
        asset.valuesItems.forEach((value, currency_code) => {
            if (asset.financialElement.transactional) {
                const newValue = (parseFloat(balanceItems.get(currency_code)?.transactionalBalance ?? '0') + value).toString();
                balanceItems.set(currency_code, { ...defaultBalanceItem, ...(balanceItems.get(currency_code) ?? {}), transactionalBalance: newValue });
            }
            const newValue = (parseFloat(balanceItems.get(currency_code)?.totalBalance ?? '0') + value).toString();
            balanceItems.set(currency_code, { ...defaultBalanceItem, ...(balanceItems.get(currency_code) ?? {}), totalBalance: newValue });
        });
    });

    return (
        <main>
            {financialElementToTransfer && <Transference financialElement={financialElementToTransfer} availableCurrencies={Array.from(assets.find(a => a.financialElement.id === financialElementToTransfer.id)?.valuesItems.keys() ?? [])} close={() => setFinancialElementToTransfer(undefined)} />}
            <article className="h-fit bg-sf-yellow-light pb-0 row-start-1">
                <div className="flex w-full flex-row justify-between items-center">
                    <h2>Ahorros e inversiones</h2>
                    <button className="button-aux flex space-x-1 items-center" onClick={() => navigate('/assets/new')}>
                        <p>Nuevo</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                    </button>
                </div>
                {balanceItems.size !== 0 &&
                    <div className="pb-3 flex justify-between">
                        <div className="w-1/2">
                            <h3>Saldo total:</h3>
                            {Array.from(balanceItems.entries()).map(valuesItemEntry => {
                                const currencyCode = valuesItemEntry[0];
                                const totalBalance = valuesItemEntry[1].totalBalance;

                                return (
                                    <div key={currencyCode} className={`flex ${totalBalance.includes('-') ? 'expenditure' : 'income'} `}>
                                        <p className="font-mono">{totalBalance.includes('-') ? '-' : '+'}</p>
                                        <p className="ml-1">{getSign(currencyCode)}</p>
                                        <p className="ml-1">{getInteger(totalBalance)}</p>
                                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(totalBalance)}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="w-1/2">
                            <p>Solo Uso cotidiano:</p>
                            {Array.from(balanceItems.entries()).map(valuesItemEntry => {
                                const currencyCode = valuesItemEntry[0];
                                const transactionalBalance = valuesItemEntry[1].transactionalBalance;

                                return (
                                    <div key={currencyCode} className={`flex ${transactionalBalance.includes('-') ? 'expenditure' : 'income'} `}>
                                        <p className="font-normal font-mono">{transactionalBalance.includes('-') ? '-' : '+'}</p>
                                        <p className="font-normal ml-1">{getSign(currencyCode)}</p>
                                        <p className="font-normal ml-1">{getInteger(transactionalBalance)}</p>
                                        <p className="font-normal text-[11px]/[16px] ml-[1px]">{getDecimal(transactionalBalance)}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
                <ul className="divide-y divide-sf-black border-t border-sf-black">
                    {assets?.length > 0 ? assets?.map(asset => <AssetItem key={asset.financialElement.id} financialElement={asset.financialElement} valuesItems={asset.valuesItems} openTransference={setFinancialElementToTransfer} />)
                        : <EmptyList message="Aún no se cargaron ahorros o inversiones." />}
                </ul>
            </article>
        </main>
    );
}
