import StepLayout from './StepLayout';
import { useNavigate } from 'react-router-dom';
import Zen from '../../components/initial-profile/brand-images/Zen';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import { getIcon, expensesIconMapping } from '../../utils/icon-mapping-utils';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useRef, useState } from 'react';
import { defaultOperationDetail, OperationDetail } from '../../model/OperationDetail';
import TitleAmountCurrencyForm from './TitleAmountCurrencyForm';
import IncompleteStepListItem from '../../components/initial-profile/IncompleteStepListItem';
import StepListItem from '../../components/initial-profile/StepListItem';
import { useAPI } from '../../hooks/UseAPI';
import { Context } from '../../utils/context';
import { sortElements } from '../../utils/sort-elements';
import { OperationTypes } from '../../consts/OperationTypes';
import { clearString } from '../../utils/string-utils';
import { DEFAULT_CURRENCY_CODE } from '../../consts/currency-codes';

export default function ExpensesStep(): JSX.Element {
    const { client, setClient } = useContext(Context);
    const { listOperationDetails, createOperationDetailBatch, updateOperationDetailBatch, updateClient } = useAPI();
    const [expensesItems, setExpensesItems] = useState<OperationDetail[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [expensesItemToEdit, setExpensesItemToEdit] = useState<OperationDetail | null>(null);
    const shouldScrollToEnd = useRef(false);
    const endMarkerRef = useRef<HTMLElement>(null);
    const [modifiedExpensesItems, setModifiedExpensesItems] = useState<(string | undefined)[]>([]);

    useEffect(() => {
        async function setUp() {
            setLoading(true);
            try {
                const results = await listOperationDetails(client.id ?? '');
                const expensesItems = results.filter(operationDetail => operationDetail.type === OperationTypes.EXPENDITURE.key).map(expensesItem => ({ ...expensesItem, amount: expensesItem.amount?.replace('-', '') }));
                const now = new Date().getTime();
                const suggestions = ['Alquiler', 'Gimnasio', 'Supermercado'].map((title, i) => ({
                    ...defaultOperationDetail,
                    title,
                    amount: '0',
                    type: OperationTypes.EXPENDITURE.key,
                    status: 'ENABLED',
                    order_key: (now + i).toString()
                }));
                const currentExpensesItems = expensesItems.length > 0 ? expensesItems : suggestions;
                sortElements(currentExpensesItems, [
                    { field: 'order_key', asc: true },
                    { field: 'update_timestamp', asc: false },
                ]);
                setExpensesItems(currentExpensesItems);
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [client.id, listOperationDetails]);

    useEffect(() => {
        if (expensesItemToEdit === null && shouldScrollToEnd.current) {
            shouldScrollToEnd.current = false;
            setTimeout(() => endMarkerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }), 100);
        }
    }, [expensesItemToEdit]);

    const defaultCurrencyCode = client.currencies ? client.currencies[0]?.code ?? DEFAULT_CURRENCY_CODE : DEFAULT_CURRENCY_CODE;

    function isExpensesItemComplete(expensesItem: OperationDetail) {
        return expensesItem.title && expensesItem.amount && expensesItem.currency_code;
    }

    function submitForm(newOperationDetail: OperationDetail) {
        setExpensesItems(oldExpensesItems => {
            const newExpensesItems = oldExpensesItems.filter(item => clearString(item.title) !== clearString(newOperationDetail.title) || item.id !== '');
            const updateIndex = newExpensesItems.findIndex(item => item.order_key === newOperationDetail.order_key);
            if (updateIndex === -1) {
                newExpensesItems.push(newOperationDetail);
                shouldScrollToEnd.current = true;
            } else {
                newExpensesItems[updateIndex] = newOperationDetail;
                setModifiedExpensesItems(prev => [...prev, newOperationDetail.id ?? '']);
            }

            return newExpensesItems;
        });
        setExpensesItemToEdit(null);
        setClient(oldClient => {
            const newCurrencies = [...oldClient.currencies ?? []];
            newCurrencies.unshift({
                code: newOperationDetail.currency_code
            });
            const uniqueCurrencies = newCurrencies.filter((currency, index, self) => index === self.findIndex(c => c.code === currency.code));

            return {
                ...oldClient,
                currencies: uniqueCurrencies
            };
        });
    }

    async function submitStep() {
        setLoading(true);
        try {
            const expensesItemsToCreate = expensesItems.filter(expensesItem => expensesItem.id === '' && expensesItem.amount !== '0').map(expensesItem => ({ ...expensesItem, amount: `-${expensesItem.amount}` }));
            const creatingExpensesItemsPromise = expensesItemsToCreate.length > 0 ? createOperationDetailBatch(client.id ?? '', expensesItemsToCreate) : Promise.resolve();

            const expensesItemsToUpdate = expensesItems.filter(expensesItem => expensesItem.id && modifiedExpensesItems.includes(expensesItem.id)).map(expensesItem => ({ ...expensesItem, amount: `-${expensesItem.amount}` }));
            const updateExpensesItemsPromise = expensesItemsToUpdate.length > 0 ? updateOperationDetailBatch(client.id ?? '', expensesItemsToUpdate) : Promise.resolve();

            const updateClientPromese = updateClient(client.id ?? '', client);

            await Promise.all([creatingExpensesItemsPromise, updateExpensesItemsPromise, updateClientPromese]);
        } finally {
            navigate('/4');
            setLoading(false);
        }
    }

    return (<>
        <StepLayout
            endMarkerRef={endMarkerRef}
            currentStep={3}
            totalSteps={6}
            goBack={() => navigate('/2')}
            goToNext={submitStep}
            skip={() => navigate('/4')}
            title='Egresos'
            description='Ahora piensa en tus egresos. Nos ayudará a controlar mejor tus finanzas.'
            isButtonDisabled={expensesItems.find(isExpensesItemComplete) === undefined}
            loading={loading}
            buttonText='Siguiente'
            image={<Zen />}
        >
            <Box sx={{
                gap: '18px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                {expensesItems.map((expensesItem, index) =>
                    <Box key={index} sx={{ width: '100%' }}>
                        {isExpensesItemComplete(expensesItem) ?
                            <StepListItem
                                icon={getIcon(expensesItem.title, expensesIconMapping)}
                                item={expensesItem}
                                onClick={() => setExpensesItemToEdit(expensesItem)}
                            />
                            :
                            <IncompleteStepListItem
                                icon={getIcon(expensesItem.title, expensesIconMapping)}
                                item={expensesItem}
                                onClick={() => setExpensesItemToEdit({ ...expensesItem, currency_code: defaultCurrencyCode })}
                            />
                        }
                        <Divider />
                    </Box>
                )}
                <Button
                    onClick={() => setExpensesItemToEdit({
                        ...defaultOperationDetail,
                        amount: '0',
                        currency_code: defaultCurrencyCode,
                        type: OperationTypes.EXPENDITURE.key,
                        status: 'ENABLED',
                        order_key: new Date().getTime().toString(),
                    })}
                    variant='plain'
                    color='neutral'
                    startDecorator={
                        <AddIcon sx={{
                            width: '24px',
                            height: '24px',
                            color: 'currentColor'
                        }} />
                    }
                >
                    Nuevo gasto
                </Button>
            </Box>
        </StepLayout>
        <TitleAmountCurrencyForm
            goBack={() => setExpensesItemToEdit(null)}
            formTitle='Agregar gasto'
            titlePlaceholder='Nombre del gasto'
            item={expensesItemToEdit}
            submit={newOperationDetail => submitForm({ ...newOperationDetail, amount: parseFloat(newOperationDetail.amount || '0').toString() })}
        />
    </>);
}
