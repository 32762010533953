interface SafeAreaInsetBottomProps {
    minValue: string,
    handleKeyboard: boolean,
}

export default function SafeAreaInsetBottom(props: SafeAreaInsetBottomProps): JSX.Element {
    return (
        <div style={{
            minHeight: `max(env(safe-area-inset-bottom, ${props.minValue}), ${props.minValue})`,
        }}>
            {props.handleKeyboard && <div style={{
                minHeight: `calc(var(--keyboard-height, 0px) + ${props.minValue})`,
                transition: 'min-height 0.33s cubic-bezier(0, .15, .15, 1)',
            }} />}
        </div>
    );
}
