import Box from "@mui/joy/Box";
import { applySolidInversion } from "@mui/joy/colorInversion";
import SomosfinLogo from "./brand-images/SomosfinLogo";

export default function DesktopHeader(): JSX.Element {
    return (
        <Box sx={[(theme) => ({
            minHeight: '60px',
            height: '60px',
            width: '100%',
            backgroundColor: theme.vars.palette.neutral[700],
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            zIndex: 1,
        }), applySolidInversion('neutral')]}>
            <Box sx={{
                maxWidth: '532px',
                width: '100%',
                px: 2,
                py: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
            }}>
                <Box sx={{
                    width: '118px',
                    fill: 'white',
                }}>
                    <SomosfinLogo />
                </Box>
            </Box>
        </Box>
    );
}