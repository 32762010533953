import { Operation, defaultOperation } from '../model/Operation';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Context } from '../utils/context';
import { useSearchParams } from 'react-router-dom';
import Loading from './Loading';
import { OperationTypes } from '../consts/OperationTypes';
import Select from './Select';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { processDecimalInput } from '../utils/process-decimal-input';
import { sortElements } from '../utils/sort-elements';
import { v4 as uuid } from 'uuid';
import { addDaysToTimestamp, addMonthsToTimestamp, getFormattedLocaleDate, getHourInMilis, ifDateIsValidThen } from '../utils/date-utils';
import OperationsService from '../utils/OperationsService';
import { useAPI } from '../hooks/UseAPI';

export default function OperationForm(): JSX.Element {
    const { client, addFeedback, financialElements, operationDetails } = useContext(Context);
    const { listOperations, createOperation, createOperationBatch } = useAPI();
    const [selectedType, setSelectedType] = useState<OperationTypes>(OperationTypes.EXPENDITURE);
    const [loading, setLoading] = useState(true);
    const [operation, setOperation] = useState({ ...defaultOperation });
    const { getSign } = useCurrencyByCode();
    const [searchParams] = useSearchParams();
    const [installments, setInstallments] = useState('1');
    const [date, setDate] = useState<Date>();
    const [operations, setOperations] = useState<Operation[]>([]);

    useEffect(() => {
        try {
            setLoading(true);

            const now = new Date();
            setDate(now);
            const operation = {
                ...defaultOperation,
                timestamp: getFormattedLocaleDate(now),
                currency_code: client.currencies ? client.currencies[0]?.code ?? '' : ''
            };

            const typeParam = searchParams.get('type');
            if (typeParam) {
                const operationTypeParam = OperationTypes.get(typeParam);
                if (operationTypeParam) {
                    setSelectedType(operationTypeParam);
                }

                const operationDetailIdParam = searchParams.get('operation_detail_id');
                if (operationDetailIdParam && operationDetails.find(operationDetail => operationDetail.id === operationDetailIdParam)?.type === typeParam) {
                    operation['operation_detail_id'] = operationDetailIdParam;
                }
            }

            const financialElementIdParam = searchParams.get('financial_element_id');
            if (financialElementIdParam) {
                operation['financial_element_id'] = financialElementIdParam;
            }

            const currencyCodeParam = searchParams.get('currency_code');
            if (currencyCodeParam) {
                operation['currency_code'] = currencyCodeParam;
            }

            setOperation(operation);
            sortElements(financialElements, [
                { field: 'transactional', asc: false },
                { field: 'update_timestamp', asc: false },
            ]);
            sortElements(operationDetails, [
                { field: 'update_timestamp', asc: false },
            ]);
        } finally {
            setLoading(false);
        }
    }, [client.currencies, searchParams, financialElements, operationDetails]);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id) return;

            const today = new Date();
            const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
            const from = addMonthsToTimestamp(UTCtoday, -3);
            const to = addDaysToTimestamp(UTCtoday, 1);

            OperationsService.listOperationsByRange(client.id, from, to, listOperations).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, listOperations]);

    function handleInputChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        setOperation(oldOperation => ({
            ...oldOperation,
            [target.name]: target.value
        }));
    }

    function handleOperationDetailIdChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const operationDetailId = target.value;
        setOperation(oldOperation => {
            const newOperation = {
                ...oldOperation,
                operation_detail_id: operationDetailId
            };

            if (oldOperation['financial_element_id'] === '') {
                const financialElementIdsCount = new Map<string, number>();
                operations.filter(oper => oper.operation_detail_id === operationDetailId)
                    .map(oper => oper.financial_element_id ?? '')
                    .forEach(financialElementId => {
                        financialElementIdsCount.set(financialElementId, (financialElementIdsCount.get(financialElementId) ?? 0) + 1);
                    });
                if (financialElementIdsCount.size > 0) {
                    newOperation['financial_element_id'] = Array.from(financialElementIdsCount.entries()).sort((a, b) => b[1] - a[1])[0][0];
                }
            }

            const currencyCodesCount = new Map<string, number>();
            operations.filter(oper => oper.operation_detail_id === operationDetailId && oper.financial_element_id === newOperation['financial_element_id'])
                .map(oper => oper.currency_code ?? '')
                .forEach(currencyCode => {
                    currencyCodesCount.set(currencyCode, (currencyCodesCount.get(currencyCode) ?? 0) + 1);
                });
            if (currencyCodesCount.size > 0) {
                newOperation['currency_code'] = Array.from(currencyCodesCount.entries()).sort((a, b) => b[1] - a[1])[0][0];
            }

            return newOperation;
        });

        setInstallments('1');
    }

    function handleSelectType(operationType: OperationTypes) {
        setSelectedType(operationType);
        setOperation(oldOperation => ({
            ...oldOperation,
            operation_detail_id: '',
            financial_element_id: '',
            currency_code: client.currencies ? client.currencies[0]?.code ?? '' : ''
        }));
    }

    function resetOperation() {
        setLoading(true);
        setDate(new Date());
        setInstallments('1');
        setOperation(oldOperation => ({
            ...defaultOperation,
            timestamp: oldOperation.timestamp,
            currency_code: client.currencies ? client.currencies[0]?.code ?? '' : ''
        }));
        setLoading(false);
    }

    function saveChanges() {
        setLoading(true);
        if ((installmentsFinancialElement?.deferred ?? '') !== '' && parseFloat(installments) > 1) {
            const operationsToCreate: Operation[] = [];
            const grouping_id = uuid();
            const totalAmount = (parseFloat(operation.amount ?? '0') * parseFloat(installments)).toString();
            operationsToCreate.push({
                ...defaultOperation,
                financial_element_id: installmentsFinancialElement?.deferred,
                grouping_id: grouping_id,
                currency_code: operation.currency_code,
                amount: selectedType === OperationTypes.EXPENDITURE ? `-${totalAmount}` : totalAmount,
                hour: getHourInMilis(date).toString(),
                timestamp: new Date(operation.timestamp ?? '').getTime().toString(),
                description: `Programación de cuotas.`
            });
            for (let installment = 0; installment < parseFloat(installments); installment++) {
                operationsToCreate.push({
                    ...operation,
                    grouping_id: grouping_id,
                    amount: selectedType === OperationTypes.EXPENDITURE ? `-${operation.amount}` : operation.amount,
                    hour: (getHourInMilis(date) + (2 * (installment + 1)) - 1).toString(),
                    timestamp: addMonthsToTimestamp(new Date(operation.timestamp ?? '').getTime().toString(), installment),
                    description: `${operation.description !== '' ? `${operation.description}, cuota ` : ''}${installment + 1}/${installments}`
                }, {
                    ...defaultOperation,
                    financial_element_id: installmentsFinancialElement?.deferred,
                    grouping_id: grouping_id,
                    currency_code: operation.currency_code,
                    amount: selectedType === OperationTypes.EXPENDITURE ? operation.amount : `-${operation.amount}`,
                    hour: (getHourInMilis(date) + (2 * (installment + 1))).toString(),
                    timestamp: addMonthsToTimestamp(new Date(operation.timestamp ?? '').getTime().toString(), installment),
                    description: `Aplicación de cuota ${installment + 1}/${installments}`
                });
            }
            OperationsService.createOperationBatch(client.id ?? '', operationsToCreate, createOperationBatch).then(() => {
                addFeedback({ message: '¡Movimiento cargado con éxito!', level: 'success' });
                resetOperation();
            });
        } else {
            OperationsService.createOperation(client.id ?? '', {
                ...operation,
                amount: selectedType === OperationTypes.EXPENDITURE ? `-${operation.amount}` : operation.amount,
                timestamp: new Date(operation.timestamp ?? '').getTime().toString(),
                hour: getHourInMilis(date).toString(),
            }, createOperation).then(() => {
                addFeedback({ message: '¡Movimiento cargado con éxito!', level: 'success' });
                resetOperation();
            });
        }
    }

    const expenditureOperationDetails = operationDetails
        .filter(operationDetail => operationDetail.status === 'ENABLED' && operationDetail.type === OperationTypes.EXPENDITURE.key)
        .map(operationDetail => ({ value: operationDetail.id, alias: operationDetail.title }));

    const incomeOperationDetails = operationDetails
        .filter(operationDetail => operationDetail.status === 'ENABLED' && operationDetail.type === OperationTypes.INCOME.key)
        .map(operationDetail => ({ value: operationDetail.id, alias: operationDetail.title }));

    const financialElementsOptions = financialElements
        .filter(financialElement => financialElement.status === 'ENABLED' && financialElement.transactional)
        .map(financialElement => ({ value: financialElement.id, alias: financialElement.title }));

    const installmentsFinancialElement = financialElements
        .find(financialElement => financialElement.status === 'ENABLED' && financialElement.id === operation.financial_element_id);

    if (loading) return <Loading />

    return (
        <article className='bg-sf-violet-light'>
            <h2>Nuevo movimiento</h2>
            <div className='border-t border-black w-full h-min pb-1'></div>
            <div className='flex space-x-3'>
                <div className='field p-2 w-min space-x-0.5'>
                    {[OperationTypes.EXPENDITURE, OperationTypes.INCOME].map(type => (
                        <div key={type.key} className='flex items-center space-x-1 cursor-pointer hover:bg-sf-gray-extra-light rounded-lg p-1' onClick={() => handleSelectType(type)}>
                            <div className={`rounded-full h-3.5 w-3.5 ${type.key === selectedType?.key ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></div>
                            <div>{type.label}</div>
                        </div>
                    ))}
                </div>
                <input name='timestamp' type='date' value={operation.timestamp} onChange={ifDateIsValidThen(handleInputChange)} className='field' />
            </div>
            <Select placeholder='Selecciona una categoría de movimiento *' name='operation_detail_id' value={operation.operation_detail_id} onChange={handleOperationDetailIdChange} options={selectedType === OperationTypes.EXPENDITURE ? expenditureOperationDetails : incomeOperationDetails} />
            <Select placeholder='Selecciona una cuenta *' name='financial_element_id' value={operation.financial_element_id} onChange={handleInputChange} options={financialElementsOptions} />
            {(installmentsFinancialElement?.deferred ?? '') !== '' &&
                <div>
                    <p className='pl-1 pt-1 pb-3'>Cantidad de cuotas:</p>
                    <input type='text' inputMode='decimal' name='installments' placeholder='Ingrese la cantidad de cuotas *' value={installments} onChange={e => e.target.value.match(/^[0-9]{0,2}$/) && setInstallments(e.target.value)} className='field' />
                    <p className='pl-1 pt-4'>Monto de cada cuota:</p>
                </div>
            }
            <div className='flex space-x-3'>
                <div className='w-48'>
                    <Select placeholder='Moneda' name='currency_code' value={operation.currency_code} onChange={handleInputChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                </div>
                <input type='text' inputMode='decimal' name='amount' placeholder='Ingrese el monto *' value={operation.amount} onChange={e => processDecimalInput(e) && handleInputChange(e)} className='field' />
            </div>
            <input name='description' type='text' placeholder='Descripción (opcional)' value={operation.description} onChange={handleInputChange} className='field' />
            <button className="button-primary self-end" disabled={!operation.financial_element_id || !operation.operation_detail_id || !operation.currency_code || !operation.amount || !operation.timestamp || parseFloat(operation.amount) === 0 || ((installmentsFinancialElement?.deferred ?? '') !== '' && (installments === '' || parseFloat(installments) === 0))} onClick={saveChanges}>
                Guardar
            </button>
        </article>
    );
}
