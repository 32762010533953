import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { ReactNode } from "react";
import CurrencyAmount from "./CurrencyAmount";
import { OperationDetail } from "../../model/OperationDetail";
import { FinancialElement } from "../../model/FinancialElement";

interface StepListItemProps {
    icon: ReactNode
    item: OperationDetail | FinancialElement
    onClick: () => void
}

export default function StepListItem(props: StepListItemProps): JSX.Element {
    return (
        <Box onClick={props.onClick} sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            cursor: 'pointer',
            paddingBottom: '18px',
        }}>
            <Box sx={{
                display: 'flex',
                gap: 2,
                width: '100%',
                justifyContent: 'start',
                alignItems: 'center',
            }}>
                <Box sx={theme => ({
                    width: '44px',
                    height: '44px',
                    borderRadius: '22px',
                    backgroundColor: theme.vars.palette.primary.outlinedActiveBg,
                    color: theme.vars.palette.primary.outlinedColor,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                })}>
                    <Box sx={{ width: '24px', strokeWidth: '2px' }}>
                        {props.icon}
                    </Box>
                </Box>
                <Box>
                    <Typography level="title-sm" fontWeight='lg'>
                        {props.item.title}
                    </Typography>
                    <Box sx={theme => ({
                        color: theme.vars.palette.text.primary
                    })}>
                        <CurrencyAmount size={14} hideSign currency_code={props.item.currency_code} amount={props.item.amount || '0'} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
