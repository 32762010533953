import { RefObject, useCallback, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import SafeAreaInsetBottom from '../../components/initial-profile/SafeAreaInsetBottom';
import SafeAreaInsetTop from '../../components/initial-profile/SafeAreaInsetTop';
import DesktopHeader from '../../components/initial-profile/DesktopHeader';
import Loading from '../../components/Loading';

interface StepLayoutProps {
    currentStep: number,
    totalSteps: number,
    goBack?: () => void,
    skip: () => void,
    goToNext: () => void,
    title: string,
    description: string,
    buttonText: string,
    isButtonDisabled: boolean,
    loading: boolean,
    image?: React.ReactNode,
    children?: React.ReactNode,
    endMarkerRef?: RefObject<HTMLElement>,
}

export default function StepLayout(props: StepLayoutProps) {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [expandedHeaderHeight, setExpandedHeaderHeight] = useState(0);
    const expandedHeaderRef = useCallback((node: HTMLElement | null) => node && new ResizeObserver(() => setExpandedHeaderHeight(old => Math.max(old, node.clientHeight))).observe(node), [setExpandedHeaderHeight]);

    const [footerHeight, setFooterHeight] = useState(0);
    const footerRef = useCallback((node: HTMLElement | null) => node && new ResizeObserver(() => setFooterHeight(old => Math.max(old, node.clientHeight))).observe(node), [setFooterHeight]);

    const goBackButton = (
        <IconButton size='md' onClick={props.goBack} sx={{
            display: { xs: 'flex', md: 'none' },
            p: 0,
            justifyContent: 'start',
            minWidth: '40px',
            minHeight: '40px',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }}>
            <ArrowBackRoundedIcon sx={{
                color: 'text.primary',
            }} />
        </IconButton>
    );

    const skip = (
        <>
            <Button size='md' variant='plain' color='neutral' onClick={props.skip} sx={{
                display: { xs: 'block', md: 'none' },
                minHeight: '40px',

                pr: 0,
                color: 'text.primary',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}>
                Saltar
            </Button>
            <Button variant='soft' color='neutral' onClick={props.skip} sx={{
                display: { xs: 'none', md: 'block' },
                minHeight: '40px'
            }}>
                Saltar
            </Button>
        </>
    );

    const collapsedHeader = (
        <Box sx={{
            position: 'fixed',
            zIndex: 2,
            width: '100%',
            backgroundColor: '#EDE2D4',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <DesktopHeader />
            <SafeAreaInsetTop minValue='16px' />
            <Box sx={{
                gap: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 2,
                pb: 2,
                width: '100%',
                maxWidth: '532px',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    {props.goBack && goBackButton}
                    <Typography level='h4' fontFamily={'ESRebondGrotesque Medium'}>
                        {props.title}
                    </Typography>
                </Box>
                {skip}
            </Box>
        </Box>
    );

    const expandedHeader = (
        <Box ref={expandedHeaderRef} sx={{
            position: 'fixed',
            zIndex: 2,
            width: '100%',
            backgroundColor: '#EDE2D4',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <DesktopHeader />
            <SafeAreaInsetTop minValue='16px' />
            <Box sx={{
                gap: 1,
                display: 'flex',
                flexDirection: 'column',
                px: 2,
                pb: 2,
                width: '100%',
                maxWidth: '532px',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                    width: '100%',
                    zIndex: 2,
                }}>
                    {skip}
                    {props.goBack && goBackButton}
                </Box>
                <Typography level='body-xs' textColor='text.icon' fontWeight='lg'>
                    Paso {props.currentStep} de {props.totalSteps}
                </Typography>
                <Box>
                    <Typography level='h1' fontFamily={'ESRebondGrotesque Medium'}>
                        {props.title}
                    </Typography>
                    <Typography level='body-sm' textColor='text.primary' sx={{
                        pr: '68px'
                    }}>
                        {props.description}
                    </Typography>
                </Box>
                <Box sx={{
                    height: `${expandedHeaderHeight}px`,
                    position: 'fixed',
                    top: 0,
                    right: { xs: '-50px', md: '-75px' },
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}>
                    <SafeAreaInsetTop minValue='16px' />
                    <Box sx={{
                        pt: { xs: '30px', md: '45px' },
                        width: { xs: '120px', md: '180px' },
                        height: '100%',
                        fill: '#F5802E',
                        opacity: '15%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {props.image}
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const loading = (
        <Box sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Loading />
        </Box>
    );

    const body = (
        <Box onScroll={event => setIsCollapsed((event.target as HTMLElement).scrollTop > expandedHeaderHeight - 72)} sx={{
            [isCollapsed ? 'pt' : 'mt']: `${expandedHeaderHeight}px`,
            mb: `${footerHeight}px`,
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        }}>
            <Box sx={{
                py: 4,
                px: 2,
                height: '100%',
                width: '100%',
                maxWidth: '532px',
            }}>
                {props.loading ? loading : props.children}
                <Box ref={props.endMarkerRef} />
            </Box>
        </Box>
    );

    const footer = (
        <Box ref={footerRef} sx={{
            position: 'fixed',
            zIndex: 2,
            bottom: 0,
            width: '100%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Box sx={{
                pt: 2,
                px: 2,
                gap: 3,
                width: '100%',
                maxWidth: '532px',
                display: 'flex',
            }}>
                {props.goBack &&
                    <Button size='lg' variant='outlined' color='neutral' fullWidth onClick={props.goBack} sx={{
                        display: { xs: 'none', md: 'block' },
                    }}>
                        Atrás
                    </Button>
                }
                <Button size='lg' fullWidth disabled={props.isButtonDisabled || props.loading} onClick={props.goToNext}>
                    {props.buttonText}
                </Button>
            </Box>
            <SafeAreaInsetBottom minValue='16px' handleKeyboard={false} />
        </Box>
    );

    return (
        <Box sx={{
            zIndex: 1,
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            {isCollapsed ? collapsedHeader : expandedHeader}
            {expandedHeaderHeight > 0 && body}
            {footer}
        </Box>
    );
}
