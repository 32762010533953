import Box from "@mui/joy/Box";
import FormLayout from "./FormLayout";
import { ISO_4217_CURRENCY_CODES } from "../../consts/currency-codes";
import { useEffect, useRef, useState } from "react";
import TitleInput from "../../components/initial-profile/TitleInput";
import AmountInput from "../../components/initial-profile/AmountInput";
import CurrencyCodeForm from "./CurrencyCodeForm";
import Chip from "@mui/joy/Chip";
import { FinancialElement } from "../../model/FinancialElement";
import { OperationDetail } from "../../model/OperationDetail";

interface TitleAmountCurrencyFormProps {
    goBack: () => void,
    formTitle: string,
    titlePlaceholder: string,
    item: FinancialElement | OperationDetail | null,
    submit: (newItem: FinancialElement | OperationDetail) => void,
}

export default function TitleAmountCurrencyForm(props: TitleAmountCurrencyFormProps): JSX.Element {
    const [focusedElement, setFocusedElement] = useState<string>();
    const [isCurrencyCodeFormOpen, setIsCurrencyCodeFormOpen] = useState(false);
    const [title, setTitle] = useState<string>();
    const [amount, setAmount] = useState<string>();
    const [currencyCode, setCurrencyCode] = useState<string>();
    const inputTitleRef = useRef<HTMLInputElement>(null);
    const inputAmountRef = useRef<HTMLInputElement>(null);

    const titleElementName = 'title';
    const amountElementName = 'amount';
    const currencyCodeElementName = 'currency_code';

    useEffect(() => {
        if (props.item) {
            setTitle(props.item.title ?? '');
            setAmount(props.item.amount ?? '');
            setCurrencyCode(props.item.currency_code ?? '');
            if ((props.item.title ?? '') === '') {
                inputTitleRef.current?.click();
            } else {
                inputAmountRef.current?.click();
            }
        } else {
            setTitle(undefined);
            setAmount(undefined);
            setCurrencyCode(undefined);
        }
    }, [props.item]);

    const incompleteVariables = {
        [titleElementName]: (title?.length ?? 0) < 3,
        [amountElementName]: parseFloat(amount || '0') <= 0,
        [currencyCodeElementName]: (currencyCode?.length ?? 0) < 3
    };

    const keys = Object.keys(incompleteVariables);

    const isButtonDisabled = focusedElement && focusedElement in incompleteVariables
        ? keys.slice(0, keys.indexOf(focusedElement) + 1).some(key => incompleteVariables[key as keyof typeof incompleteVariables])
        : Object.values(incompleteVariables).some(value => value);

    function submit() {
        if (props.item === undefined) {
            return;
        }

        if (focusedElement === titleElementName) {
            inputAmountRef.current?.click();

            return;
        }

        props.submit({ ...props.item, title, amount, currency_code: currencyCode });
    }

    return (
        <>
            <FormLayout
                goBack={props.goBack}
                submit={submit}
                title={props.formTitle}
                isButtonDisabled={isButtonDisabled}
                visible={props.item !== null && title !== undefined && amount !== undefined && currencyCode !== undefined}
                buttonText='Siguiente'
            >
                <Box sx={{
                    py: 6,
                    gap: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <TitleInput
                        value={title ?? ''}
                        setValue={setTitle}
                        placeholder={props.titlePlaceholder}
                        submit={() => !isButtonDisabled && submit()}
                        onFocus={() => setFocusedElement(titleElementName)}
                        inputRef={inputTitleRef}
                    />
                    <AmountInput
                        value={amount ?? ''}
                        setValue={setAmount}
                        currency={ISO_4217_CURRENCY_CODES.find(currency => currency.code === currencyCode)?.symbol ?? '$'}
                        submit={() => !isButtonDisabled && submit()}
                        onFocus={() => setFocusedElement(amountElementName)}
                        onBlur={() => setFocusedElement(old => old === amountElementName ? undefined : old)}
                        inputRef={inputAmountRef}
                    />
                    <Chip
                        variant='outlined'
                        onClick={() => setIsCurrencyCodeFormOpen(true)}
                        startDecorator={
                            <Box sx={{ width: '20px', height: '20px' }}>
                                {ISO_4217_CURRENCY_CODES.find(currency => currency.code === currencyCode)?.icon}
                            </Box>
                        }
                        sx={{
                            minHeight: '40px',
                            minWidth: '40px',
                            pr: 1.5,
                            pl: 0.75,
                            gap: 0.75,
                            cursor: 'pointer'
                        }}
                    >
                        Moneda {currencyCode}
                    </Chip>
                </Box>
            </FormLayout>
            <CurrencyCodeForm
                goBack={() => setIsCurrencyCodeFormOpen(false)}
                initialCurrencyCode={currencyCode}
                visible={isCurrencyCodeFormOpen}
                submit={newCurrencyCode => {
                    setCurrencyCode(newCurrencyCode);
                    setIsCurrencyCodeFormOpen(false);
                }}
            />
        </>
    );
}
