import { useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import SafeAreaInsetBottom from '../../components/initial-profile/SafeAreaInsetBottom';
import SafeAreaInsetTop from '../../components/initial-profile/SafeAreaInsetTop';
import SomosfinLogo from '../../components/initial-profile/brand-images/SomosfinLogo';
import Typography from '@mui/joy/Typography';
import Playground from '../../components/initial-profile/brand-images/Playground';

export default function Welcome(): JSX.Element {
    const navigate = useNavigate();

    return (window.innerWidth < 900 ?
        <Box sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100vw',
            height: '100vh',
        }}>
            <Box sx={{
                backgroundColor: 'white',
                minHeight: '60%',
                overflow: 'hidden'
            }}>
                <SafeAreaInsetTop minValue='16px' />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}>
                    <Box sx={{
                        width: '100%',
                        maxWidth: '140px',
                    }}>
                        <SomosfinLogo />
                    </Box>
                    <Box sx={{
                        p: 2,
                        width: '100%',
                        maxWidth: '370px',
                    }}>
                        <Playground />
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                minHeight: '40%',
            }}>
                <Box sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    maxWidth: '337px',
                    pt: 2,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        textAlign: 'center',
                        gap: 2.5,
                    }}>
                        <Typography level='h2' fontFamily='ESRebondGrotesque Medium'>Comienza tu camino al<br />bienestar financiero</Typography>
                        <Typography level='body-md'>Completa lo que puedas de tu perfil.<br />Es el primer paso para entender tus finanzas y alcanzar tus objetivos.</Typography>
                    </Box>
                    <Box />
                </Box>
                <Box sx={{
                    pt: 2,
                    px: 2,
                    width: '100%',
                    maxWidth: '532px',
                }}>
                    <Button size='lg' fullWidth onClick={() => navigate('/1')}>
                        Completar perfil
                    </Button>
                    <SafeAreaInsetBottom minValue='16px' handleKeyboard={false} />
                </Box>
            </Box>
        </Box>
        :
        <Box sx={{
            position: 'fixed',
            display: 'flex',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                height: '100vh',
                left: 0,
                width: '60vw',
                backgroundColor: 'white',
                zIndex: -1,
            }} />
            <Box sx={{
                width: '100%',
                maxWidth: '1024px',
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                    gap: 4,
                    p: 2,
                }}>
                    <Box sx={{
                        width: '100%',
                        maxWidth: '150px',
                    }}>
                        <SomosfinLogo />
                    </Box>
                    <Typography level='h1' fontFamily='ESRebondGrotesque Medium' sx={{
                        fontSize: '50px'
                    }}>Comienza tu camino<br />al bienestar financiero</Typography>
                    <Typography level='body-md' sx={{
                        maxWidth: '343px'
                    }}>Completa lo que puedas de tu perfil.<br />Es el primer paso para entender tus finanzas y alcanzar tus objetivos.</Typography>
                    <Button size='lg' fullWidth onClick={() => navigate('/1')} sx={{ width: '238px' }}>
                        Completar perfil
                    </Button>
                </Box>
                <Box sx={{
                    p: 2,
                    width: '100%',
                    maxWidth: '431px',
                }}>
                    <Playground />
                </Box>
            </Box>
        </Box>
    );
}
