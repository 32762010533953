import Box from "@mui/joy/Box";
import Input from "@mui/joy/Input";
import { RefObject, SyntheticEvent, useState } from "react";
import { getDecimal, getInteger } from "../../utils/number-utils";
import { processDecimalInput } from "../../utils/process-decimal-input";
import Typography from "@mui/joy/Typography";

interface AmountInputProps {
    currency: string;
    value: string | undefined;
    setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
    submit?: () => void;
    inputRef: RefObject<HTMLInputElement>;
    onFocus?: () => void;
    onBlur?: () => void;
}

export default function AmountInput(props: AmountInputProps): JSX.Element {
    const [focused, setFocused] = useState(false);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    function changeValue(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        let value = target.value;
        if (!/^\d/.test(value)) {
            value = '0' + value;
        }
        value = value.replace(/^00/, '0')
        value = value.replace(/\.(\d)0$/, '.$1');
        props.setValue(value);
    }

    function clickOnInteger() {
        props.inputRef?.current?.click();
        const dotIndex = (props.value ?? '').indexOf('.');
        if (dotIndex !== -1 && props.inputRef.current) {
            (document.activeElement as HTMLInputElement).setSelectionRange(dotIndex, dotIndex);
            setCursorPosition(dotIndex);
            if (dotIndex + 1 === (props.value ?? '').length) {
                props.setValue(old => old && old.substring(0, old.length - 1));
            }
        }
    }

    function clickOnDecimal() {
        props.inputRef?.current?.click();
        props.setValue(old => ((old ?? '').includes('.') ? old : (old ?? '').length === 0 ? '0.' : old + '.'));
        (document.activeElement as HTMLInputElement).setSelectionRange((props.value ?? '').length, (props.value ?? '').length);
        setCursorPosition((props.value ?? '').length + 2);
    }

    function handleSelection(event: SyntheticEvent) {
        const input = event.target as HTMLInputElement;
        if (input.selectionStart === 0) { // Buggy behavior in Android keyboard
            clickOnDecimal();
        } else {
            setCursorPosition(input.selectionStart);
        }
    }

    const isDecimalSelected = (props.value ?? '').includes('.') && cursorPosition !== null && cursorPosition > (props.value ?? '').indexOf('.');

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Input
                ref={props.inputRef}
                slotProps={{ input: { inputMode: 'decimal' } }}
                onFocus={() => { setFocused(true); props.onFocus && props.onFocus(); }}
                onBlur={() => { setFocused(false); props.onBlur && props.onBlur(); }}
                onSelect={handleSelection}
                value={props.value}
                onKeyDown={event => event.key === 'Enter' && props.submit?.()}
                onChange={event => processDecimalInput(event) && changeValue(event)}
                sx={{
                    width: 0,
                    p: 0,
                    'input': {
                        caretColor: 'transparent',
                    },
                    'input:focus': {
                        boxShadow: 'none',
                    },
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    ':focus-within::before, :hover::before': {
                        boxShadow: 'none',
                        border: 'none',
                    },
                }}
            />
            <Typography sx={{
                fontFamily: 'ESRebondGrotesque Medium',
                fontWeight: '700',
                fontSize: '24px',
                height: '29px',
                lineHeight: '29px',
                px: '6.5px',
            }}>
                {props.currency}
            </Typography>
            <Box sx={{
                display: 'flex',
            }}>
                <Typography
                    onClick={clickOnInteger}
                    sx={theme => ({
                        cursor: 'text',
                        fontFamily: 'ESRebondGrotesque Medium',
                        fontWeight: '700',
                        fontSize: '50px',
                        height: '60px',
                        lineHeight: '60px',
                        borderWidth: '1px',
                        borderColor: 'transparent',
                        borderBottomColor: focused && !isDecimalSelected ? theme.vars.palette.text.primary : 'transparent',
                    })}
                >
                    {getInteger(props.value || '0')}
                </Typography>
                <Typography
                    onClick={clickOnDecimal}
                    sx={theme => ({
                        cursor: 'text',
                        fontFamily: 'ESRebondGrotesque Medium',
                        fontWeight: '700',
                        fontSize: '14px',
                        height: '20px',
                        lineHeight: '20px',
                        borderWidth: '1px',
                        borderColor: 'transparent',
                        borderBottomColor: focused && isDecimalSelected ? theme.vars.palette.text.primary : 'transparent',
                    })}
                >
                    {getDecimal(props.value || '0')}
                </Typography>
            </Box>
        </Box>
    );
}
