export default function Shapes(): JSX.Element {
    return (
        <svg viewBox="0 0 220 223" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_576_6428)">
                <path d="M145.199 161.283L162.601 171.33C164.167 172.234 166.178 171.696 167.001 170.085C183.159 138.425 171.951 99.3762 140.95 81.4776C109.949 63.5791 70.5318 73.3996 51.1889 103.22C50.2047 104.738 50.7437 106.749 52.31 107.653L67.0643 116.172C69.9083 117.814 68.843 122.569 65.6266 123.231C48.5428 126.751 32.8692 136.888 22.7197 152.531C21.7352 154.049 22.2737 156.061 23.8401 156.965L134.135 220.644C135.702 221.549 137.713 221.009 138.535 219.398C147.504 201.814 148.035 181.955 141.426 164.705C140.566 162.46 143.117 160.081 145.199 161.283Z" fill="#FFBF3F" />
            </g>
            <path d="M166.823 17.2117L170.791 21.1078L176.14 20.6508L172.497 24.5936L173.138 30.1178L169.169 26.2217L163.82 26.6786L167.464 22.7358L166.823 17.2117Z" fill="#FFBF3F" />
            <path d="M182.864 94.2539L188.281 95.5127L192.609 92.3365L191.552 97.5999L194.977 101.982L189.56 100.723L185.232 103.899L186.289 98.6356L182.864 94.2539Z" fill="#FFBF3F" />
            <path d="M26.8277 182.946L25.4731 177.552L20.6708 175.153L25.8354 173.688L28.169 168.64L29.5235 174.034L34.3259 176.433L29.1613 177.898L26.8277 182.946Z" fill="#FFBF3F" />
            <circle cx="172.746" cy="62.8533" r="21.8787" fill="#83D683" />
            <path d="M95.4376 22.1787C91.5921 17.5301 94.221 10.4618 100.17 9.45587L149.805 1.06208C155.754 0.0561364 160.561 5.86695 158.458 11.5216L140.909 58.7044C138.806 64.359 131.37 65.6164 127.525 60.9678L95.4376 22.1787Z" fill="#D1C4F4" />
            <path d="M38.0764 221.535C28.8388 221.535 23.0653 211.535 27.6841 203.535L36.8504 187.659C41.4692 179.659 53.0162 179.659 57.635 187.659L66.8014 203.535C71.4202 211.535 65.6467 221.535 56.4091 221.535L38.0764 221.535Z" fill="#FF796E" />
            <defs>
                <clipPath id="clip0_576_6428">
                    <rect x="82.9648" y="48" width="133.916" height="123.929" rx="6.53096" transform="rotate(30 82.9648 48)" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
