import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Context } from '../utils/context';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { Valuation } from '../model/Valuation';
import { useVerticalScrollWithShadow } from '../hooks/VerticalScrollWithShadow';
import { Operation, defaultOperation } from '../model/Operation';
import { sortElements } from '../utils/sort-elements';
import { useNavigate } from 'react-router-dom';
import { addMonthsToTimestamp, getFormattedLocaleDate, ifDateIsValidThen } from '../utils/date-utils';
import { v4 as uuid } from 'uuid';
import OperationsService from '../utils/OperationsService';
import { getInteger, getDecimal } from '../utils/number-utils';
import Loading from './Loading';
import { useAPI } from '../hooks/UseAPI';
import Select from './Select';
import { FinancialElement } from '../model/FinancialElement';

function isOperation(operationOrValuation: Operation | Valuation): operationOrValuation is Operation {
    return 'operation_detail_id' in operationOrValuation;
}

function isValuation(operationOrValuation: Operation | Valuation): operationOrValuation is Valuation {
    return !('operation_detail_id' in operationOrValuation);
}

interface OperationsAndValuationsProps {
    financialElement: FinancialElement;
    valuations: Valuation[];
    setValuations: React.Dispatch<React.SetStateAction<Valuation[]>>;
}
export default function OperationsAndValuations(props: OperationsAndValuationsProps): JSX.Element {
    const { client, operationDetails, financialElements, setConfirmDialog, addFeedback } = useContext(Context);
    const { updateValuation, listOperations, createOperation, updateOperation } = useAPI();
    const [operations, setOperations] = useState<Operation[]>([]);
    const [loading, setLoading] = useState(true);
    const [timestampFrom, setTimestampFrom] = useState<string>('');
    const [timestampTo, setTimestampTo] = useState<string>('');
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [possibleCurrencyCodes, setPossibleCurrencyCodes] = useState<string[]>([]);
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();

    useEffect(() => {
        try {
            setLoading(true);
            const valuationsCurrencyCodesSet = new Set<string>();
            props.valuations.forEach(valuation => valuationsCurrencyCodesSet.add(valuation.currency_code ?? ''));
            client.currencies?.forEach(currency => valuationsCurrencyCodesSet.add(currency.code ?? ''));
            const currencyCodesInValuationsAndClients = Array.from(valuationsCurrencyCodesSet);
            setPossibleCurrencyCodes(currencyCodesInValuationsAndClients);
            setCurrencyCode(currencyCodesInValuationsAndClients[0]);
            const today = new Date();
            const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
            setTimestampTo(UTCtoday);
            setTimestampFrom(addMonthsToTimestamp(UTCtoday, -3));
        } finally {
            setLoading(false);
        }
    }, [client.currencies, props.valuations]);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || !timestampFrom || !timestampTo) return;

            OperationsService.listOperationsByRange(client.id, timestampFrom, timestampTo, listOperations).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, timestampFrom, timestampTo, listOperations]);

    const sortedOperationsAndValuations = sortElements([...operations, ...props.valuations].filter(operationOrValuation =>
        (operationOrValuation.timestamp ?? '') >= timestampFrom &&
        (operationOrValuation.timestamp ?? '') <= timestampTo &&
        (operationOrValuation.financial_element_id ?? '') === props.financialElement.id &&
        (operationOrValuation.currency_code ?? '') === currencyCode
    ), [
        { field: 'timestamp', asc: false },
        { field: 'hour', asc: false },
        { field: 'update_timestamp', asc: false },
    ]);

    const operationsAndValuationsTimestampMapEntries = useMemo(() => {
        const operationsAndValuationsTimestampMap = new Map<string, (Operation | Valuation)[]>();
        operationsAndValuationsTimestampMap.set(new Date(getFormattedLocaleDate(new Date())).getTime().toString(), []);
        sortedOperationsAndValuations.forEach(operationOrValuation =>
            operationsAndValuationsTimestampMap.set(operationOrValuation.timestamp ?? '', [
                ...(operationsAndValuationsTimestampMap.get(operationOrValuation.timestamp ?? '') ?? []),
                operationOrValuation
            ])
        );
        const operationsAndValuationsTimestampMapEntries = Array.from(operationsAndValuationsTimestampMap.entries());
        sortElements(operationsAndValuationsTimestampMapEntries, [
            { field: 0, asc: false },
        ]);
        operationsAndValuationsTimestampMapEntries.forEach(entry => {
            sortElements(entry[1], [
                { field: 'hour', asc: false },
                { field: 'update_timestamp', asc: false },
            ]);
        });

        return operationsAndValuationsTimestampMapEntries;
    }, [sortedOperationsAndValuations]);

    if (loading) return <Loading />

    function handleDateFromChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();
        setTimestampFrom(newTimestamp);
    }

    function handleDateToChange(event: SyntheticEvent) {
        const target = event.target as HTMLInputElement;
        const newTimestamp = new Date(target.value ?? '').getTime().toString();
        setTimestampTo(newTimestamp);
    }

    const dateFrom = timestampFrom === null ? '' : new Date(parseInt(timestampFrom)).toISOString().substring(0, 10);

    const dateTo = timestampTo === null ? '' : new Date(parseInt(timestampTo)).toISOString().substring(0, 10);

    function calculateBalance(timestamp: string): JSX.Element | undefined {
        const previousValuation: Valuation | undefined = sortedOperationsAndValuations
            .filter(isValuation)
            .find(comparedValuation => (comparedValuation.timestamp ?? '') <= timestamp);

        if (!previousValuation) return;

        const accumulatedBalance = sortedOperationsAndValuations
            .filter(isOperation)
            .filter(operation => (operation.timestamp ?? '') <= timestamp)
            .filter(operation => (operation.timestamp ?? '') > (previousValuation?.timestamp ?? '') || ((operation.timestamp ?? '') === (previousValuation?.timestamp ?? '') && (operation.hour ?? '') > (previousValuation?.hour ?? '')))
            .reduce((accumulator, operation) => accumulator + parseFloat(operation.amount ?? '0'), parseFloat(previousValuation?.amount ?? '0'))
            .toString();

        return (
            <div className={`flex ${accumulatedBalance.includes('-') ? 'expenditure' : 'income'}`}>
                <p className="text-xl font-mono">{accumulatedBalance.includes('-') ? '-' : '+'}</p>
                <p className="text-xl mx-1">{getSign(currencyCode)}</p>
                <p className='text-xl'>{getInteger(accumulatedBalance)}</p>
                <p className="text-[14px]/[20px] ml-[0.5px]">{getDecimal(accumulatedBalance)}</p>
            </div>
        );
    }

    function calculateErrorAndOmissions(valuation: Valuation): JSX.Element | undefined {
        const previousValuation: Valuation | undefined = sortedOperationsAndValuations.filter(isValuation)
            .find(comparedValuation => (comparedValuation.timestamp ?? '') < (valuation.timestamp ?? '') || ((comparedValuation.timestamp ?? '') === (valuation.timestamp ?? '') && (comparedValuation.hour ?? '') < (valuation.hour ?? '')));

        if (!previousValuation) {
            const previousAbsoluteValuation = props.valuations
                .find(comparedValuation => (comparedValuation.timestamp ?? '') < (valuation.timestamp ?? '') || ((comparedValuation.timestamp ?? '') === (valuation.timestamp ?? '') && (comparedValuation.hour ?? '') < (valuation.hour ?? '')));
            if (!previousAbsoluteValuation) {
                return <p className='flex justify-center items-center'>Primer registro de saldo</p>;
            }
            return;
        }

        const accumulatedBalance = sortedOperationsAndValuations
            .filter(isOperation)
            .filter(operation => (operation.timestamp ?? '') < (valuation.timestamp ?? '') || ((operation.timestamp ?? '') === (valuation.timestamp ?? '') && (operation.hour ?? '') < (valuation.hour ?? '')))
            .filter(operation => (operation.timestamp ?? '') > (previousValuation?.timestamp ?? '') || ((operation.timestamp ?? '') === (previousValuation?.timestamp ?? '') && (operation.hour ?? '') > (previousValuation?.hour ?? '')))
            .reduce((accumulator, operation) => accumulator + parseFloat(operation.amount ?? '0'), parseFloat(previousValuation?.amount ?? '0'));

        const errorsAndOmissions = (parseFloat(valuation.amount ?? '0') - accumulatedBalance).toString();

        if (parseFloat(errorsAndOmissions) === 0) return (
            <p className='flex justify-center items-center'>Errores y omisiones: 0</p>
        );

        async function addAdjustment() {
            await OperationsService.createOperation(client.id ?? '', {
                ...defaultOperation,
                financial_element_id: valuation.financial_element_id,
                grouping_id: uuid(),
                timestamp: valuation.timestamp,
                hour: (parseInt(valuation.hour ?? '') - 1).toString(),
                currency_code: valuation.currency_code,
                amount: errorsAndOmissions,
                description: 'Ajuste por errores y omisiones'
            }, createOperation);
            addFeedback({ message: '¡Operación creada con éxito!', level: 'success' });
        }

        const confirmDialogAddAdjustment = {
            callback: addAdjustment,
            title: '¿Agregar operación de ajuste para corregir Errores y omisiones?',
            subtitle: <div>
                <h3>Características de la nueva operación:</h3>
                <ul className='font-normal list-disc pl-4'>
                    <li>
                        <div className='flex'>
                            <p className="font-mono">{errorsAndOmissions?.includes('-') ? '-' : '+'}</p>
                            <p className="mx-1">{getSign(valuation.currency_code)}</p>
                            <p>{getInteger(errorsAndOmissions ?? '')}</p>
                            <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(errorsAndOmissions ?? '')}</p>
                        </div>
                    </li>
                    <li>{new Date(parseInt(valuation.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</li>
                    <li>{financialElements.find(financialElement => financialElement.id === valuation.financial_element_id)?.title}</li>
                    <li>Ajuste por errores y omisiones</li>
                </ul>
            </div>,
            confirmButton: 'Crear',
            cancelButton: 'Cancelar'
        };

        return (
            <div className='flex justify-center items-center space-x-1'>
                <p>Errores y omisiones: </p>
                <div className={`flex ${errorsAndOmissions?.includes('-') ? 'expenditure' : 'income'} `}>
                    <p className="font-normal font-mono">{errorsAndOmissions?.includes('-') ? '-' : '+'}</p>
                    <p className="font-normal mx-1">{getSign(valuation.currency_code)}</p>
                    <p className='font-normal'>{getInteger(errorsAndOmissions ?? '')}</p>
                    <p className="font-normal text-[11px]/[16px] ml-[1px]">{getDecimal(errorsAndOmissions ?? '')}</p>
                </div>
                <div className='w-5 h-5 p-0.5 text-sf-violet-dark rounded-lg hover:bg-sf-violet-medium cursor-pointer' onClick={() => setConfirmDialog(confirmDialogAddAdjustment)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="m20 7l-.95-2.05L17 4l2.05-.95L20 1l.95 2.05L23 4l-2.05.95L20 7ZM8.5 7l-.95-2.05L5.5 4l2.05-.95L8.5 1l.95 2.05L11.5 4l-2.05.95L8.5 7ZM20 18.5l-.95-2.05L17 15.5l2.05-.95l.95-2.05l.95 2.05l2.05.95l-2.05.95L20 18.5ZM5.1 21.7l-2.8-2.8q-.3-.3-.3-.725t.3-.725L13.45 6.3q.3-.3.725-.3t.725.3l2.8 2.8q.3.3.3.725t-.3.725L6.55 21.7q-.3.3-.725.3t-.725-.3Zm.75-2.1L13 12.4L11.6 11l-7.2 7.15l1.45 1.45Z" /></svg>
                </div>
            </div>
        );
    }

    function updateValuationOrOperation(operationOrValuation: Valuation | Operation) {
        if ((operationOrValuation as Operation).operation_detail_id !== undefined) {
            const operation = operationOrValuation as Operation;
            OperationsService.updateOperation(client.id ?? '', operation.id ?? '', operation, updateOperation);
        } else {
            const newValuation = operationOrValuation as Valuation;
            updateValuation(client.id ?? '', newValuation.id ?? '', newValuation).then(() => {
                props.setValuations(oldValuations => [newValuation, ...oldValuations.filter(oldValuation => oldValuation.id !== newValuation.id)]);
            });
        }
    }

    function swap(operationsAndValuations: (Operation | Valuation)[], i: number, j: number) {
        if (i === j || i < 0 || i >= operationsAndValuations.length || j < 0 || j >= operationsAndValuations.length) return;
        updateValuationOrOperation({ ...operationsAndValuations[i], hour: operationsAndValuations[j].hour });
        updateValuationOrOperation({ ...operationsAndValuations[j], hour: operationsAndValuations[i].hour });
    }

    function goToOperation(operation: Operation) {
        if (operation.grouping_id === '') {
            navigate(`/operations/${operation.id}`);
        } else {
            navigate(`/transactions/${operation.grouping_id}`);
        }
    }

    return (
        <article className='p-0 space-y-0 h-fit'>
            <h2 className='p-3'> Análisis de cuenta</h2>
            <div className="mx-3 pb-2">
                <div className="w-full flex border-t border-sf-black space-x-3 py-2">
                    <div className="w-1/2">
                        <p className="pb-1">Desde</p>
                        <input onKeyDown={e => e.preventDefault()} name='timestamp_from' type='date' max={dateTo} value={dateFrom} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateFromChange)} />
                    </div>
                    <div className="w-1/2">
                        <p className="pb-1">Hasta</p>
                        <input onKeyDown={e => e.preventDefault()} name='timestamp_to' type='date' min={dateFrom} value={dateTo} className='field ring-1 ring-sf-violet-dark' onChange={ifDateIsValidThen(handleDateToChange)} />
                    </div>
                </div>
                <div className='w-full h-fit ring-1 ring-sf-violet-dark rounded-lg'>
                    <Select placeholder='Moneda' name='currency_code' value={currencyCode} onChange={e => setCurrencyCode((e.target as HTMLInputElement).value)} options={possibleCurrencyCodes.map(currencyCode => ({ value: currencyCode, alias: getSign(currencyCode) }))} />
                </div>
            </div>
            <div onScroll={onScrollHandler} ref={ref} style={{ maxHeight: '36rem' }} className={`px-3 rounded-b-2xl space-y-3 overflow-auto ${boxShadow}`}>
                {operationsAndValuationsTimestampMapEntries.filter(entry => timestampFrom <= entry[0] && entry[0] <= timestampTo).map(entry =>
                    <div key={entry[0]} className='py-1'>
                        <div className='flex justify-between items-center'>
                            <h3 className='text-lg'>{new Date(parseInt(entry[0])).toLocaleDateString('es', { timeZone: 'UTC' })}</h3>
                            {calculateBalance(entry[0])}
                        </div>
                        <ul className="divide-y divide-sf-black border-t border-sf-black">
                            {entry[1].map((operationOrValuation, index) => {
                                if (isOperation(operationOrValuation)) {
                                    const operation = operationOrValuation;
                                    return <div key={index} className="py-2 flex">
                                        <div className="flex flex-col justify-center items-center ml-1 mr-4">
                                            <button className={`bg-sf-gray-extra-light rounded-t-lg pb-1 ${index === 0 ? 'text-sf-gray-medium cursor-default' : 'cursor-pointer hover:bg-sf-gray-light'}`} onClick={() => swap(entry[1], index, index - 1)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6" viewBox="0 0 24 24"><path fill="currentColor" d="m12 10.8l-3.9 3.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.6-4.6q.3-.3.7-.3t.7.3l4.6 4.6q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 10.8Z" /></svg>
                                            </button>
                                            <button className={`bg-sf-gray-extra-light rounded-b-lg pt-1 ${index === (entry[1].length - 1) ? 'text-sf-gray-medium cursor-default' : 'cursor-pointer hover:bg-sf-gray-light'}`} onClick={() => swap(entry[1], index, index + 1)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z" /></svg>
                                            </button>
                                        </div>
                                        <div className='flex justify-between items-center w-full cursor-pointer' onClick={() => goToOperation(operation)}>
                                            {operation.operation_detail_id === '' ?
                                                <p>{operation.description}</p>
                                                :
                                                <p className='font-bold'>{operationDetails.find(operationDetail => operationDetail.id === operation.operation_detail_id)?.title}</p>
                                            }
                                            <div className={`flex ${operation.amount?.includes('-') ? 'expenditure' : 'income'}`}>
                                                <p className="font-mono">{operation.amount?.includes('-') ? '-' : '+'}</p>
                                                <p className="mx-1">{getSign(operation.currency_code)}</p>
                                                <p>{getInteger(operation.amount ?? '')}</p>
                                                <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(operation.amount ?? '')}</p>
                                            </div>
                                        </div>
                                    </div>
                                } else {
                                    const valuation = operationOrValuation;
                                    return <div key={index} className='py-2 flex'>
                                        <div className="flex flex-col justify-center items-center ml-1 mr-4">
                                            <button className={`bg-sf-gray-extra-light rounded-t-lg pb-1 ${index === 0 ? 'text-sf-gray-medium cursor-default' : 'cursor-pointer hover:bg-sf-gray-light'}`} onClick={() => swap(entry[1], index, index - 1)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6" viewBox="0 0 24 24"><path fill="currentColor" d="m12 10.8l-3.9 3.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.6-4.6q.3-.3.7-.3t.7.3l4.6 4.6q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275L12 10.8Z" /></svg>
                                            </button>
                                            <button className={`bg-sf-gray-extra-light rounded-b-lg pt-1 ${index === (entry[1].length - 1) ? 'text-sf-gray-medium cursor-default' : 'cursor-pointer hover:bg-sf-gray-light'}`} onClick={() => swap(entry[1], index, index + 1)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6" viewBox="0 0 24 24"><path fill="currentColor" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z" /></svg>
                                            </button>
                                        </div>
                                        <div key={valuation.id} className="flex flex-col justify-center items-center w-full py-2 bg-sf-violet-light rounded-lg">
                                            <div className='flex w-full justify-center items-center space-x-1'>
                                                <p className='font-bold'>Registro de saldo:</p>
                                                <div className={`flex ${valuation.amount?.includes('-') ? 'expenditure' : 'income'} `}>
                                                    <p className="font-mono">{valuation.amount?.includes('-') ? '-' : '+'}</p>
                                                    <p className="mx-1">{getSign(valuation.currency_code)}</p>
                                                    <p>{getInteger(valuation.amount ?? '')}</p>
                                                    <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(valuation.amount ?? '')}</p>
                                                </div>
                                            </div>
                                            {calculateErrorAndOmissions(valuation)}
                                        </div>
                                    </div>
                                }
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </article>
    );
}
