import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Close from '@mui/icons-material/Close';
import SafeAreaInsetBottom from '../../components/initial-profile/SafeAreaInsetBottom';
import SafeAreaInsetTop from '../../components/initial-profile/SafeAreaInsetTop';
import DesktopHeader from '../../components/initial-profile/DesktopHeader';

interface FormLayoutProps {
    goBack: () => void,
    submit: () => void,
    title: string,
    isButtonDisabled: boolean,
    buttonText: string,
    visible: boolean,
    children?: React.ReactNode,
}

export default function FormLayout(props: FormLayoutProps) {
    const header = (
        <Box sx={{
            position: 'sticky',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <DesktopHeader />
            <SafeAreaInsetTop minValue='16px' />
            <Box sx={{
                gap: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'space-between', md: 'center' },
                px: 2,
                pb: { xs: 2, md: 4 },
                pt: { xs: 0, md: 6 },
                width: '100%',
                maxWidth: '532px',
            }}>
                <IconButton size='md' onClick={props.goBack} sx={{
                    display: { xs: 'flex', md: 'none' },
                    p: 0,
                    justifyContent: 'start',
                    minWidth: '40px',
                    minHeight: '40px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}>
                    <Close sx={{
                        color: 'text.primary',
                    }} />
                </IconButton>
                <Typography level='title-sm' fontWeight='lg'>
                    {props.title}
                </Typography>
                <IconButton sx={{
                    display: { xs: 'flex', md: 'none' },
                    cursor: 'default',
                    minWidth: '40px',
                    minHeight: '40px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }} />
            </Box>
        </Box>
    );

    const body = (
        <Box sx={{
            overflowY: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
        }}>
            <Box sx={{
                p: 2,
                height: '100%',
                width: '100%',
                maxWidth: '532px',
            }}>
                {props.children}
            </Box>
        </Box>
    );

    const footer = (
        <Box sx={{
            position: 'sticky',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bottom: 0,
        }}>
            <Box sx={{
                pt: 2,
                px: 2,
                gap: 3,
                width: '100%',
                maxWidth: '532px',
                display: 'flex',
            }}>
                <Button size='lg' variant='outlined' color='neutral' fullWidth onClick={props.goBack} sx={{
                    display: { xs: 'none', md: 'block' },
                }}>
                    Atrás
                </Button>
                <Button size='lg' fullWidth disabled={props.isButtonDisabled} onClick={props.submit}>
                    {props.buttonText}
                </Button>
            </Box>
            <SafeAreaInsetBottom minValue='16px' handleKeyboard={true} />
        </Box>
    );

    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            zIndex: 3,
            height: '100vh',
            width: '100vw',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            left: props.visible ? '0' : '100%',
            transition: { xs: 'left 0.2s ease', md: 'none' },
        }}>
            {header}
            {body}
            {footer}
        </Box>
    );
}
