import React, { SyntheticEvent, useContext, useRef, useState } from "react";
import Loading from "./Loading";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import { Context } from "../utils/context";
import { defaultOperationRow, OperationRow } from "../model/OperationRow";
import { useOperationRowErrors } from "../hooks/UseOperationRowErrors";
import { v4 as uuid } from 'uuid';
import { defaultOperation, Operation } from "../model/Operation";
import { OperationTypes } from "../consts/OperationTypes";
import { getHourInMilis, parseDMY } from "../utils/date-utils";
import ConfirmOperationRow from "./ConfirmOperationRow";
import { readSpreadsheet, writeSpreadsheet } from "../utils/spreadsheets-utils";
import OperationsService from "../utils/OperationsService";
import { useAPI } from "../hooks/UseAPI";

const RECOMMENDED_TYPES = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export default function OperationsFileInput() {
    const { client, financialElements, operationDetails, addFeedback, setConfirmDialog } = useContext(Context);
    const { createOperationBatch } = useAPI();
    const inputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState('');
    const [operationRowsGroups, setOperationRowsGroups] = useState<OperationRow[][]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { ref, boxShadow, onScrollHandler } = useVerticalScrollWithShadow();
    const { getErrors } = useOperationRowErrors();

    const handleChange = async (event: SyntheticEvent) => {
        let fileError = '';
        try {
            setLoading(true);
            const target = event.target as HTMLInputElement;
            if (target.files === null || target.files.length === 0) return;
            const selectedFile = target.files[0];
            if (!RECOMMENDED_TYPES.includes(selectedFile.type)) {
                fileError = 'Tipo de archivo no permitido';
                return;
            }
            if (selectedFile.size > 1048576) {
                fileError = 'Tamaño de archivo no permitido';
                return;
            }
            const EMPTY_VALUES = ['', '-'];
            const sheetJson = await readSpreadsheet(selectedFile);
            const operationRows = sheetJson.map((row: any, index: number) => {
                Object.keys(defaultOperationRow).forEach(key => {
                    row[key] = (row[key] ?? '').toString().trim();
                });
                return {
                    ...row,
                    'index': index,
                    'Categoría de movimiento': EMPTY_VALUES.includes(row['Categoría de movimiento']) ? '' : row['Categoría de movimiento'],
                    'Descripción': EMPTY_VALUES.includes(row['Descripción']) ? '' : row['Descripción'],
                    'Referencia de transacción': EMPTY_VALUES.includes(row['Referencia de transacción']) ? '' : row['Referencia de transacción']
                } as OperationRow;
            });
            const groupKey = 'Referencia de transacción';
            const operationRowsGroups: OperationRow[][] = [];
            const operationRowsMap = new Map<string, OperationRow[]>();
            operationRows.forEach(operationRow => {
                if (operationRow[groupKey] === '') {
                    operationRowsGroups.push([operationRow]);
                } else {
                    operationRowsMap.set(operationRow[groupKey], operationRowsMap.get(operationRow[groupKey])?.concat(operationRow) ?? [operationRow]);
                }
            });
            operationRowsGroups.push(...Array.from(operationRowsMap.values()));
            if (operationRowsGroups.length === 0) {
                fileError = 'No se encontraron operaciones en el archivo';
                return;
            }
            setFileName(selectedFile.name);
            setOperationRowsGroups(operationRowsGroups);
        } catch (error) {
            fileError = 'Error desconocido al leer el archivo';
        } finally {
            if (fileError !== '') {
                addFeedback({ message: fileError, level: 'error' });
            }
            setLoading(false);
        }
    };

    const downloadTemplate = () => {
        try {
            setLoading(true);
            const operationsData = [
                ['Fecha', 'Tipo', 'Moneda', 'Monto', 'Categoría de movimiento', 'Cuenta', 'Descripción', 'Referencia de transacción']
            ];
            const availableTypes = [OperationTypes.INCOME, OperationTypes.EXPENDITURE]
                .map(type => ['', type.label]);
            const availableCurrencies = client.currencies?.map(currency => ['', currency.code ?? '']) ?? [];
            const availableFinancialElements = financialElements
                .filter(financialElement => financialElement.status === 'ENABLED')
                .map(financialElement => ['', financialElement.title ?? '']);
            const availableExpenditureOperationDetails = operationDetails
                .filter(operationDetail => operationDetail.type === OperationTypes.EXPENDITURE.key && operationDetail.status === 'ENABLED')
                .map(operationDetail => ['', operationDetail.title ?? '']);
            const availableIncomeOperationDetails = operationDetails
                .filter(operationDetail => operationDetail.type === OperationTypes.INCOME.key && operationDetail.status === 'ENABLED')
                .map(operationDetail => ['', operationDetail.title ?? '']);
            const dataFormats = [
                'Para los campos "Tipo" "Moneda", "Cuenta" y "Categoría de movimiento", usa solo los valores indicados abajo',
                'El valor de "Fecha" debe estar en formato "dd/mm/aaaa"',
                'El valor de "Monto" debe estar en formato "1234.56"',
                'La "Descripción" es opcional, y pueden ser cualquier texto',
                'Si hay un valor en "Descripción", el campo "Categoría de movimiento" es opcional',
                'Usa "Referencia de transacción" para agrupar operaciones individuales (asigna el mismo valor de "Referencia de transacción" a todas las operaciones que quieras agrupar)',
                'La "Referencia de transacción" es opcional',
                'El valor de "Referencia de transacción" pueden ser cualquier número o texto. Se recomienda utilizar números (1, 2, 3, etc.)',
            ].map(phrase => ['', phrase]);
            const clientProfileData = [
                [], [], ['FORMATO DE LOS DATOS'], ...dataFormats, [], [],
                [], [], ['TIPOS DE OPERACIONES'], ...availableTypes, [], [],
                [], [], ['MONEDAS'], ...availableCurrencies, [], [],
                [], [], ['CUENTAS'], ...availableFinancialElements, [], [],
                [], [], ['CATEGORÍAS DE MOVIMIENTO DE EGRESO'], ...availableExpenditureOperationDetails, [], [],
                [], [], ['CATEGORÍAS DE MOVIMIENTO DE INGRESO'], ...availableIncomeOperationDetails, [], [],
            ];
            writeSpreadsheet([
                { data: operationsData, name: 'Operaciones' },
                { data: clientProfileData, name: 'Datos del cliente' }
            ], 'Operaciones.xlsx');
        } catch (error) {
            addFeedback({ message: 'Error desconocido', level: 'error' });
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <Loading />

    if (operationRowsGroups.length > 0) {
        const errorsFound = operationRowsGroups.flat().map(getErrors).flat().length > 0;

        const clearSelectedFile = () => {
            setFileName('');
            setOperationRowsGroups([]);
        }

        const handleConfirm = async (): Promise<void> => {
            setLoading(true);
            const operationsToCreate: Operation[] = operationRowsGroups
                .filter(operationsGroup => operationsGroup.length > 0)
                .map(operationRowsGroup => {
                    const groupingId = operationRowsGroup.length === 1 ? '' : uuid();

                    return operationRowsGroup.map(operationRow => {
                        const operationType = [OperationTypes.INCOME, OperationTypes.EXPENDITURE].find(operationType => operationType.label.toLowerCase() === operationRow['Tipo'].toLowerCase());
                        const financialElement = financialElements.find(financialElement => financialElement.title?.toLowerCase() === operationRow['Cuenta'].toLowerCase());
                        const operationDetail = operationDetails.find(operationDetail => operationDetail.title?.toLowerCase() === operationRow['Categoría de movimiento'].toLowerCase() && operationDetail.type === operationType?.key);
                        const currency = client.currencies?.find(currency => currency.code?.toLowerCase() === operationRow['Moneda'].toLowerCase() || currency.sign?.toLowerCase() === operationRow['Moneda'].toLowerCase());

                        return {
                            ...defaultOperation,
                            financial_element_id: financialElement?.id ?? '',
                            operation_detail_id: operationDetail?.id ?? '',
                            grouping_id: groupingId,
                            timestamp: parseDMY(operationRow['Fecha']),
                            hour: getHourInMilis(new Date(new Date().getTime() + operationRow['index'])).toString(),
                            currency_code: currency?.code ?? '',
                            amount: operationType === OperationTypes.EXPENDITURE ? `-${operationRow['Monto']}` : operationRow['Monto'],
                            description: operationRow['Descripción'].trim()
                        };
                    });
                })
                .flat();

            await OperationsService.createOperationBatch(client.id ?? '', operationsToCreate, createOperationBatch);
            addFeedback({ message: '¡Operaciones cargadas exitosamente!', level: 'success' });
            clearSelectedFile();
            setLoading(false);
        }

        const confirmOperationsDialogParams = {
            callback: handleConfirm,
            title: '¿Estás seguro que deseas confirmar estas operaciones?',
            confirmButton: 'Confirmar',
            cancelButton: 'Cancelar'
        };

        return (
            <div>
                <article className="p-0 space-y-0 h-fit">
                    <div className="p-3">
                        <div className="flex w-full flex-row justify-between items-center">
                            <h2>Cargar operaciones</h2>
                            {errorsFound ?
                                <div className="py-1 px-3 w-fit bg-sf-red-dark rounded-lg flex justify-center items-center">
                                    <p className="font-bold text-sf-white">Errores encontrados</p>
                                </div>
                                :
                                <div className="py-1 px-3 w-fit bg-sf-green-dark rounded-lg flex justify-center items-center">
                                    <p className="font-bold text-sf-white">No se encontraron errores</p>
                                </div>
                            }
                        </div>
                        <h3 className="whitespace-nowrap py-1.5">{`\u2022 ${fileName}`}</h3>
                    </div>
                    <div onScroll={onScrollHandler} ref={ref} className={`px-4 max-h-96 rounded-b-2xl overflow-auto ${boxShadow}`}>
                        <ul className="divide-y divide-sf-black border-t border-sf-black">
                            {operationRowsGroups.map((operationRowsGroup: OperationRow[], index1: number) =>
                                <div className="py-2 space-y-2" key={index1} >
                                    {operationRowsGroup.map((operationRow: OperationRow, index2: number) =>
                                        <ConfirmOperationRow key={index2} operationRow={operationRow} />
                                    )}
                                </div>
                            )}
                        </ul>
                    </div>
                </article>
                <div className="flex justify-between items-center w-full p-3">
                    <button className="button-secondary" onClick={clearSelectedFile}>
                        Cancelar
                    </button>
                    <button className="button-primary" disabled={errorsFound} onClick={() => setConfirmDialog(confirmOperationsDialogParams)}>
                        Confirmar
                    </button>
                </div>
            </div >
        );
    }

    return (
        <article className="h-fit">
            <div className="flex w-full justify-between items-center">
                <h2>Cargar operaciones</h2>
                <button className="button-aux flex space-x-1 items-center" onClick={downloadTemplate}>
                    <p>Template</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4" viewBox="0 0 24 24"><path fill="currentColor" d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11l-5 5Zm-6 4q-.825 0-1.413-.588T4 18v-3h2v3h12v-3h2v3q0 .825-.588 1.413T18 20H6Z" /></svg>
                </button>
            </div>
            <div className="pt-12 pb-9 w-full flex justify-center border-t border-sf-black">
                <div onClick={() => { inputRef.current?.click() }}
                    className="p-3 w-fit h-fit flex flex-col self-center items-center gap-2 bg-sf-violet-dark text-sf-white rounded-lg cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24"><g fill="currentColor"><path d="M6.5 18v-.09c0-.865 0-1.659.087-2.304c.095-.711.32-1.463.938-2.08c.618-.619 1.37-.844 2.08-.94c.646-.086 1.44-.086 2.306-.086h.178c.866 0 1.66 0 2.305.087c.711.095 1.463.32 2.08.938c.619.618.844 1.37.94 2.08c.085.637.086 1.416.086 2.267c2.573-.55 4.5-2.812 4.5-5.52c0-2.47-1.607-3.572-3.845-5.337C17.837 4.194 15.415 2 12.476 2C9.32 2 6.762 4.528 6.762 7.647c0 .69.125 1.35.354 1.962a4.356 4.356 0 0 0-.83-.08C3.919 9.53 2 11.426 2 13.765C2 16.104 3.919 18 6.286 18H6.5Z" opacity=".5" /><path fillRule="evenodd" d="M12 14c-1.886 0-2.828 0-3.414.586C8 15.172 8 16.114 8 18c0 1.886 0 2.828.586 3.414C9.172 22 10.114 22 12 22c1.886 0 2.828 0 3.414-.586C16 20.828 16 19.886 16 18c0-1.886 0-2.828-.586-3.414C14.828 14 13.886 14 12 14Zm1.805 3.084l-1.334-1.333a.667.667 0 0 0-.942 0l-1.334 1.333a.667.667 0 1 0 .943.943l.195-.195v1.946a.667.667 0 0 0 1.334 0v-1.946l.195.195a.667.667 0 0 0 .943-.943Z" clipRule="evenodd" /></g></svg>
                    <h3 className="text-base">Sube un archivo .xlsx, .csv o .xls</h3>
                    <p>Máximo 1MB</p>
                    <input type="file" ref={inputRef} className="hidden" onChange={handleChange} />
                </div>
            </div>
        </article>
    );
};
