import { SyntheticEvent, useContext, useEffect, useState } from "react"
import { Budget, Item, defaultBudget, defaultItem } from "../model/Budget";
import { useAPI } from "../hooks/UseAPI";
import { Context } from "../utils/context";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import Select from "../components/Select";
import EmptyList from "../components/EmptyList";
import { processDecimalInput } from "../utils/process-decimal-input";
import { OperationTypes } from "../consts/OperationTypes";
import { addDaysToTimestamp, addMonthsToTimestamp, ifDateIsValidThen } from "../utils/date-utils";
import EditBudgetItem from "../components/EditBudgetItem";
import { areItemsEqual } from "../utils/budget-utils";

export default function NewBudget(): JSX.Element {
    const { client, setConfirmDialog, operationDetails } = useContext(Context);
    const { createBudget, listBudgets } = useAPI();
    const [loading, setLoading] = useState(true);
    const [isFirstBudget, setIsFirstBudget] = useState(false);
    const [isDangerousTimestampTo, setIsDangerousTimestampTo] = useState(false);
    const [newBudget, setNewBudget] = useState<Budget>({ ...defaultBudget });
    const [newItem, setNewItem] = useState<Item>({ ...defaultItem });
    const { getSign } = useCurrencyByCode();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [newItemType, setNewItemType] = useState<OperationTypes>(OperationTypes.EXPENDITURE);

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                const today = new Date();
                let timestamp_from = Date.UTC(today.getFullYear(), today.getMonth()).toString();
                let items: Item[] = [];
                let budgetCurrency = client.currencies ? client.currencies[0]?.code ?? '' : '';
                if (result.length > 0) {
                    const lastBudget = result[result.length - 1];
                    items = lastBudget.items ?? items;
                    timestamp_from = lastBudget.timestamp_to ?? timestamp_from;
                    budgetCurrency = lastBudget.currency ?? budgetCurrency;
                }
                const timestamp_to = addDaysToTimestamp(addMonthsToTimestamp(timestamp_from, 1), -1);

                setIsFirstBudget(result.length === 0);
                setNewBudget({
                    ...defaultBudget,
                    items: items,
                    currency: budgetCurrency,
                    timestamp_from: new Date(parseInt(timestamp_from)).toISOString().substring(0, 10),
                    timestamp_to: new Date(parseInt(timestamp_to)).toISOString().substring(0, 10),
                });

                setNewItem({ ...defaultItem });
                setNewItemType(OperationTypes.EXPENDITURE);
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [client.currencies, client.id, listBudgets]);

    useEffect(() => {
        if (!newBudget.timestamp_to) {
            return;
        }

        const timestampToDisplayed = new Date(newBudget.timestamp_to).getTime().toString();
        const timestampToReal = new Date(parseInt(addDaysToTimestamp(timestampToDisplayed, 1)));
        const day = timestampToReal.toISOString().substring(8, 10);

        setIsDangerousTimestampTo(day > '28');
    }, [newBudget.timestamp_to]);

    if (loading) return <Loading />

    function handleBudgetChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setNewBudget(oldBudget => ({
            ...oldBudget,
            [target.name]: target.value
        }));
    }

    function handleItemChange(event: SyntheticEvent): void {
        const target = event.target as HTMLInputElement;
        setNewItem(oldItem => ({
            ...oldItem,
            [target.name]: target.value
        }));
    }

    function addItem(): void {
        setNewBudget(oldBudget => ({
            ...oldBudget,
            items: [...oldBudget.items ?? [], {
                ...newItem,
                amount: newItemType === OperationTypes.EXPENDITURE ? `-${newItem.amount}` : newItem.amount
            }]
        }));
        setNewItem({ ...defaultItem });
    }

    function removeFromBudget(item: Item): void {
        setNewBudget(oldBudget => ({
            ...oldBudget,
            items: (oldBudget.items ?? []).filter(oldItem => !(oldItem.operation_detail_id === item.operation_detail_id))
        }));
    }

    function saveItem(item: Item): void {
        setNewBudget(oldBudget => ({
            ...oldBudget,
            items: (oldBudget.items ?? []).map(oldItem => (oldItem.operation_detail_id === item.operation_detail_id) ? item : oldItem)
        }));
    }

    const returnToBudgets = () => navigate(`/budgets?${searchParams}`);

    function saveBudget() {
        setLoading(true);

        const timestampFrom = new Date(newBudget.timestamp_from ?? '').getTime().toString();
        const timestampTo = addDaysToTimestamp(new Date(newBudget.timestamp_to ?? '').getTime().toString(), 1);

        setSearchParams(oldSearchParams => {
            oldSearchParams.set('budget_timestamp', timestampTo);
            return oldSearchParams;
        });

        createBudget(client.id ?? '', {
            ...newBudget,
            timestamp_from: timestampFrom,
            timestamp_to: timestampTo,
        }).then(returnToBudgets);
    }

    async function confirmCancelBudget(): Promise<void> {
        returnToBudgets();
    }

    function swap(item1: Item, item2: Item): void {
        setNewBudget(oldBudget => {
            const index1 = oldBudget.items?.findIndex(oldItem => areItemsEqual(oldItem, item1));
            const index2 = oldBudget.items?.findIndex(oldItem => areItemsEqual(oldItem, item2));

            if (index1 === undefined || index2 === undefined) return oldBudget;

            const rearrangedItems = [...oldBudget.items ?? []];
            rearrangedItems[index1] = (oldBudget.items ?? [])[index2];
            rearrangedItems[index2] = (oldBudget.items ?? [])[index1];

            return { ...oldBudget, items: rearrangedItems };
        });
    }

    const expenditureItems = newBudget?.items?.filter(item =>
        !!item.amount?.includes('-') &&
        (item.operation_detail_id ?? '') !== ''
    ) ?? [];
    const incomeItems = newBudget?.items?.filter(item =>
        !item.amount?.includes('-') &&
        (item.operation_detail_id ?? '') !== ''
    ) ?? [];

    const usedExpenditureIds = expenditureItems.map(item => item.operation_detail_id) ?? [];
    const usedIncomeIds = incomeItems.map(item => item.operation_detail_id) ?? [];

    const availableExpenditureOperationDetails = operationDetails?.filter(operationDetail => operationDetail.status === 'ENABLED' && !usedExpenditureIds.includes(operationDetail.id) && operationDetail.type === OperationTypes.EXPENDITURE.key);
    const availableIncomeOperationDetails = operationDetails?.filter(operationDetail => operationDetail.status === 'ENABLED' && !usedIncomeIds.includes(operationDetail.id) && operationDetail.type === OperationTypes.INCOME.key);

    const availableOperationDetails = newItemType === OperationTypes.EXPENDITURE ? availableExpenditureOperationDetails : availableIncomeOperationDetails;

    const confirmDialogParams = {
        callback: confirmCancelBudget,
        title: '¿Estás seguro que deseas salir?',
        confirmButton: 'Salir',
        cancelButton: 'Seguir editando'
    };

    return (
        <main>
            <article>
                <div className="flex justify-between">
                    <h2>Nuevo presupuesto</h2>
                </div>
                {isDangerousTimestampTo && <p className="w-full flex justify-center bg-sf-red-light text-sf-red-dark p-3 rounded-lg font-bold"> No es recomendable usar esta Fecha de finalización.</p>}
                <div className="flex min-w-full justify-between items-center pb-2 space-x-4">
                    <div className="w-full">
                        <p className={`pb-1 ${!isFirstBudget && 'text-sf-gray-dark'}`}>Fecha de inicio:</p>
                        <input name='timestamp_from' type='date' disabled={!isFirstBudget} max={newBudget.timestamp_to} value={newBudget.timestamp_from} onChange={ifDateIsValidThen(handleBudgetChange)} className={`field ring-1 ${!isFirstBudget ? 'ring-sf-gray-dark text-sf-gray-dark' : 'ring-sf-violet-dark'}`} />
                    </div>
                    <div className="w-full">
                        <p className='pb-1'>Fecha de finalización:</p>
                        <input name='timestamp_to' type='date' min={newBudget.timestamp_from} value={newBudget.timestamp_to} onChange={ifDateIsValidThen(handleBudgetChange)} className='field ring-1 ring-sf-violet-dark' />
                    </div>
                </div>
                {isFirstBudget && <article className="bg-sf-violet-light">
                    <h2>Moneda del tablero</h2>
                    <Select placeholder='Moneda' name='currency' value={newBudget.currency} onChange={handleBudgetChange} options={client.currencies?.map(currency => ({ value: currency.code, alias: getSign(currency.code) }))} />
                </article>}
                <article className="bg-sf-violet-light">
                    <div className="flex justify-between items-center">
                        <h2>Nuevo concepto</h2>
                        <div className='flex'>
                            {[OperationTypes.EXPENDITURE, OperationTypes.INCOME].map(type => (
                                <div key={type.key} className='flex items-center space-x-1.5 cursor-pointer rounded-lg p-1 px-3' onClick={() => { setNewItem({ ...defaultItem }); setNewItemType(type); }}>
                                    <p className={`rounded-full h-4 w-4 ${type === newItemType ? 'bg-sf-violet-dark' : 'ring-1 ring-inset ring-sf-violet-dark'}`}></p>
                                    <p>{type.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {availableOperationDetails?.length === 0 ?
                        <small className="text-sf-violet-dark p-2 rounded-md border border-sf-violet-dark">No hay categorías de {newItemType.label} disponibles para presupuestar.</small>
                        :
                        <div className="space-y-3 flex flex-col">
                            <Select placeholder='Selecciona una categoría de movimiento *' name='operation_detail_id' value={newItem.operation_detail_id} onChange={handleItemChange} options={availableOperationDetails.map(availableOperationDetail => ({ value: availableOperationDetail.id, alias: availableOperationDetail.title }))} />
                            <div className='flex'>
                                <div className={`field px-6 mr-3 bg-sf-white text-center w-fit ${newBudget.currency === '' && 'ring-2 ring-inset ring-sf-red-dark'}`}>
                                    {getSign(newBudget.currency)}
                                </div>
                                <input type='text' inputMode='decimal' name='amount' placeholder='Ingrese el monto *' value={newItem.amount} onChange={e => processDecimalInput(e) && handleItemChange(e)} className='field' />
                            </div>
                            <button disabled={!newItem.operation_detail_id || !newBudget.currency || !newItem.amount} className="self-center button-primary" onClick={addItem}>
                                Agregar
                            </button>
                        </div>
                    }
                </article>
                {incomeItems.length > 0 && <div>
                    <h2 className='py-2'>Ingresos:</h2>
                    <div className="divide-y divide-sf-black border-y border-sf-black">
                        {incomeItems.map((item, index) =>
                            <EditBudgetItem
                                key={index}
                                item={item}
                                currency={newBudget.currency ?? ''}
                                removeFromBudget={() => removeFromBudget(item)}
                                saveItem={saveItem}
                                moveUp={() => index > 0 ? swap(item, incomeItems[index - 1]) : null}
                                moveDown={() => index < (incomeItems.length - 1) ? swap(item, incomeItems[index + 1]) : null}
                            />
                        )}
                    </div>
                </div>}
                {expenditureItems.length > 0 && <div>
                    <h2 className='py-2'>Egresos:</h2>
                    <div className="divide-y divide-sf-black border-y border-sf-black">
                        {expenditureItems.map((item, index) =>
                            <EditBudgetItem
                                key={index}
                                item={item}
                                currency={newBudget.currency ?? ''}
                                removeFromBudget={() => removeFromBudget(item)}
                                saveItem={saveItem}
                                moveUp={() => index > 0 ? swap(item, expenditureItems[index - 1]) : null}
                                moveDown={() => index < (expenditureItems.length - 1) ? swap(item, expenditureItems[index + 1]) : null}
                            />
                        )}
                    </div>
                </div>}
                {newBudget?.items?.length === 0 && <EmptyList message="Aún no hay conceptos cargados." />}
                <div className='self-end flex w-full justify-between'>
                    <button className="button-secondary-red px-4" onClick={() => setConfirmDialog(confirmDialogParams)}>Cancelar</button>
                    <button className="button-primary" disabled={newBudget.currency === ''} onClick={saveBudget}>Guardar</button>
                </div>
            </article>
        </main>
    )
}
