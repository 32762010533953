import { useContext, useEffect, useState } from 'react';
import { useAPI } from '../hooks/UseAPI';
import { Context } from '../utils/context';
import { Budget, defaultItem, Item } from '../model/Budget';
import { Operation } from '../model/Operation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { useCurrencyByCode } from '../hooks/CurrencyFromCode';
import { ExchangeRate, defaultExchangeRate } from '../model/ExchangeRate';
import SelectBlack from '../components/SelectBlack';
import { OperationTypes } from '../consts/OperationTypes';
import { OperationDetail } from '../model/OperationDetail';
import { FinancialElement } from '../model/FinancialElement';
import { FinancialElementTypes } from '../consts/FinancialElementTypes';
import { addDaysToTimestamp } from '../utils/date-utils';
import { getInteger, getDecimal } from '../utils/number-utils';
import { WidthWarning } from '../components/WidthWarning';
import PlanSection from '../components/PlanComponents/PlanSection';
import { areItemsEqual } from '../utils/budget-utils';
import Subtotal from '../components/PlanComponents/Subtotal';
import OperationsService from '../utils/OperationsService';

export default function Plan(): JSX.Element {
    const { client, operationDetails, financialElements } = useContext(Context);
    const { listBudgets, listExchangeRates, updateBudget, listOperations } = useAPI();
    const [loading, setLoading] = useState(true);
    const [loadingExchangeRates, setLoadingExchangeRates] = useState(true);
    const [budgets, setBudgets] = useState<Budget[]>([]);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
    const [selectedBudgetIndex, setSelectedBudgetIndex] = useState<number>(0);
    const [budgetsRetrieved, setBudgetsRetrieved] = useState<boolean>(false);
    const [operations, setOperations] = useState<Operation[]>([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getSign } = useCurrencyByCode();

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                setBudgets(result);
                const budgetTimestamp = searchParams.get('budget_timestamp') ?? (new Date()).getTime().toString();
                let currentBudgetIndex = result.findIndex(budget => (budget.timestamp_from ?? '') < budgetTimestamp && budgetTimestamp <= (budget.timestamp_to ?? ''));
                if (currentBudgetIndex < 0) {
                    currentBudgetIndex = result.length - 1;
                }
                setSelectedBudgetIndex(currentBudgetIndex);
            } finally {
                setLoading(false);
                setBudgetsRetrieved(true);
            }
        }
        setUp();
    }, [client.id, searchParams, listBudgets]);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || budgets.length === 0) return;

            const budget = budgets[selectedBudgetIndex];

            if (!budget.timestamp_from || !budget.timestamp_to) return;

            const from = addDaysToTimestamp(budget.timestamp_from, -7);
            const to = addDaysToTimestamp(budget.timestamp_to, 7);

            OperationsService.listOperationsByRange(client.id, from, to, listOperations).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, budgets, budgetsRetrieved, selectedBudgetIndex, listOperations]);

    useEffect(() => {
        if (budgets.length === 0) {
            if (budgetsRetrieved) {
                setLoadingExchangeRates(false);
            }
            return;
        }

        const budget = budgets[selectedBudgetIndex];

        async function getExchangeRates() {
            try {
                setLoadingExchangeRates(true);
                const usedCurrencies = operations.map(operationOrValuation => operationOrValuation.currency_code ?? '');
                usedCurrencies.push(budget.currency ?? '');
                const codes = Array.from(new Set(usedCurrencies));
                if (codes.length === 0) return;
                const exchangeRates = await listExchangeRates({
                    codes,
                    from: budget.timestamp_from,
                    to: budget.timestamp_to,
                });
                setExchangeRates(oldExchangeRates => [...oldExchangeRates, ...exchangeRates]);
            } finally {
                setLoadingExchangeRates(false);
            }
        }

        getExchangeRates();
    }, [budgets, budgetsRetrieved, selectedBudgetIndex, operations, listExchangeRates]);

    if (loading) return <Loading />

    if (budgets.length === 0) return (
        <main>
            <article>
                <div className="items-center text-center min-w-full text-sf-gray-dark p-4">No hay ningún plan para mostrar</div>
                <div className='min-w-full flex justify-center'>
                    <button className='button-primary' onClick={() => navigate('/plan/new')}>Crear plan</button>
                </div>
            </article>
        </main>
    );

    const missingExchangeRates: ExchangeRate[] = [];

    function getExchangeRate(code: string, timestamp: string): number {
        let selectedExchangeRate = { ...defaultExchangeRate };
        exchangeRates?.filter(exchangeRate => (exchangeRate.code === code) && ((exchangeRate.timestamp ?? '') <= timestamp))
            .forEach(exchangeRate => {
                if ((exchangeRate.timestamp ?? '') > (selectedExchangeRate.timestamp ?? '')) {
                    selectedExchangeRate = exchangeRate;
                }
            });

        let exchangeRate = selectedExchangeRate.rate;
        if (exchangeRate === undefined || exchangeRate === '') {
            exchangeRate = '1';
            if (code !== 'USD') {
                missingExchangeRates.push({ code, timestamp });
            }
        }

        return parseFloat(exchangeRate);
    }

    const budget = budgets[selectedBudgetIndex];

    function addItem(budget: Budget, item: Item): void {
        setBudgets(oldBudgets => {
            const oldBudget = oldBudgets.find(oldBudget => oldBudget.timestamp_to === budget.timestamp_to) ?? {};
            const newItems = [...oldBudget.items ?? [], item];
            const newBudget = { ...oldBudget, items: newItems };
            updateBudget(client.id ?? '', newBudget.timestamp_to ?? '', newBudget);

            return oldBudgets.map(oldBudget => oldBudget.timestamp_to === budget.timestamp_to ? newBudget : oldBudget);
        });
    }

    function removeItem(budget: Budget, item: Item): void {
        setBudgets(oldBudgets => {
            const oldBudget = oldBudgets.find(oldBudget => oldBudget.timestamp_to === budget.timestamp_to) ?? {};
            const newItems = (oldBudget.items ?? []).filter(oldItem => !areItemsEqual(item, oldItem));
            const newBudget = { ...oldBudget, items: newItems };
            updateBudget(client.id ?? '', newBudget.timestamp_to ?? '', newBudget);

            return oldBudgets.map(oldBudget => oldBudget.timestamp_to === budget.timestamp_to ? newBudget : oldBudget);
        });
    }

    function updateItem(budget: Budget, item: Item, newAmount: string) {
        setBudgets(oldBudgets => {
            const oldBudget = oldBudgets.find(oldBudget => oldBudget.timestamp_to === budget.timestamp_to) ?? {};
            const newItems = (oldBudget.items ?? []).map(oldItem => areItemsEqual(item, oldItem) ? { ...defaultItem, ...item, amount: newAmount } : { ...defaultItem, ...oldItem });
            const newBudget = { ...oldBudget, items: newItems };
            updateBudget(client.id ?? '', newBudget.timestamp_to ?? '', newBudget);

            return oldBudgets.map(oldBudget => oldBudget.timestamp_to === budget.timestamp_to ? newBudget : oldBudget);
        });
    }

    function swap(budget: Budget, item1: Item, item2: Item): void {
        setBudgets(oldBudgets => {
            const oldBudget = oldBudgets.find(oldBudget => oldBudget.timestamp_to === budget.timestamp_to) ?? {};
            const index1 = oldBudget.items?.findIndex(oldItem => areItemsEqual(oldItem, item1));
            const index2 = oldBudget.items?.findIndex(oldItem => areItemsEqual(oldItem, item2));

            if (index1 === undefined || index2 === undefined) return oldBudgets;

            const newItems = [...oldBudget.items ?? []];
            newItems[index1] = (oldBudget.items ?? [])[index2];
            newItems[index2] = (oldBudget.items ?? [])[index1];
            const newBudget = { ...oldBudget, items: newItems };
            updateBudget(client.id ?? '', newBudget.timestamp_to ?? '', newBudget);

            return oldBudgets.map(oldBudget => oldBudget.timestamp_to === budget.timestamp_to ? newBudget : oldBudget);
        });
    }

    function newSearchParams(): URLSearchParams {
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.set('budget_timestamp', budget?.timestamp_to ?? '');

        return newSearchParams;
    }

    const getOperationDetail = (operation?: Operation) => operationDetails.find(operationDetail => operationDetail.id === operation?.operation_detail_id);

    const getFinancialElement = (operation?: Operation) => financialElements.find(financialElement => financialElement.id === operation?.financial_element_id);

    const financialElementIsNonTransactionalAndOfType = (finacialElementType: FinancialElementTypes, financialElement?: FinancialElement) =>
        financialElement?.type === finacialElementType.key &&
        !financialElement?.transactional;

    const filteredOperations = operations?.filter(operation => {
        if (budget?.timestamp_from === undefined || budget?.timestamp_to === undefined || operation.timestamp === undefined) return 0;

        return operation.timestamp >= budget.timestamp_from && operation.timestamp < budget.timestamp_to && getFinancialElement(operation)?.transactional;
    }).map(operation => {
        const parsedAmount = parseFloat(operation.amount ?? '');
        const budgetExchangeRate = getExchangeRate(budget.currency ?? '', operation.timestamp ?? '');
        const operationExchangeRate = getExchangeRate(operation.currency_code ?? '', operation.timestamp ?? '');

        return {
            ...operation,
            currency: budget.currency,
            amount: (parsedAmount * budgetExchangeRate / operationExchangeRate).toString()
        };
    });

    const movementsOperations = filteredOperations.filter(operation => operation.operation_detail_id !== '');

    const nonMovementsOperations = filteredOperations.filter(operation => operation.operation_detail_id === '')
        .map(operation => ({
            ...operation,
            financial_element_id: operations.find(o =>
                o.grouping_id === operation.grouping_id &&
                o.financial_element_id !== operation.financial_element_id &&
                getFinancialElement(o)?.transactional === false
            )?.financial_element_id
        }));

    const allOperations = [...movementsOperations, ...nonMovementsOperations];

    const incomeOperationsFilterPredicate = (operation: Operation) =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== '';
    const incomeAvailableElementsFilterPredicate = (operationDetail: OperationDetail) =>
        operationDetail.type === OperationTypes.INCOME.key;

    const spendingOperationsFilterPredicate = (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== '' &&
        (getOperationDetail(operation)?.goal_id ?? '') === '';
    const spendingAvailableElementsFilterPredicate = (operationDetail: OperationDetail) =>
        (operationDetail.goal_id ?? '') === '' &&
        operationDetail.type === OperationTypes.EXPENDITURE.key;

    const goalOperationsFilterPredicate = (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== '' &&
        (getOperationDetail(operation)?.goal_id ?? '') !== '';
    const goalAvailableElementsFilterPredicate = (operationDetail: OperationDetail) =>
        (operationDetail.goal_id ?? '') !== '';

    const borrowingOperationsFilterPredicate = (operation: Operation) =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.DEBT, getFinancialElement(operation));
    const borrowingAvailableElementsFilterPredicate = (financialElement: FinancialElement) =>
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.DEBT, financialElement) &&
        !financialElements.find(fe => fe.deferred === financialElement.id);

    const debtPaymentOperationsFilterPredicate = (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.DEBT, getFinancialElement(operation));
    const debtPaymentAvailableElementsFilterPredicate = (financialElement: FinancialElement) =>
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.DEBT, financialElement) &&
        !financialElements.find(fe => fe.deferred === financialElement.id);

    const profitOperationsFilterPredicate = (operation: Operation) =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.ASSET, getFinancialElement(operation));
    const profitAvailableElementsFilterPredicate = (financialElement: FinancialElement) =>
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.ASSET, financialElement) &&
        !financialElements.find(fe => fe.deferred === financialElement.id);

    const investmentOperationsFilterPredicate = (operation: Operation) =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') === '' &&
        (operation.financial_element_id ?? '') !== '' &&
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.ASSET, getFinancialElement(operation));
    const investmentAvailableElementsFilterPredicate = (financialElement: FinancialElement) =>
        financialElementIsNonTransactionalAndOfType(FinancialElementTypes.ASSET, financialElement) &&
        !financialElements.find(fe => fe.deferred === financialElement.id);

    const savingCapacityCurrentAmount = allOperations.filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation)
    );

    const savingCapacityPlannedAmount = (budget.items ?? []).filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation)
    );

    const availableToGoalsCurrentAmount = allOperations.filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation) ||
        borrowingOperationsFilterPredicate(operation) ||
        debtPaymentOperationsFilterPredicate(operation)
    );

    const availableToGoalsPlannedAmount = (budget.items ?? []).filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation) ||
        borrowingOperationsFilterPredicate(operation) ||
        debtPaymentOperationsFilterPredicate(operation)
    );

    const availableToAllocateCurrentAmount = allOperations.filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation) ||
        borrowingOperationsFilterPredicate(operation) ||
        debtPaymentOperationsFilterPredicate(operation) ||
        goalOperationsFilterPredicate(operation)
    );

    const availableToAllocatePlannedAmount = (budget.items ?? []).filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation) ||
        borrowingOperationsFilterPredicate(operation) ||
        debtPaymentOperationsFilterPredicate(operation) ||
        goalOperationsFilterPredicate(operation)
    );

    const generalResultCurrentAmount = allOperations.filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation) ||
        borrowingOperationsFilterPredicate(operation) ||
        debtPaymentOperationsFilterPredicate(operation) ||
        goalOperationsFilterPredicate(operation) ||
        profitOperationsFilterPredicate(operation) ||
        investmentOperationsFilterPredicate(operation)
    );

    const generalResultPlannedAmount = (budget.items ?? []).filter(operation =>
        incomeOperationsFilterPredicate(operation) ||
        spendingOperationsFilterPredicate(operation) ||
        borrowingOperationsFilterPredicate(operation) ||
        debtPaymentOperationsFilterPredicate(operation) ||
        goalOperationsFilterPredicate(operation) ||
        profitOperationsFilterPredicate(operation) ||
        investmentOperationsFilterPredicate(operation)
    );

    const usedOperationsIds = generalResultCurrentAmount.map(operation => operation.id);
    const otherOperationsTotalCurrentAmount = allOperations.filter(operation => !usedOperationsIds.includes(operation.id))
        .map(operation => parseFloat(operation.amount ?? '')).reduce((p, c) => p + c, 0).toString();

    const currency = getSign(budget.currency ?? '') ?? '';

    return (
        <main className='grid-cols-1 min-w-[703px]'>
            <div className='w-full'>
                <div className='flex w-full justify-between items-center'>
                    <h1 className='text-2xl'>Plan mensual</h1>
                    <button className="button-aux w-fit h-fit p-1" onClick={() => navigate(`/yearplans`)}>
                        <p>Ver plan anual</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-2.5 w-2.5" viewBox="0 0 24 24"><path fill="currentColor" d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887q.375-.375.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75q0 .375-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1q-.375-.375-.375-.888t.375-.887z" /></svg>
                    </button>
                </div>
                <WidthWarning minWidth={672} />
            </div>
            <article>
                <div className='flex justify-between items-center w-full'>
                    <div className='w-60 ring-1 ring-sf-black rounded-lg'>
                        <SelectBlack placeholder='Periodo' value={selectedBudgetIndex.toString()} onChange={e => setSelectedBudgetIndex(parseInt((e.target as HTMLInputElement).value))} options={budgets.map((budget, index) => ({ value: index.toString(), alias: `${new Date(parseInt(budget?.timestamp_from ?? '')).toLocaleDateString('en-GB', { timeZone: 'UTC' })} - ${new Date(parseInt(addDaysToTimestamp(budget?.timestamp_to ?? '', -1))).toLocaleDateString('en-GB', { timeZone: 'UTC' })}` }))} />
                    </div>
                    {missingExchangeRates.length > 0 && !loadingExchangeRates &&
                        <div className='bg-sf-yellow-light rounded-lg p-0.5 ring-1 ring-inset ring-sf-red-medium mr-2'>
                            <svg xmlns="http://www.w3.org/20q00/svg" className='w-5 text-sf-red-medium' viewBox="0 0 256 256"><g fill="currentColor"><path d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91Z" opacity=".2" /><path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72Zm-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72ZM120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z" /></g></svg>
                        </div>
                    }
                    <div className='flex justify-between items-center w-fit space-x-2'>
                        {(selectedBudgetIndex === 0 || selectedBudgetIndex === budgets.length - 1) &&
                            <div className='cursor-pointer hover:bg-sf-gray-extra-light rounded-lg' onClick={() => navigate(`/plan/${budget.timestamp_to}?${newSearchParams()}`)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className='w-7 pr-1' viewBox="0 0 24 24"><path fill="currentColor" d="M5 19h1.4l8.625-8.625l-1.4-1.4L5 17.6V19ZM19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM4 21q-.425 0-.713-.288T3 20v-2.825q0-.2.075-.388t.225-.337l10.3-10.3l4.25 4.25l-10.3 10.3q-.15.15-.337.225T6.825 21H4ZM14.325 9.675l-.7-.7l1.4 1.4l-.7-.7Z" /></svg>
                            </div>
                        }
                        <div className='cursor-pointer hover:bg-sf-gray-extra-light rounded-lg' onClick={() => navigate(`/plan/new?${newSearchParams()}`)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-7' viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                        </div>
                    </div>
                </div>
            </article>
            <article>
                <h2>Economía cotidiana</h2>
                <PlanSection
                    budget={budget}
                    operations={movementsOperations}
                    title='Movimientos de ingreso'
                    isIncome={true}
                    isMovement={true}
                    addItem={addItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    swap={swap}
                    operationsFilterPredicate={incomeOperationsFilterPredicate}
                    availableElementsFilterPredicate={incomeAvailableElementsFilterPredicate}
                />
                <PlanSection
                    budget={budget}
                    operations={movementsOperations}
                    title='Movimientos de egreso'
                    isIncome={false}
                    isMovement={true}
                    addItem={addItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    swap={swap}
                    operationsFilterPredicate={spendingOperationsFilterPredicate}
                    availableElementsFilterPredicate={spendingAvailableElementsFilterPredicate}
                />
            </article>
            <article className='bg-sf-violet-light'>
                <Subtotal
                    title='Capacidad de ahorro estructural'
                    currency={currency}
                    operationsAmounts={savingCapacityCurrentAmount.map(o => o.amount ?? '')}
                    itemsAmounts={savingCapacityPlannedAmount.map(o => o.amount ?? '')}
                />
            </article>
            <article>
                <h2>Deudas</h2>
                <PlanSection
                    budget={budget}
                    operations={nonMovementsOperations}
                    title='Entradas de dinero por asumir deudas'
                    isIncome={true}
                    isMovement={false}
                    addItem={addItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    swap={swap}
                    operationsFilterPredicate={borrowingOperationsFilterPredicate}
                    availableElementsFilterPredicate={borrowingAvailableElementsFilterPredicate}
                />
                <PlanSection
                    budget={budget}
                    operations={nonMovementsOperations}
                    title='Salidas de dinero para pagar deudas'
                    isIncome={false}
                    isMovement={false}
                    addItem={addItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    swap={swap}
                    operationsFilterPredicate={debtPaymentOperationsFilterPredicate}
                    availableElementsFilterPredicate={debtPaymentAvailableElementsFilterPredicate}
                />
            </article>
            <article className='bg-sf-violet-light'>
                <Subtotal
                    title='Disponible para objetivos'
                    currency={currency}
                    operationsAmounts={availableToGoalsCurrentAmount.map(o => o.amount ?? '')}
                    itemsAmounts={availableToGoalsPlannedAmount.map(o => o.amount ?? '')}
                />
            </article>
            <article>
                <h2>Cumpliendo objetivos</h2>
                <PlanSection
                    budget={budget}
                    operations={movementsOperations}
                    title='Movimientos de objetivos'
                    isIncome={false}
                    isMovement={true}
                    addItem={addItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    swap={swap}
                    operationsFilterPredicate={goalOperationsFilterPredicate}
                    availableElementsFilterPredicate={goalAvailableElementsFilterPredicate}
                />
            </article>
            <article className='bg-sf-violet-light'>
                <Subtotal
                    title='Disponible para asignar'
                    currency={currency}
                    operationsAmounts={availableToAllocateCurrentAmount.map(o => o.amount ?? '')}
                    itemsAmounts={availableToAllocatePlannedAmount.map(o => o.amount ?? '')}
                />
            </article>
            <article>
                <h2>Inversiones</h2>
                <PlanSection
                    budget={budget}
                    operations={nonMovementsOperations}
                    title='Entradas de dinero desde inversiones'
                    isIncome={true}
                    isMovement={false}
                    addItem={addItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    swap={swap}
                    operationsFilterPredicate={profitOperationsFilterPredicate}
                    availableElementsFilterPredicate={profitAvailableElementsFilterPredicate}
                />
                <PlanSection
                    budget={budget}
                    operations={nonMovementsOperations}
                    title='Salidas de dinero para inversiones'
                    isIncome={false}
                    isMovement={false}
                    addItem={addItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    swap={swap}
                    operationsFilterPredicate={investmentOperationsFilterPredicate}
                    availableElementsFilterPredicate={investmentAvailableElementsFilterPredicate}
                />
            </article>
            <article className='bg-sf-violet-light'>
                <Subtotal
                    title='Resultado general'
                    currency={currency}
                    operationsAmounts={generalResultCurrentAmount.map(o => o.amount ?? '')}
                    itemsAmounts={generalResultPlannedAmount.map(o => o.amount ?? '')}
                />
                <div className='flex w-full items-center'>
                    <p className='pl-1.5 w-[40%] bg-sf-white py-2 rounded-l-lg'>Otras operaciones</p>
                    <div className='w-fit pr-1.5 flex bg-sf-white py-2 rounded-r-lg'>
                        <p>Actual:</p>
                        <p className="ml-1 font-mono">{otherOperationsTotalCurrentAmount.includes('-') ? '-' : '+'}</p>
                        <p className='mx-1'>{currency}</p>
                        <p>{getInteger(otherOperationsTotalCurrentAmount)}</p>
                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(otherOperationsTotalCurrentAmount)}</p>
                    </div>
                </div>
                <Subtotal
                    title='Resultado general (definitivo)'
                    currency={currency}
                    operationsAmounts={allOperations.map(o => o.amount ?? '')}
                    itemsAmounts={generalResultPlannedAmount.map(o => o.amount ?? '')}
                />
            </article>
            {missingExchangeRates.length > 0 && !loadingExchangeRates &&
                <article className='h-fit'>
                    <h2 className='text-sf-red-dark font-bold'>Advertencia</h2>
                    <p>Algunos movimietos no pudieron convertise. Faltan los siguientes tipos de cambio:</p>
                    {missingExchangeRates.map((exchangeRate, index) =>
                        <div className="flex" key={index}>
                            <p>USD a</p><p className="mx-1 font-bold">{exchangeRate.code}</p>
                            <p>(</p><p>{new Date(parseInt(exchangeRate.timestamp ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })}</p> <p>)</p>
                        </div>
                    )}
                </article>
            }
        </main>
    );
}
