import PlaneTakeoff from '../components/initial-profile/icons/PlaneTakeoff';
import Goal from '../components/initial-profile/icons/Goal';
import BriefcaseMedical from '../components/initial-profile/icons/BriefcaseMedical';
import CarFront from '../components/initial-profile/icons/CarFront';
import House from '../components/initial-profile/icons/House';
import GraduationCap from '../components/initial-profile/icons/GraduationCap';
import Heart from '../components/initial-profile/icons/Heart';
import ShoppingBag from '../components/initial-profile/icons/ShoppingBag';
import Cake from '../components/initial-profile/icons/Cake';
import Glasses from '../components/initial-profile/icons/Glasses';
import HandCoins from '../components/initial-profile/icons/HandCoins';
import HandHeart from '../components/initial-profile/icons/HandHeart';
import BriefcaseBusiness from '../components/initial-profile/icons/BriefcaseBusiness';
import ChartSpline from '../components/initial-profile/icons/ChartSpline';
import Banknote from '../components/initial-profile/icons/Banknote';
import CreditCard from '../components/initial-profile/icons/CreditCard';
import CircleDollarSign from '../components/initial-profile/icons/CircleDollarSign';
import Landmark from '../components/initial-profile/icons/Landmark';
import ShoppingCart from '../components/initial-profile/icons/ShoppingCart';
import Pizza from '../components/initial-profile/icons/Pizza';
import Coffee from '../components/initial-profile/icons/Coffee';
import ShoppingBasket from '../components/initial-profile/icons/ShoppingBasket';
import Cigarette from '../components/initial-profile/icons/Cigarette';
import PawPrint from '../components/initial-profile/icons/PawPrint';
import Car from '../components/initial-profile/icons/Car';
import Fuel from '../components/initial-profile/icons/Fuel';
import ShieldCheck from '../components/initial-profile/icons/ShieldCheck';
import CircleParking from '../components/initial-profile/icons/CircleParking';
import Bus from '../components/initial-profile/icons/Bus';
import CarTaxiFront from '../components/initial-profile/icons/CarTaxiFront';
import KeyRound from '../components/initial-profile/icons/KeyRound';
import PaintBrush from '../components/initial-profile/icons/PaintBrush';
import HousePlug from '../components/initial-profile/icons/HousePlug';
import Droplets from '../components/initial-profile/icons/Droplets';
import Flame from '../components/initial-profile/icons/Flame';
import LightBulb from '../components/initial-profile/icons/LightBulb';
import Wifi from '../components/initial-profile/icons/Wifi';
import PhoneCall from '../components/initial-profile/icons/PhoneCall';
import Ambulance from '../components/initial-profile/icons/Ambulance';
import Cross from '../components/initial-profile/icons/Cross';
import CirclePlay from '../components/initial-profile/icons/CirclePlay';
import Tv from '../components/initial-profile/icons/Tv';
import Shirt from '../components/initial-profile/icons/Shirt';
import PartyPopper from '../components/initial-profile/icons/PartyPopper';
import Plane from '../components/initial-profile/icons/Plane';
import Carrot from '../components/initial-profile/icons/Carrot';
import Gift from '../components/initial-profile/icons/Gift';
import TentTree from '../components/initial-profile/icons/TentTree';
import PiggyBank from '../components/initial-profile/icons/PiggyBank';
import Haze from '../components/initial-profile/icons/Haze';
import BicepsFlexed from '../components/initial-profile/icons/BicepsFlexed';
import Puzzle from '../components/initial-profile/icons/Puzzle';
import Dribble from '../components/initial-profile/icons/Dribble';
import Laugh from '../components/initial-profile/icons/Laugh';
import Rainbow from '../components/initial-profile/icons/Rainbow';
import { clearString } from './string-utils';

interface IconMappingEntry {
    icon: React.ReactNode,
    keywords: string[],
}

interface IconMapping {
    mappings: IconMappingEntry[],
    defaultIcon: React.ReactNode,
}

export function getIcon(title: string | undefined, iconMapping: IconMapping): React.ReactNode {
    if (title === undefined) {
        return iconMapping.defaultIcon;
    }

    const titleNormalized = ` ${clearString(title)} `;
    for (const { icon, keywords } of iconMapping.mappings) {
        if (keywords.some(keyword => titleNormalized.includes(` ${keyword} `))) {
            return icon;
        }
    }

    return iconMapping.defaultIcon;
}

export const goalsIconMapping: IconMapping = {
    mappings: [
        { icon: <PlaneTakeoff />, keywords: ['vacaciones', 'viaje', 'viajar', 'viajes'] },
        { icon: <Goal />, keywords: ['retiro', 'retirar'] },
        { icon: <BriefcaseMedical />, keywords: ['emergencia', 'emergencias'] },
        { icon: <CarFront />, keywords: ['auto', 'carro'] },
        { icon: <House />, keywords: ['casa'] },
        { icon: <GraduationCap />, keywords: ['curso', 'cursos', 'educacion', 'capacitacion', 'capacitaciones', 'diplomatura', 'maestria', 'master', 'universidad'] },
        { icon: <Heart />, keywords: ['casamiento', 'casarme', 'casarnos', 'luna de miel'] },
        { icon: <ShoppingBag />, keywords: ['comprar', 'celular', 'celu', 'telefono', 'computadora', 'compu', 'ropa', 'guitarra'] },
        { icon: <Cake />, keywords: ['cumple', 'cumpleaños'] },
    ],
    defaultIcon: <Goal />,
};

export const incomeIconMapping: IconMapping = {
    mappings: [
        { icon: <ChartSpline />, keywords: ['inversion', 'inversiones', 'dividendos', 'intereses', 'ganancia', 'ganancias', 'beneficios'] },
        { icon: <Glasses />, keywords: ['consultorias', 'consultoria', 'consultorio'] },
        { icon: <HandCoins />, keywords: ['reintegros', 'reintegro', 'devoluciones', 'variable', 'variables', 'extra', 'extras'] },
        { icon: <HandHeart />, keywords: ['emprendimiento', 'emprendimientos', 'negocio'] },
    ],
    defaultIcon: <BriefcaseBusiness />,
};

export const expensesIconMapping: IconMapping = {
    mappings: [
        { icon: <ShoppingCart />, keywords: ['super', 'supermercado'] },
        { icon: <Pizza />, keywords: ['delivery', 'comida'] },
        { icon: <Coffee />, keywords: ['cafe', 'cafeteria'] },
        { icon: <ShoppingBasket />, keywords: ['dietetica', 'verduleria', 'carniceria', 'almacen'] },
        { icon: <Cigarette />, keywords: ['cigarrillos', 'cigarrillo'] },
        { icon: <PawPrint />, keywords: ['mascota', 'mascotas', 'perro', 'perros', 'gato', 'gatos'] },
        { icon: <Car />, keywords: ['auto', 'autos'] },
        { icon: <Fuel />, keywords: ['nafta', 'gasolina', 'combustible'] },
        { icon: <ShieldCheck />, keywords: ['seguro', 'seguros'] },
        { icon: <CircleParking />, keywords: ['estacionamiento', 'cochera'] },
        { icon: <Bus />, keywords: ['bus', 'colectivo', 'autobus', 'transporte'] },
        { icon: <CarTaxiFront />, keywords: ['taxi', 'cabify', 'uber', 'remis'] },
        { icon: <KeyRound />, keywords: ['alquiler'] },
        { icon: <House />, keywords: ['hogar', 'casa'] },
        { icon: <PaintBrush />, keywords: ['limpieza'] },
        { icon: <HousePlug />, keywords: ['servicios'] },
        { icon: <Droplets />, keywords: ['agua'] },
        { icon: <Flame />, keywords: ['gas'] },
        { icon: <LightBulb />, keywords: ['luz', 'electricidad', 'edenor', 'edesur'] },
        { icon: <Wifi />, keywords: ['internet'] },
        { icon: <PhoneCall />, keywords: ['telefono', 'celular', 'datos'] },
        { icon: <Ambulance />, keywords: ['social', 'prepaga', 'salud'] },
        { icon: <Cross />, keywords: ['farmacia', 'medicamento', 'medicamentos'] },
        { icon: <CirclePlay />, keywords: ['suscripciones', 'netflix', 'spotify', 'prime', 'hbo', 'disney'] },
        { icon: <Tv />, keywords: ['cable', 'tv', 'television', 'cablevision'] },
        { icon: <Shirt />, keywords: ['ropa'] },
        { icon: <PartyPopper />, keywords: ['salidas', 'fiesta', 'fiestas', 'festejo', 'festejos'] },
        { icon: <Plane />, keywords: ['viaje', 'viajes'] },
        { icon: <Carrot />, keywords: ['objetivo', 'objetivos'] },
        { icon: <Gift />, keywords: ['regalo', 'regalos', 'regalito', 'regalitos'] },
        { icon: <TentTree />, keywords: ['vacaciones'] },
        { icon: <GraduationCap />, keywords: ['curso', 'cursos', 'educacion', 'capacitacion', 'capacitaciones', 'diplomatura', 'maestria', 'master', 'universidad', 'ingles', 'idiomas', 'colegio', 'escuela', 'jardin', 'instituto', 'facu', 'facultad', 'uni'] },
        { icon: <PiggyBank />, keywords: ['somosfin'] },
        { icon: <Landmark />, keywords: ['bancarios', 'banco', 'afip', 'impuestos', 'hacienda', 'monotributo'] },
        { icon: <Haze />, keywords: ['bienestar'] },
        { icon: <BicepsFlexed />, keywords: ['gimnasio', 'gym'] },
        { icon: <Puzzle />, keywords: ['terapia', 'psicologo', 'psicologa'] },
        { icon: <Dribble />, keywords: ['deporte', 'futbol', 'basket', 'tenis', 'padel'] },
        { icon: <Laugh />, keywords: ['belleza', 'cuidado'] },
        { icon: <Rainbow />, keywords: ['yoga'] },
    ],
    defaultIcon: <CircleDollarSign />,
};

export const debtIconMapping: IconMapping = {
    mappings: [
        { icon: <Heart />, keywords: ['mama', 'papa', 'padre', 'madre', 'padres', 'familia', 'familiar'] },
        { icon: <CreditCard />, keywords: ['credito', 'credit', 'visa', 'amex', 'master', 'mastercard', 'american', 'american express'] },
        { icon: <House />, keywords: ['casa', 'hipoteca'] },
    ],
    defaultIcon: <Landmark />,
};

export const savingIconMapping: IconMapping = {
    mappings: [
        { icon: <Banknote />, keywords: ['efectivo', 'dinero', 'saldo', 'billete', 'billetes', 'dolares', 'colchon', 'ahorro', 'billetera', 'wallet'] },
        { icon: <ChartSpline />, keywords: ['inversion', 'inversiones', 'acciones', 'bonos', 'bono', 'plazo', 'fondo'] },
    ],
    defaultIcon: <BriefcaseBusiness />,
};
