import { useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { useCallback, useEffect, useMemo } from "react";
import { App as CapacitorApp } from '@capacitor/app';
import AppleLogoutBrowser from "../plugins/AppleLogoutBrowserPlugin";
import getRedirectUri from "../utils/redirect-uri-utils";
import AndroidBrowser from "../plugins/AndroidBrowserPlugin";

export function useHandleSignOut() {
    const { logout } = useAuth0();
    const platform = Capacitor.getPlatform();

    useEffect(() => {
        if (platform === 'ios') {
            CapacitorApp.addListener('appUrlOpen', async () => {
                await AppleLogoutBrowser.close();
                localStorage.clear();
                window.location.assign('/');
            });
        }
    }, [platform]);

    const openUrl = useMemo(() => {
        switch (platform) {
            case 'ios':
                return async (url: string) => {
                    await AppleLogoutBrowser.open({ url });
                };
            case 'android':
                return async (url: string) => {
                    await AndroidBrowser.open({ url });
                    localStorage.clear();
                    window.location.assign('/');
                };
            default:
                return undefined;
        }
    }, [platform]);

    const handleSignOut = useCallback(async () => {
        await logout({
            logoutParams: {
                returnTo: getRedirectUri()
            },
            openUrl
        });
    }, [logout, openUrl]);

    return { handleSignOut };
}
