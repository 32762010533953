import { useAuth0 } from "@auth0/auth0-react";
import { Capacitor } from "@capacitor/core";
import { useEffect, useMemo, useCallback, useState } from "react";
import { App as CapacitorApp } from '@capacitor/app';
import AppleLoginBrowser from "../plugins/AppleLoginBrowserPlugin";
import AndroidBrowser from "../plugins/AndroidBrowserPlugin";

const DELAY = 200;

export function useHandleSignIn() {
    const { loginWithRedirect, handleRedirectCallback } = useAuth0();
    const [loading, setLoading] = useState(false);
    const platform = Capacitor.getPlatform();

    useEffect(() => {
        if (platform === 'android') {
            CapacitorApp.addListener('appUrlOpen', async ({ url }) => {
                if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
                    setLoading(true);
                    await handleRedirectCallback(url);
                    setLoading(false);
                }
            });
        }
    }, [platform, handleRedirectCallback]);

    const openUrl = useMemo(() => {
        switch (platform) {
            case 'ios':
                return async (url: string) => {
                    await new Promise(res => setTimeout(res, DELAY));
                    const result = await AppleLoginBrowser.open({ url });
                    setLoading(true);
                    await handleRedirectCallback(result.callbackURL);
                    setLoading(false);
                };
            case 'android':
                return async (url: string) => {
                    await new Promise(res => setTimeout(res, DELAY));
                    await AndroidBrowser.open({ url });
                };
            default:
                return undefined;
        }
    }, [platform, handleRedirectCallback]);

    const handleSignIn = useCallback(async () => {
        await loginWithRedirect({ openUrl });
    }, [loginWithRedirect, openUrl]);

    return { handleSignIn, loading };
}
