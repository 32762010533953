import StepLayout from './StepLayout';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/joy/Button';
import { getIcon, goalsIconMapping } from '../../utils/icon-mapping-utils';
import PersonalGrowth from '../../components/initial-profile/brand-images/PersonalGrowth';
import Typography from '@mui/joy/Typography';
import FormLayout from './FormLayout';
import AddIcon from '@mui/icons-material/Add';
import TextInput from '../../components/initial-profile/TextInput';
import { defaultGoal, Goal } from '../../model/Goal';
import { Context } from '../../utils/context';
import { useAPI } from '../../hooks/UseAPI';
import { sortElements } from '../../utils/sort-elements';

export default function GoalsStep(): JSX.Element {
    const { client } = useContext(Context);
    const { listGoals, createGoalBatch, deleteGoalBatch } = useAPI();
    const [loading, setLoading] = useState(true);
    const [goals, setGoals] = useState<Goal[]>([]);
    const navigate = useNavigate();
    const [newGoalTitle, setNewGoalTitle] = useState('');
    const [newGoalFormOpen, setNewGoalFormOpen] = useState(false);
    const shouldScrollToEnd = useRef(false);
    const endMarkerRef = useRef<HTMLElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        async function setUp() {
            setLoading(true);
            try {
                const results = await listGoals(client.id ?? '');
                const now = new Date().getTime();
                const suggestions = ['Comprar auto', 'Comprar casa', 'Vacaciones'].map((title, i) => ({
                    ...defaultGoal,
                    title,
                    order_key: (now + i).toString()
                }));
                const currentGoals = results.length > 0 ? results : suggestions;
                sortElements(currentGoals, [
                    { field: 'order_key', asc: true },
                    { field: 'update_timestamp', asc: false },
                ]);
                setGoals(currentGoals);
            } finally {
                setLoading(false);
            }
        }
        setUp();
    }, [client.id, listGoals]);

    useEffect(() => {
        if (shouldScrollToEnd.current) {
            shouldScrollToEnd.current = false;
            endMarkerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [goals]);

    useEffect(() => {
        if (newGoalFormOpen) {
            inputRef.current?.click();
        }
    }, [newGoalFormOpen]);

    function switchActive(indexToSwitch: number) {
        setGoals(oldGoalItems =>
            oldGoalItems.map((oldGoalItem, index) => indexToSwitch === index ? { ...oldGoalItem, status: oldGoalItem.status === 'pending' ? '' : 'pending' } : oldGoalItem)
        );
    }

    const isTitleIncomplete = newGoalTitle.length < 3;

    function goBack() {
        setNewGoalTitle('');
        setNewGoalFormOpen(old => !old);
    }

    function submitForm() {
        const valueCopy = `${newGoalTitle}`;
        shouldScrollToEnd.current = true;
        setGoals(oldGoalItems => [
            ...oldGoalItems,
            {
                ...defaultGoal,
                title: valueCopy,
                status: 'pending',
                order_key: new Date().getTime().toString()
            }
        ]);
        setNewGoalTitle('');
        setNewGoalFormOpen(old => !old);
    }

    async function submitStep() {
        setLoading(true);
        try {
            const goalsToCreate = goals.filter(goal => goal.goal_id === '' && goal.status === 'pending');
            const creatingGoalsPromise = goalsToCreate.length > 0 ? createGoalBatch(client.id ?? '', goalsToCreate) : Promise.resolve();

            const goalsToDeleteIds = goals.filter(goal => goal.goal_id !== '' && goal.status !== 'pending').map(goalToDelete => goalToDelete.goal_id ?? '');
            const deletingGoalsPromise = goalsToDeleteIds.length > 0 ? deleteGoalBatch(client.id ?? '', goalsToDeleteIds) : Promise.resolve();

            await Promise.all([creatingGoalsPromise, deletingGoalsPromise]);
        } finally {
            navigate('/2');
            setLoading(false);
        }
    }

    return (<>
        <StepLayout
            endMarkerRef={endMarkerRef}
            currentStep={1}
            totalSteps={6}
            goToNext={submitStep}
            skip={() => navigate('/2')}
            title='Objetivos'
            description='Cuéntanos sobre tus objetivos para acompañarte a alcanzarlos.'
            isButtonDisabled={!goals.find(goalItem => goalItem.status === 'pending')}
            loading={loading}
            buttonText='Siguiente'
            image={<PersonalGrowth />}
        >
            <Box sx={{
                gap: '18px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                {goals.map((goalItem, index) =>
                    <Button
                        key={index}
                        onClick={() => switchActive(index)}
                        fullWidth
                        variant='outlined'
                        size='lg'
                        color='primary'
                        startDecorator={
                            <Box sx={{ width: '28px', strokeWidth: '2px' }}>
                                {getIcon(goalItem.title, goalsIconMapping)}
                            </Box>
                        }
                        sx={theme => ({
                            height: '48px',
                            gap: '6px',
                            justifyContent: 'start',
                            borderRadius: '24px',
                            px: goalItem.status === 'pending' ? '11px' : '12px',
                            borderWidth: goalItem.status === 'pending' ? '2px' : '1px',
                            background: goalItem.status === 'pending' ? theme.colorSchemes.light.palette.primary.outlinedActiveBg : 'transparent',
                        })}
                    >
                        {goalItem.title}
                    </Button>
                )}
                <Button
                    onClick={() => setNewGoalFormOpen(old => !old)}
                    variant='plain'
                    color='neutral'
                    startDecorator={
                        <AddIcon sx={{
                            width: '24px',
                            height: '24px',
                            color: 'currentColor'
                        }} />
                    }
                >
                    Nuevo objetivo
                </Button>
            </Box>
        </StepLayout>
        <FormLayout
            goBack={goBack}
            submit={submitForm}
            title='Nuevo objetivo'
            isButtonDisabled={isTitleIncomplete}
            visible={newGoalFormOpen}
            buttonText='Siguiente'
        >
            <Box sx={{
                gap: '6px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography level='title-sm'>
                    ¿Qué objetivo te gustaría alcanzar?
                </Typography>
                <TextInput
                    placeholder="Comprar una bicicleta"
                    value={newGoalTitle}
                    setValue={setNewGoalTitle}
                    submitCallback={() => !isTitleIncomplete && submitForm()}
                    inputRef={inputRef}
                />
            </Box>
        </FormLayout>
    </>);
}
