import StepLayout from './StepLayout';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import { useContext, useState } from 'react';
import { useAPI } from '../../hooks/UseAPI';
import { Context } from '../../utils/context';
import { STRESS_LEVELS } from '../../consts/stress-levels';

export default function FinancialStressStep(): JSX.Element {
    const { client, setClient } = useContext(Context);
    const { updateClient } = useAPI();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function submit() {
        setLoading(true);
        try {
            await updateClient(client.id ?? '', client);
        } finally {
            navigate('/summary');
            setLoading(false);
        }
    }

    return (
        <StepLayout
            currentStep={6}
            totalSteps={6}
            goBack={() => navigate('/5')}
            goToNext={submit}
            skip={() => navigate('/summary')}
            title='Stress financiero'
            description='¿Cuál de estas emociones describe cómo te sientes con tu situación financiera hoy?'
            isButtonDisabled={(client.stress_level ?? '') === ''}
            loading={loading}
            buttonText='Siguiente'
        >
            <Box sx={{
                gap: '18px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                {STRESS_LEVELS.map((stressLevel, index) =>
                    <Button
                        key={index}
                        onClick={() => setClient(oldClient => ({
                            ...oldClient,
                            stress_level: stressLevel.key,
                        }))}
                        fullWidth
                        variant='outlined'
                        size='lg'
                        color='primary'
                        sx={theme => ({
                            height: '48px',
                            gap: '6px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '24px',
                            px: client.stress_level === stressLevel.key ? '11px' : '12px',
                            borderWidth: client.stress_level === stressLevel.key ? '2px' : '1px',
                            background: client.stress_level === stressLevel.key ? theme.colorSchemes.light.palette.primary.outlinedActiveBg : 'transparent',
                        })}
                    >
                        <Box sx={{ width: '42px' }}>
                            {stressLevel.icon}
                        </Box>
                    </Button>
                )}
            </Box>
        </StepLayout>
    );
}
