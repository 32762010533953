import Box from "@mui/joy/Box";
import FormLayout from "./FormLayout";
import Typography from "@mui/joy/Typography";
import RadioGroup from "@mui/joy/RadioGroup";
import { ISO_4217_CURRENCY_CODES } from "../../consts/currency-codes";
import Divider from "@mui/joy/Divider";
import Radio from "@mui/joy/Radio";
import { useEffect, useState } from "react";

interface CurrencyCodeFormProps {
    goBack: () => void,
    visible: boolean,
    initialCurrencyCode: string | undefined,
    submit: (currencyCode: string | undefined) => void,
}

export default function CurrencyCodeForm(props: CurrencyCodeFormProps): JSX.Element {
    const [currencyCode, setCurrencyCode] = useState('');

    useEffect(() => {
        if (props.visible) {
            setCurrencyCode(props.initialCurrencyCode ?? '');
        }
    }, [props.visible, props.initialCurrencyCode]);

    return (
        <FormLayout
            goBack={props.goBack}
            submit={() => props.submit(currencyCode)}
            title='Selecciona tu moneda'
            isButtonDisabled={false}
            visible={props.visible}
            buttonText='Siguiente'
        >
            <Box sx={{
                py: 1,
                gap: 3,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography sx={{
                    fontFamily: 'ESRebondGrotesque Medium',
                    fontWeight: '700',
                    fontSize: '24px',
                    height: '29px',
                    lineHeight: '29px',
                }}>
                    Monedas más usadas
                </Typography>
                <RadioGroup value={currencyCode} onChange={event => setCurrencyCode(event.target.value)}>
                    {ISO_4217_CURRENCY_CODES.filter(currency => ['EUR', 'USD', 'GBP'].includes(currency.code)).map((currency, index) => <Radio sx={{
                        alignItems: 'center',
                    }} key={index} value={currency.code} label={
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography level='title-md' fontWeight='lg'>
                                {currency.code}
                            </Typography>
                            <Typography level='body-sm' sx={theme => ({
                                color: theme.vars.palette.text.primary
                            })}>
                                {currency.description}
                            </Typography>
                        </Box>
                    } />)}
                </RadioGroup>
                <Divider />
                <Typography sx={{
                    fontFamily: 'ESRebondGrotesque Medium',
                    fontWeight: '700',
                    fontSize: '24px',
                    height: '29px',
                    lineHeight: '29px',
                }}>
                    Todas las monedas
                </Typography>
                <RadioGroup value={currencyCode} onChange={event => setCurrencyCode(event.target.value)}>
                    {ISO_4217_CURRENCY_CODES.map((currency, index) => <Radio sx={{
                        alignItems: 'center',
                    }} key={index} value={currency.code} label={
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography level='title-md' fontWeight='lg'>
                                {currency.code}
                            </Typography>
                            <Typography level='body-sm' sx={theme => ({
                                color: theme.vars.palette.text.primary
                            })}>
                                {currency.description}
                            </Typography>
                        </Box>
                    } />)}
                </RadioGroup>
            </Box>
        </FormLayout>
    );
}
