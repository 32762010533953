import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import LoadingScreen from './pages/LoadingScreen';
import OperationsService from './utils/OperationsService';
import { addMonthsToTimestamp } from './utils/date-utils';
import { Context } from './utils/context';
import { useAPI } from './hooks/UseAPI';
import { useHandleSignOut } from './hooks/UseHandleSignOut';
import { sortElements } from './utils/sort-elements';
import DebtsStep from "./pages/initial-profile/DebtsStep";
import ExpensesStep from "./pages/initial-profile/ExpensesStep";
import FinancialStressStep from "./pages/initial-profile/FinancialStressStep";
import GoalsStep from "./pages/initial-profile/GoalsStep";
import IncomeStep from "./pages/initial-profile/IncomeStep";
import SavingsStep from "./pages/initial-profile/SavingsStep";
import Summary from "./pages/initial-profile/Summary";
import Welcome from "./pages/initial-profile/Welcome";
import SharedLayout from './pages/SharedLayout';
import Home from './pages/Home';
import Error from './pages/Error';
import Operations from './pages/Operations';
import OperationFull from './pages/OperationFull';
import NewOperation from './pages/NewOperation';
import Assets from './pages/Assets';
import Debts from './pages/Debts';
import NewDebt from './pages/NewDebt';
import DebtFull from './pages/DebtFull';
import Goals from './pages/Goals';
import NewGoal from './pages/NewGoal';
import GoalFull from './pages/GoalFull';
import Profile from './pages/Profile';
import Budgets from './pages/Budgets';
import BudgetFull from './pages/BudgetFull';
import NewBudget from './pages/NewBudget';
import OperationDetailFull from './pages/OperationDetailFull';
import NewOperationDetail from './pages/NewOperationDetail';
import NewAsset from './pages/NewAsset';
import AssetFull from './pages/AssetFull';
import TransactionFull from './pages/TransactionFull';
import Transactions from './pages/Transactions';
import Plan from './pages/Plan';
import YearPlans from './pages/YearPlans';
import NewPlan from './pages/NewPlan';
import PlanFull from './pages/PlanFull';
import Cashflow from './pages/Cashflow';
import OpertaionsFiles from './pages/OperationsFiles';

export default function Router(): JSX.Element {
  const { userId, client, setClient, setFinancialElements, setOperationDetails, setGoals } = useContext(Context);
  const { getClient, listFinancialElements, listOperationDetails, listOperations, listGoals } = useAPI();
  const [timestamp, setTimestamp] = useState<number>();
  const [loading, setLoading] = useState(true);
  const { showBoundary } = useErrorBoundary();
  const { handleSignOut } = useHandleSignOut();

  useEffect(() => {
    async function setUp() {
      const timestamp = new Date().getTime();
      try {
        setTimestamp(timestamp);
        const today = new Date();
        const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
        const retrievedClient = await getClient(userId);
        setClient(retrievedClient);
        await Promise.all([
          listOperationDetails(retrievedClient.id ?? '').then(res => setOperationDetails(sortElements(res, [
            { field: 'order_key', asc: true },
            { field: 'update_timestamp', asc: false },
          ]))),
          listGoals(retrievedClient.id ?? '').then(res => setGoals(sortElements(res, [
            { field: 'order_key', asc: true },
            { field: 'update_timestamp', asc: false },
          ]))),
          listFinancialElements(retrievedClient.id ?? '').then(res => setFinancialElements(sortElements(res, [
            { field: 'order_key', asc: true },
            { field: 'update_timestamp', asc: false },
          ]))),
          OperationsService.listOperationsByRange(retrievedClient.id ?? '', addMonthsToTimestamp(UTCtoday, -3), addMonthsToTimestamp(UTCtoday, 1), listOperations),
        ]);
      } catch (error) {
        showBoundary({ error: JSON.stringify(error), errorWithReplacer: JSON.stringify(error, Object.getOwnPropertyNames(error)), timestamp, userId });
      } finally {
        setLoading(false);
      }
    }
    setUp();
  }, [userId, showBoundary, setClient, setFinancialElements, setOperationDetails, setGoals, getClient, listFinancialElements, listOperationDetails, listOperations, listGoals]);

  if (loading) return <LoadingScreen reference='4' />;

  if (client?.status === 'initial_profile') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/'>

            <Route index element={<Welcome />} />

            <Route path='1' element={<GoalsStep />} />
            <Route path='2' element={<IncomeStep />} />
            <Route path='3' element={<ExpensesStep />} />
            <Route path='4' element={<DebtsStep />} />
            <Route path='5' element={<SavingsStep />} />
            <Route path='6' element={<FinancialStressStep />} />
            <Route path='summary' element={<Summary />} />

            <Route path='*' element={<Error />} />

          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  if (client?.status === 'enabled') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SharedLayout />}>

            <Route index element={<Home />} />

            <Route path='operations' element={<Operations />} />
            <Route path='operations/new' element={<NewOperation />} />
            <Route path='operations/files' element={<OpertaionsFiles />} />
            <Route path='operations/:operation_id' element={<OperationFull />} />

            <Route path='transactions' element={<Transactions />} />
            <Route path='transactions/:grouping_id' element={<TransactionFull />} />

            <Route path='budgets' element={<Budgets />} />
            <Route path='budgets/new' element={<NewBudget />} />
            <Route path='budgets/:timestamp_to' element={<BudgetFull />} />

            <Route path='plan' element={<Plan />} />
            <Route path='plan/new' element={<NewPlan />} />
            <Route path='plan/:timestamp_to' element={<PlanFull />} />

            <Route path='cashflow' element={<Cashflow />} />

            <Route path='yearplans' element={<YearPlans />} />

            <Route path='assets' element={<Assets />} />
            <Route path='assets/new' element={<NewAsset />} />
            <Route path='assets/:asset_id' element={<AssetFull />} />

            <Route path='debts' element={<Debts />} />
            <Route path='debts/new' element={<NewDebt />} />
            <Route path='debts/:debt_id' element={<DebtFull />} />

            <Route path='goals' element={<Goals />} />
            <Route path='goals/new' element={<NewGoal />} />
            <Route path='goals/:goal_id' element={<GoalFull />} />

            <Route path='profile' element={<Profile />} />
            <Route path='operation_details/new' element={<NewOperationDetail />} />
            <Route path='operation_details/:operation_detail_id' element={<OperationDetailFull />} />

            <Route path='*' element={<Error />} />

          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <main className='fallback-page'>
      <h1 className='self-center justify-center'>Somosfin app</h1>
      <article className='self-center text-center'>
        <h2>Tu cuenta de Somosfin no está disponible.</h2>
        <h3 className='text-base font-normal'>Solicitaste la eliminación de tu cuenta, o tu cuenta fue deshabilitada.</h3>
        <div className='flex justify-end pt-4'>
          <svg xmlns="http://www.w3.org/2000/svg" onClick={handleSignOut} className="cursor-pointer w-4 h-4 text-sf-violet-dark" viewBox="0 0 18 18" fill="none">
            <path d="M2 18C1.45 18 0.979002 17.804 0.587002 17.412C0.195002 17.02 -0.000664969 16.5493 1.69779e-06 16V2C1.69779e-06 1.45 0.196002 0.979002 0.588002 0.587002C0.980002 0.195002 1.45067 -0.000664969 2 1.69779e-06H9V2H2V16H9V18H2ZM13 14L11.625 12.55L14.175 10H6V8H14.175L11.625 5.45L13 4L18 9L13 14Z" fill="currentColor" />
          </svg>
        </div>
      </article>
      <div className='self-center flex flex-col'>
        <small className='secondary self-center'>{userId}</small>
        <small className='secondary self-center'>{client?.email ?? 'unknown client email'}</small>
        <small className='secondary self-center'>{client?.id ?? 'unknown client id'}</small>
        <small className='secondary self-center'>{timestamp}</small>
      </div>
    </main>
  );
}
