import { useContext, useEffect, useState } from "react";
import { Context } from "../utils/context";
import { useAPI } from "../hooks/UseAPI";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import EmptyList from "../components/EmptyList";
import { useCurrencyByCode } from "../hooks/CurrencyFromCode";
import { FinancialElement } from "../model/FinancialElement";
import { sortElements } from "../utils/sort-elements";
import { FinancialElementTypes } from "../consts/FinancialElementTypes";
import DebtPayment from "../components/DebtPayment";
import { getInteger, getDecimal } from '../utils/number-utils';
import CurrentValuesService from "../utils/CurrentValuesService";
import OperationsService from "../utils/OperationsService";

interface DebtItemProps {
    financialElement: FinancialElement
    valuesItems: Map<string, number>
    openDebtPayment: (financialElement: FinancialElement) => void
}

function DebtItem(props: DebtItemProps): JSX.Element {
    const navigate = useNavigate();
    const { getSign } = useCurrencyByCode();
    const valuesItems = Array.from(props.valuesItems.entries()).map(entry => ({ currencyCode: entry[0], valuation: entry[1].toString() }));

    return (
        <div className="py-2">
            <div className="cursor-pointer" onClick={() => navigate(`/debts/${props.financialElement.id}`)}>
                {props.financialElement.status === 'DISABLED' &&
                    <p className="bg-sf-red-medium h-min mb-1.5 font-bold rounded-full flex justify-center items-center whitespace-nowrap">
                        Deshabilitada
                    </p>
                }
                <div className="flex justify-between h-fit w-full">
                    <div className="max-w-[62%]">
                        <p className="truncate font-bold">{props.financialElement.title}</p>
                        <p className="secondary">{props.financialElement.description}</p>
                    </div>
                    {props.financialElement.status === 'ENABLED' && valuesItems.length > 0 &&
                        <div className="w-fit h-fit">
                            {valuesItems.map(item =>
                                <div key={item.currencyCode} className="flex items-center min-h-full self-center justify-start">
                                    <div className={`flex self-center ${item.valuation.includes('-') ? 'expenditure' : 'income'} `}>
                                        <p className="font-mono ml-1">{item.valuation.includes('-') ? '-' : '+'}</p>
                                        <p className="ml-0.5">{getSign(item.currencyCode)}</p>
                                        <p className="ml-0.5">{getInteger(item.valuation)}</p>
                                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(item.valuation)}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
            <div className="flex flex-row-reverse w-full items-center justify-between py-1">
                <button className="button-aux flex space-x-1 items-center" onClick={() => { props.openDebtPayment(props.financialElement); window.scrollTo(0, 0); }}>
                    <p>Pago de deuda</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5" viewBox="0 0 24 24"><path fill="currentColor" d="M4.615 18q-.69 0-1.152-.462Q3 17.075 3 16.385v-8.77q0-.69.463-1.152Q3.925 6 4.615 6h14.77q.69 0 1.152.463q.463.462.463 1.152v8.77q0 .69-.462 1.152q-.463.463-1.153.463zm0-1h14.77q.23 0 .423-.192q.192-.193.192-.423v-8.77q0-.23-.192-.423Q19.615 7 19.385 7H4.615q-.23 0-.423.192Q4 7.385 4 7.615v8.77q0 .23.192.423q.193.192.423.192m13.193-2.192H15.75q-.19 0-.316.126t-.126.316t.126.316q.126.126.316.126h2.135q.348 0 .577-.23q.23-.23.23-.577V12.75q0-.19-.126-.316t-.316-.126t-.316.126q-.126.126-.126.316zM12 14.385q.98 0 1.683-.702q.702-.702.702-1.683q0-.98-.702-1.683q-.702-.702-1.683-.702q-.98 0-1.683.702q-.702.702-.702 1.683q0 .98.702 1.683q.702.702 1.683.702M6.192 9.192H8.25q.19 0 .316-.126t.126-.316t-.126-.316q-.126-.126-.316-.126H6.115q-.348 0-.577.23q-.23.23-.23.577v2.135q0 .19.126.316t.316.126t.316-.126q.126-.126.126-.316zM4 17V7z" /></svg>
                </button>
                {props.financialElement.transactional &&
                    <small onClick={() => navigate(`/debts/${props.financialElement.id}`)} className="cursor-pointer ring-1 ring-inset ring-sf-violet-dark h-min px-2.5 py-0.5 rounded-full flex justify-center items-center text-sf-violet-dark font-bold whitespace-nowrap">
                        Uso cotidiano
                    </small>
                }
            </div>
        </div>
    );
}

export default function Debts(): JSX.Element {
    const { client, setFinancialElements } = useContext(Context);
    const { listFinancialElements, listOperations, listValuations, createValuation } = useAPI();
    const [loading, setLoading] = useState(true);
    const [debts, setDebts] = useState<DebtItemProps[]>([]);
    const [financialElementToTransfer, setFinancialElementToTransfer] = useState<FinancialElement>();
    const { getSign } = useCurrencyByCode();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            if (!client.id) return;
            try {
                const today = new Date();
                const UTCtoday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()).toString();
                const financialElements = await listFinancialElements(client.id);
                sortElements(financialElements, [
                    { field: 'status', asc: false },
                    { field: 'transactional', asc: false },
                    { field: 'update_timestamp', asc: false },
                ]);
                setFinancialElements(financialElements);
                const debtFinancialElements = financialElements.filter(financialElement => financialElement.type === FinancialElementTypes.DEBT.key);
                const currentValues = await CurrentValuesService.getValues(client.id ?? '', debtFinancialElements, [UTCtoday], listOperations, listValuations, createValuation);
                const debtItems = debtFinancialElements.map(financialElement => {
                    const valuesItems = new Map<string, number>();
                    currentValues.filter(currentValue => currentValue.financial_element_id === financialElement.id).forEach(currentValue => {
                        valuesItems.set(currentValue.currency_code ?? '', parseFloat(currentValue.amount ?? '0'));
                    });
                    return { financialElement, valuesItems } as DebtItemProps;
                });
                setDebts(debtItems);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return OperationsService.subscribe(fetchData);
    }, [client.id, setFinancialElements, createValuation, listOperations, listValuations, listFinancialElements]);

    if (loading) return <Loading />

    const balanceItems = new Map<string, { transactionalBalance: string, totalBalance: string }>();
    const defaultBalanceItem = { transactionalBalance: '0', totalBalance: '0' };
    debts.filter(debt => debt.financialElement.status === 'ENABLED').forEach(debt => {
        debt.valuesItems.forEach((value, currency_code) => {
            if (debt.financialElement.transactional) {
                const newValue = (parseFloat(balanceItems.get(currency_code)?.transactionalBalance ?? '0') + value).toString();
                balanceItems.set(currency_code, { ...defaultBalanceItem, ...(balanceItems.get(currency_code) ?? {}), transactionalBalance: newValue });
            }
            const newValue = (parseFloat(balanceItems.get(currency_code)?.totalBalance ?? '0') + value).toString();
            balanceItems.set(currency_code, { ...defaultBalanceItem, ...(balanceItems.get(currency_code) ?? {}), totalBalance: newValue });
        });
    });

    return (
        <main>
            {financialElementToTransfer && <DebtPayment financialElement={financialElementToTransfer} availableCurrencies={Array.from(debts.find(d => d.financialElement.id === financialElementToTransfer.id)?.valuesItems.keys() ?? [])} close={() => setFinancialElementToTransfer(undefined)} />}
            <article className="h-fit bg-sf-red-light pb-0 row-start-1">
                <div className="flex w-full flex-row justify-between items-center">
                    <h2>Deudas</h2>
                    <button className="button-aux flex space-x-1 items-center" onClick={() => navigate('/debts/new')}>
                        <p>Nuevo</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-3.5" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H6q-.425 0-.713-.288T5 12q0-.425.288-.713T6 11h5V6q0-.425.288-.713T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.713-.288T11 18v-5Z" /></svg>
                    </button>
                </div>
                {balanceItems.size !== 0 &&
                    <div className="pb-3 flex justify-between">
                        <div className="w-1/2">
                            <h3>Saldo total:</h3>
                            {Array.from(balanceItems.entries()).map(valuesItemEntry => {
                                const currencyCode = valuesItemEntry[0];
                                const totalBalance = valuesItemEntry[1].totalBalance;

                                return (
                                    <div key={currencyCode} className={`flex ${totalBalance.includes('-') ? 'expenditure' : 'income'} `}>
                                        <p className="font-mono">{totalBalance.includes('-') ? '-' : '+'}</p>
                                        <p className="ml-1">{getSign(currencyCode)}</p>
                                        <p className="ml-1">{getInteger(totalBalance)}</p>
                                        <p className="text-[11px]/[16px] ml-[1px]">{getDecimal(totalBalance)}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="w-1/2">
                            <p>Solo Uso cotidiano:</p>
                            {Array.from(balanceItems.entries()).map(valuesItemEntry => {
                                const currencyCode = valuesItemEntry[0];
                                const transactionalBalance = valuesItemEntry[1].transactionalBalance;

                                return (
                                    <div key={currencyCode} className={`flex ${transactionalBalance.includes('-') ? 'expenditure' : 'income'} `}>
                                        <p className="font-normal font-mono">{transactionalBalance.includes('-') ? '-' : '+'}</p>
                                        <p className="font-normal ml-1">{getSign(currencyCode)}</p>
                                        <p className="font-normal ml-1">{getInteger(transactionalBalance)}</p>
                                        <p className="font-normal text-[11px]/[16px] ml-[1px]">{getDecimal(transactionalBalance)}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
                <ul className="divide-y divide-sf-black border-t border-sf-black">
                    {debts?.length > 0 ? debts?.map(debt => <DebtItem key={debt.financialElement.id} financialElement={debt.financialElement} valuesItems={debt.valuesItems} openDebtPayment={setFinancialElementToTransfer} />)
                        : <EmptyList message="Aún no se cargaron deudas." />}
                </ul>
            </article>
        </main>
    );
}
