interface SafeAreaInsetTopProps {
    minValue: string
}

export default function SafeAreaInsetTop(props: SafeAreaInsetTopProps): JSX.Element {
    const height = `max(env(safe-area-inset-top, ${props.minValue}), ${props.minValue})`;

    return (
        <div style={{
            height: height,
            minHeight: height,
        }} />
    );
}
