import { useContext, useEffect, useState } from 'react';
import { useAPI } from '../hooks/UseAPI';
import { Context } from '../utils/context';
import { Budget } from '../model/Budget';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import { ExchangeRate, defaultExchangeRate } from '../model/ExchangeRate';
import BudgetItem from './BudgetItem';
import { OperationTypes } from '../consts/OperationTypes';
import { addDaysToTimestamp } from '../utils/date-utils';
import { Operation } from '../model/Operation';
import OperationsService from '../utils/OperationsService';

export default function BudgetSummary(): JSX.Element {
    const { client, operationDetails } = useContext(Context);
    const { listBudgets, listExchangeRates, listOperations } = useAPI();
    const [loading, setLoading] = useState(true);
    const [loadingExchangeRates, setLoadingExchangeRates] = useState(true);
    const [budgets, setBudgets] = useState<Budget[]>([]);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRate[]>([]);
    const [selectedBudgetIndex, setSelectedBudgetIndex] = useState<number>(0);
    const [budgetsRetrieved, setBudgetsRetrieved] = useState<boolean>(false);
    const [operations, setOperations] = useState<Operation[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function setUp() {
            try {
                setLoading(true);
                const result = await listBudgets(client.id ?? '');
                setBudgets(result);
                const currentTime = (new Date()).getTime().toString();
                let currentBudgetIndex = result.findIndex(budget => (budget.timestamp_from ?? '') < currentTime && (budget.timestamp_to ?? '') > currentTime);
                if (currentBudgetIndex < 0) {
                    currentBudgetIndex = result.length - 1;
                }
                setSelectedBudgetIndex(currentBudgetIndex);
            } finally {
                setLoading(false);
                setBudgetsRetrieved(true);
            }
        }
        setUp();
    }, [client.id, listBudgets]);

    useEffect(() => {
        const fetchOperations = () => {
            if (!client.id || budgets.length === 0) return;

            const budget = budgets[selectedBudgetIndex];

            if (!budget.timestamp_from || !budget.timestamp_to) return;

            const from = budget.timestamp_from;
            const to = budget.timestamp_to;

            OperationsService.listOperationsByRange(client.id, from, to, listOperations).then(setOperations);
        };

        fetchOperations();

        return OperationsService.subscribe(fetchOperations);
    }, [client.id, budgets, budgetsRetrieved, selectedBudgetIndex, listOperations]);

    useEffect(() => {
        if (budgets.length === 0) {
            if (budgetsRetrieved) {
                setLoadingExchangeRates(false);
            }
            return;
        }

        const budget = budgets[selectedBudgetIndex];

        async function getExchangeRates() {
            try {
                setLoadingExchangeRates(true);
                const usedCurrencies = operations?.filter(operation => (operation?.operation_detail_id ?? '') !== '').filter(operation => {
                    if (budget?.timestamp_from === undefined || budget?.timestamp_to === undefined || operation.timestamp === undefined) return 0;

                    return operation.timestamp >= budget.timestamp_from && operation.timestamp < budget.timestamp_to;
                }).map(operation => operation.currency_code ?? '');
                usedCurrencies.push(budget.currency ?? '');
                const codes = Array.from(new Set(usedCurrencies));
                if (codes.length === 0) return;
                const exchangeRates = await listExchangeRates({
                    codes,
                    from: budget.timestamp_from,
                    to: budget.timestamp_to,
                });
                setExchangeRates(oldExchangeRates => [...oldExchangeRates, ...exchangeRates]);
            } finally {
                setLoadingExchangeRates(false);
            }
        }

        getExchangeRates();
    }, [budgets, budgetsRetrieved, selectedBudgetIndex, operations, listExchangeRates]);

    if (loading || loadingExchangeRates) return <Loading />

    if (budgets.length === 0) return (
        <article>
            <div className="items-center text-center min-w-full text-sf-gray-dark p-4">No se cargó ningún presupuesto</div>
            <div className='min-w-full flex justify-center'>
                <button className='button-primary' onClick={() => navigate('/budgets/new')}>Crear presupuesto</button>
            </div>
        </article>
    );

    const missingExchangeRates: ExchangeRate[] = [];

    function getExchangeRate(code: string, timestamp: string): number {
        let selectedExchangeRate = { ...defaultExchangeRate };
        exchangeRates.filter(exchangeRate => (exchangeRate.code === code) && ((exchangeRate.timestamp ?? '') <= timestamp))
            .forEach(exchangeRate => {
                if ((exchangeRate.timestamp ?? '') > (selectedExchangeRate.timestamp ?? '')) {
                    selectedExchangeRate = exchangeRate;
                }
            });

        let exchangeRate = selectedExchangeRate.rate;
        if (exchangeRate === undefined || exchangeRate === '') {
            exchangeRate = '1';
            if (code !== 'USD') {
                missingExchangeRates.push({ code, timestamp });
            }
        }

        return parseFloat(exchangeRate);
    }

    const budget = budgets[selectedBudgetIndex];

    const filteredOperations = operations?.filter(operation => (operation?.operation_detail_id ?? '') !== '').filter(operation => {
        if (budget?.timestamp_from === undefined || budget?.timestamp_to === undefined || operation.timestamp === undefined) return 0;

        return operation.timestamp >= budget.timestamp_from && operation.timestamp < budget.timestamp_to;
    }).map(operation => {
        const parsedAmount = parseFloat(operation.amount ?? '');
        const budgetExchangeRate = getExchangeRate(budget.currency ?? '', operation.timestamp ?? '');
        const operationExchangeRate = getExchangeRate(operation.currency_code ?? '', operation.timestamp ?? '');

        return {
            ...operation,
            currency: budget.currency,
            amount: (parsedAmount * budgetExchangeRate / operationExchangeRate).toString()
        }
    });

    const incomeFilteredOperations = filteredOperations.filter(item => !item.amount.includes('-'));

    const spendingFilteredOperations = filteredOperations.filter(item => !!item.amount.includes('-'));

    const budgetIncome = budget.items?.filter(operation =>
        !operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== ''
    ) ?? [];

    const budgetSpending = budget.items?.filter(operation =>
        !!operation.amount?.includes('-') &&
        (operation.operation_detail_id ?? '') !== ''
    ) ?? [];

    const totalSpending = spendingFilteredOperations.map(item => parseFloat(item.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
        .toString() ?? '';

    const totalIncome = incomeFilteredOperations.map(item => parseFloat(item.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
        .toString() ?? '';

    const plannedSpending = budgetSpending.map(item => parseFloat(item.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
        .toString() ?? '';

    const plannedIncome = budgetIncome.map(item => parseFloat(item.amount ?? ''))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
        .toString() ?? '';

    return (
        <article className="h-fit">
            <div className='flex space-x-1 items-center justify-between'>
                <h2>Tablero</h2>
                <h3 className='flex items-center my-1'>
                    {missingExchangeRates.length > 0 && <div className='bg-sf-yellow-light rounded-lg p-0.5 ring-1 ring-inset ring-sf-red-medium mr-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" className='w-5 text-sf-red-medium' viewBox="0 0 256 256"><g fill="currentColor"><path d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91Z" opacity=".2" /><path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72Zm-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72ZM120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12Z" /></g></svg>
                    </div>}
                    Periodo: {new Date(parseInt(budget?.timestamp_from ?? '')).toLocaleDateString('es', { timeZone: 'UTC' })} - {new Date(parseInt(addDaysToTimestamp(budget?.timestamp_to ?? '', -1))).toLocaleDateString('es', { timeZone: 'UTC' })}
                </h3>
            </div>
            <div className='border-t border-sf-black'>
                <BudgetItem
                    currentAmount={totalSpending}
                    plannedAmount={plannedSpending}
                    title={<p className='text-base'>Egreso total</p>}
                    queryParams={createSearchParams({
                        type: OperationTypes.EXPENDITURE.key,
                        timestamp_from: budget.timestamp_from ?? '',
                        timestamp_to: budget.timestamp_to ?? '',
                        budget_timestamp: budget.timestamp_to ?? '',
                    }).toString()}
                    currency={budget.currency ?? ''}
                />
                <p className='mb-2 text-base'>Detalles:</p>
                <ul className="divide-y divide-sf-black border-t border-sf-black mb-3">
                    {budgetSpending.length > 0 ?
                        budgetSpending.slice(0, 3).map(item => <BudgetItem key={item.operation_detail_id}
                            currentAmount={spendingFilteredOperations.filter(operation => operation.operation_detail_id === item.operation_detail_id)
                                .map(operation => parseFloat(operation.amount ?? '0'))
                                .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
                                .toString()}
                            plannedAmount={item.amount ?? '0'}
                            title={operationDetails.find(operationDetail => operationDetail.id === item.operation_detail_id)?.title ?? ''}
                            queryParams={createSearchParams({
                                type: OperationTypes.EXPENDITURE.key,
                                operation_detail_id: item.operation_detail_id ?? '',
                                timestamp_from: budget.timestamp_from ?? '',
                                timestamp_to: budget.timestamp_to ?? '',
                                budget_timestamp: budget.timestamp_to ?? '',
                            }).toString()}
                            currency={budget.currency ?? ''}
                        />)
                        : <p className='my-2'>Aún no hay conceptos presupuestados</p>
                    }
                </ul>
            </div>
            <div className='border-t border-sf-black'>
                <BudgetItem
                    currentAmount={totalIncome}
                    plannedAmount={plannedIncome}
                    title={<p className='text-base'>Ingreso total</p>}
                    queryParams={createSearchParams({
                        type: OperationTypes.INCOME.key,
                        timestamp_from: budget.timestamp_from ?? '',
                        timestamp_to: budget.timestamp_to ?? '',
                        budget_timestamp: budget.timestamp_to ?? '',
                    }).toString()}
                    currency={budget.currency ?? ''}
                />
            </div>
        </article>
    )
}
