import Input from "@mui/joy/Input";
import { RefObject } from "react";

interface TitleInputProps {
    value: string | undefined;
    setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
    placeholder?: string;
    submit?: () => void;
    inputRef?: RefObject<HTMLInputElement>;
    onFocus?: () => void;
    onBlur?: () => void;
}

export default function TitleInput(props: TitleInputProps): JSX.Element {
    return (
        <Input
            ref={props.inputRef}
            value={props.value}
            onKeyDown={event => event.key === 'Enter' && props.submit?.()}
            onChange={event => props.setValue(event.target.value)}
            placeholder={props.placeholder}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            sx={theme => ({
                width: '100%',
                'input': {
                    textAlign: 'center',
                    fontFamily: 'ESRebondGrotesque Medium',
                    fontWeight: '700',
                    fontSize: '24px',
                    height: '29px',
                    lineHeight: '29px',
                    color: theme.vars.palette.text.primary,
                    '&::placeholder': {
                        color: theme.vars.palette.neutral.softDisabledColor,
                    },
                },
                boxShadow: 'none',
                'input:focus': {
                    boxShadow: 'none',
                },
                backgroundColor: 'transparent',
                border: 'none',
                ':focus-within::before, :hover::before': {
                    border: `1px solid ${theme.vars.palette.focusVisible}`,
                    top: 'unset',
                },
            })}
        />
    );
}
